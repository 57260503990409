import template from './AddKpiProject.html';

class AddKpiProjectVM
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.kpis = ko.observableArray();
		this.projects = ko.observableArray();
		this.kpi_id = ko.observable(dialog.bindings.kpi_id);
		this.project_id = ko.observable();
		this.adding_project = ko.observable(true);
		this.selected_kpi_project = ko.observableArray();
		this.dialog_title = ko.observable();
		this.kpi_project_add = ko.observableArray([]);
		this.kpi_project_remove = ko.observableArray([]);
		this.kpi_projects = ko.observableArray();
		this.data_to_add = ko.observableArray();
		this.kpi_note = ko.observable();
		this.add_disabled = ko.observable(true);
		this.remove_disabled = ko.observable(true);

		this.kpi_project_add.subscribe((value) => {
			const valid = value.length > 0 && !value.some(item => this.selected_kpi_project().includes(item));
			this.add_disabled(!valid);
			this.remove_disabled(valid);
		});

		this.kpi_project_remove.subscribe((value) => {
			const valid = value.length > 0;
			this.remove_disabled(!valid);
			this.add_disabled(valid);
		});
	}

	async btn_add_click ()
	{
		
		this.data_to_add.push(...this.kpi_project_add());
		

		let success = true;
		if (this.adding_project())
		{
			for (let item of this.data_to_add())
			{
				let result = {
					kpi_id: this.kpi_id(),
					project_id: item.project_id, 
					note: this.kpi_note()
				}

				let response = await fetch('/api/kpi/project-link', {
					method: 'POST',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(result)
				});
				
				if (!response.ok)
					success = false;
			}
		}
		else if (!this.adding_project())
		{
			for (let item of this.data_to_add())
			{
				let result = {
					kpi_id: item.kpi_id,
					project_id: this.project_id(),
					note: this.kpi_note()
				}

				let response = await fetch('/api/kpi/project-link', {
					method: 'POST',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(result)
				});

				if (!response.ok)
					success = false;
			}
		}

		if (success)
		{
			Grape.alerts.alert({title: 'Success!', message: 'Related indicators/projects successfully added!', type: 'success'});
			this.selected_kpi_project.push(...this.kpi_project_add());
			this.projects.removeAll(this.kpi_project_add());
			this.kpis.removeAll(this.kpi_project_add());
			this.kpi_project_add([]);
			this.data_to_add.removeAll();
		}
	}

	async btn_remove_click ()
	{
		let response = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Are you sure you want to unlink the selected items? This will delete ALL the previously captured values relating to these Indicator & Project combinations!',
			title: 'Warning'
		});

		if (response)
		{
			let success = true;
			for (let item of this.kpi_project_remove())
			{
				let options;
				if (this.adding_project())
				{
					options = {
						kpi_id: this.kpi_id(),
						project_id: item.project_id
					}
				}
				else if (!this.adding_project())
				{
					options = {
						kpi_id: item.kpi_id,
						project_id :this.project_id()
					}
				}

				let response = await fetch('/api/kpi/project-unlink', {
					method: 'DELETE',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(options)
				});

				if (response.ok)
				{
					success = true;
					this.projects.push(item);
					this.kpis.push(item);
				}
				else 
					throw new Error(response.message || response.code || 'Unknown Error');
			}

			if (success)
			{
				Grape.alerts.alert({title: 'Success!', message: 'Items unlinked successfully!', type: 'success'});
				this.selected_kpi_project.removeAll(this.kpi_project_remove());
				this.kpi_project_remove([]);
			}
		}
	}

	async btn_close_click ()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}

class AddKpiProject
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new AddKpiProjectVM(this);
		this.name = 'AddKpiProject';
		this.viewModel.kpi_id(bindings.kpi_id);
		this.viewModel.project_id(bindings.project_id);
		this.viewModel.selected_kpi_project(bindings.kpi_projects);
		this.viewModel.kpi_projects(bindings.kpi_projects);

		//LOGIC to check whether your adding a BU to the KPI or a KPI to a BU
		if (bindings.kpi_id)
		{
			this.viewModel.adding_project(true);
			this.viewModel.dialog_title('ADD/REMOVE PROJECT(S)');
			
		}
		else if (bindings.project_id) 
		{
			this.viewModel.adding_project(false);
			this.viewModel.dialog_title('ADD/REMOVE INDICATOR(S)');
		}

		this.init();
	}

	async init () 
	{
		document.title = 'Add Related Project to a Indicator';
		this.viewModel.projects.removeAll(this.viewModel.selected_kpi_project());
		this.updateData();
	}

	async updateData ()
	{
		if (this.viewModel.adding_project())
		{
			// GET List of projects..
			let options_projects = {
				table: 'v_projects',
				schema: 'kpi',
				offset: 0,
				sortorder: 'ASC',
				sortfield: 'project_id',
				filter_join: 'AND',
				join: 'OR',
				filter: []
			}

			try {
				let result = await Grape.fetches.getJSON('/api/record', options_projects);
				if (result.status != 'ERROR')
				{
					let associated_projects = this.viewModel.kpi_projects().map(record => record.project_id);
					this.viewModel.selected_kpi_project(result.records.filter(project => associated_projects.includes(project.project_id)));
					let filtered = result.records.filter(project => !associated_projects.includes(project.project_id));
					this.viewModel.projects(filtered);
				}
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				console.error(error);
			}
		}   

		if (!this.viewModel.adding_project())
		{
			//GET KPIs
			let options_kpi = {
				table: 'dv_kpis',
				schema: 'kpi',
				fields: ['name', 'kpi_id', 'uom', 'kpi_nr'],
				filter: []
			}

			let result = await Grape.fetches.getJSON('/api/record', options_kpi);
			if (result.status != 'ERROR')
			{
					let associated_kpis = this.viewModel.kpi_projects().map(record => record.kpi_id);
					this.viewModel.selected_kpi_project(result.records.filter(p => associated_kpis.includes(p.kpi_id)));
					let filtered_kpis = result.records.filter(p => !associated_kpis.includes(p.kpi_id));
					this.viewModel.kpis(filtered_kpis);
			}
			else 
				throw new Error(result.message || result.code);
		}
	}
}

export default {
	name: 'AddKpiProject',
	dialog_class: AddKpiProject,
	template: template,
	provider: 'ps'
}
