import template from './bu-kpi.html';
import BUViewModel from '../../../lib/BUViewModel.js';

class BUKPIVM
{
	constructor (page)
	{
		// Pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.page_count = ko.observable(1);

		this.current_page_number_target = ko.observable(1);
		this.current_page_size_target = ko.observable(10);
		this.page_count_target = ko.observable(1);

		// permissions
		this.can_add = ko.observable(false);

		this.buvm = new BUViewModel(page.bindings.bu_id || null);
		this.loaded = ko.observable(false);
		this.page = page;
		this.bu_id = ko.observable();
		this.bu_nr = ko.observable();
		this.bu_name = ko.observable();
		this.type = ko.observable();
		this.bu_details = ko.observableArray([]);
		this.kpi_business_units = ko.observableArray([]);
		this.kpis = ko.observableArray([]);
		this.kpi_id = ko.observable();
		this.baseline_per_bu = ko.observableArray();
		this.mode = ko.observable('from_bu');
		this.uom_options = ko.observableArray();
		this.time_period_options = ko.observableArray();
	}

	page_click(page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	page_click_target(page_number)
	{
		this.current_page_number_target(page_number);
		this.page.updateData();
	}

	async btn_view_monthly_target (data)
	{
		await Grape.dialog.open('KPIBUTargetsmonthly', {data: data, kpi_business_units: this.kpi_business_units()});
	}

	async btn_edit_bu_click (data)
	{
		await Grape.dialog.open('EditKPIBU', {kpi_id: data.kpi_id, business_unit_id: data.business_unit_id, note: data.note, kpi_business_unit_id: data.kpi_business_unit_id});
		this.page.updateData();
	}

	async btn_edit_baseline_target_click (data)
	{
		this.mode('edit_from_bu');
		await Grape.dialog.open('BaselinePerBU', {baseline_data: data, mode: this.mode(), bu_nr: this.bu_nr(), bu_id: this.bu_id(), bu_name: this.bu_name(), kpi_business_units: this.kpi_business_units(), uom_options: this.uom_options(), period: this.time_period_options()});
		this.page.updateData();
	}

	async btn_add_baseline_click ()
	{
		this.mode('from_bu');
		await Grape.dialog.open('BaselinePerBU', {bu_nr: this.bu_nr(), bu_id: this.bu_id(), bu_name: this.bu_name(), kpi_business_units: this.kpi_business_units(), mode: this.mode(), uom_options: this.uom_options(), period: this.time_period_options()});
		this.page.updateData();
	}	

	view_kpi_click (data)
	{
		Grape.navigate(`/kpi/kpi-setup/${data.kpi_id}`);
	}

	async btn_add_bu_click ()
	{
		await Grape.dialog.open('AddKPIBU', {kpi_id: this.kpi_id(), business_unit_id: this.bu_id(), kpi_business_units: this.kpi_business_units()})
		this.page.updateData();
	}

	async btn_remove_bu_click (data)
	{
		let response = await Grape.alerts.confirm({
			type: 'danger',
			message: 'Are you sure you want to remove this Indicator? This will delete ALL the previously captured values relating to this Indicator & Business Unit combination!',
			title: 'Danger',
		});

		if (response) 
		{
			let result = await Grape.alerts.confirm({
				type: 'warning',
				message: 'Are you sure? This cannot be undone!',
				title: 'Warning',
			});
		
			if (result)
			{
				let options = {
					business_unit_id: data.business_unit_id,
					kpi_id: data.kpi_id
				}

				let response = await fetch('/api/kpi/kpi-business-unit', {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(options)
				});
				
				if (response.ok)
					console.log('Deleted successfully')
				else
					throw new Error(response.message || response.code || 'Unknown Error');

				this.page.updateData();
			}
		}
	}

	async btn_remove_target_click (data)
	{
		let alert = await Grape.alerts.confirm({type: 'warning', title: 'Confirm', message: 'Are you sure you want to delete this baseline & target?'})
		if (alert)
		{
			let options = {
				target_id: data.target_id
			}
			let response = await fetch('/api/kpi/kpi-target', {
				method: 'DELETE',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify(options)
			});
			if (response.ok)
				Grape.alerts.alert({type: 'success', title: 'Success', message: `Target for ${data.kpi_name} deleted successfully!`})
			else
				throw new Error(response.message || response.code || 'Unkown Error');

			this.page.updateData();
		}
	}

	async load ()
	{
		return await this.buvm.load();
	}
}

class BUKPIPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new BUKPIVM(this);
		this.timer = null;
		this.viewModel.bu_id(bindings.bu_id);
		this.viewModel.bu_nr(bindings.bu_nr);
		this.viewModel.type(bindings.business_unit_type_value);
	}

	async init ()
	{
		document.title = 'Business Unit Indicators';

		let permission = await window.user_permission(['admin', 'Setup Business Unit', 'Setup associated Indicator BU']);
		if (permission)
			this.viewModel.can_add(true);
		
		this.viewModel.load();
	}

	async updateData ()
	{
		//Get Business Unit details
		let options = {
			table: 'v_business_units',
			schema: 'kpi',
			offset: 0,
			sortorder: 'ASC',
			sortfield: 'bu_nr',
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'business_unit_id',
				operand: '=',
				value: this.viewModel.bu_id()
			}]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR') {
				this.viewModel.bu_details(result.records);
				this.viewModel.bu_nr(result.records[0].bu_nr);
				this.viewModel.bu_name(result.records[0].name);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}

		//Get this KPI details
		let option = {
			table: 'dv_kpis',
			schema: 'kpi',
			fields: ['name', 'kpi_id', 'uom', 'kpi_nr'],
			filter_join: 'AND',
			join: 'OR'
			
		}

		let result = await Grape.fetches.getJSON('/api/record', option);
		if (result.status != 'ERROR')
			this.viewModel.kpis(result.records);
		else 
			throw new Error(result.message || result.code);
		
		//GET Associated KPIs for this business unit
		let response = {
			table: 'v_kpi_business_units',
			schema: 'kpi',
			offset: 0,
			filter_join: 'AND',
			sortorder: 'ASC',
			sortfield: 'kpi_name',
			join: 'OR',
			filter: [{
				field: 'business_unit_id',
				operand: '=',
				value: this.viewModel.bu_id()
			}]
		}

		//LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			response.limit = this.viewModel.current_page_size();
			response.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', response);
			if (result.status != 'ERROR') 
			{
				this.viewModel.kpi_business_units(result.records);
				this.viewModel.page_count(Math.floor(result.total/result.limit)+1);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}

		//Get Baseline & target per BU
		let response_target = {
			table: 'v_kpi_bu_targets',
			schema: 'kpi',
			offset: (this.viewModel.current_page_number_target() - 1) * this.viewModel.current_page_size_target(),
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'business_unit_name',
				operand: '=',
				value: this.viewModel.bu_name()
			}]
		}

		//LOGIC: Pagination
		if (this.viewModel.current_page_number_target() && this.viewModel.current_page_size_target())
		{
			response_target.limit = this.viewModel.current_page_size_target();
			response_target.offset = (this.viewModel.current_page_number_target()-1) * this.viewModel.current_page_size_target();
		}
		
		try {
			let result_target = await Grape.fetches.getJSON('/api/record', response_target);
			if (result_target.status != 'ERROR')
			{
				this.viewModel.baseline_per_bu(result_target.records);
				this.viewModel.page_count_target(Math.floor(result_target.total/result_target.limit)+1);
			}
			else
				throw new Error(result.message || result_target.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}

		try {
			let result = await Grape.cache.fetch('TimePeriodTypeLookup');
			this.viewModel.time_period_options(result);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
		
		let uom_data = await Grape.cache.fetch('UoM');
			this.viewModel.uom_options(uom_data);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]bu/bu-kpi',
	page_class: BUKPIPage,
	template: template
}
