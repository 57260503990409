import template from './EditKPI.html';

class EditKPIViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.kpi_id = ko.observable();
		this.name = ko.observable();
		this.kpi_nr = ko.observable();
		this.parent_kpi= ko.observable();
		this.kpi_group = ko.observable();
		this.description = ko.observable();
		this.notes = ko.observable();
		this.idx = ko.observable();
		this.capture_manual = ko.observable(true);
		this.capture_auto = ko.observable(true);

		this.availableGroups = ko.observableArray([
			{name:'Environmental',kpi_group_id: 1},
			{name:'Social',kpi_group_id: 2},
			{name:'Governance', kpi_group_id: 3}
		]);

		this.selected_group = ko.observable();
		this.loadkpiGroupNames();
		this.available_uoms = ko.observableArray();
		this.selected_uom = ko.observable();
		this.selected_flag = ko.observableArray(['']);
		this.reporting_frequency_options = ko.observableArray([]);
		this.selected_reporting_frequency = ko.observable();
		this.parent_kpis = ko.observableArray();
		this.kpi_flags_options = ko.observableArray();
		this.remove_parent = ko.observable(false);

		this.selected_flag.subscribe((value) => {
			if (this.selected_flag() ===  undefined)
				this.selected_flag(null);
		});

		this.selected_reporting_frequency.subscribe((value) => {
			if (this.selected_reporting_frequency() ===  undefined)
				this.selected_reporting_frequency(null);
		});

		this.filtered_parent_kpis = ko.computed(() => {
			if (this.selected_group())
			{
				let current_kpi_id = this.kpi_id();
				let current_kpi_name = this.name();
				return ko.utils.arrayFilter(this.parent_kpis(), (parent) => {
					return (
						parent.kpi_group_id === this.selected_group() &&
						parent.kpi_id !== current_kpi_id &&
						parent.name !== current_kpi_name
					);
				});
			} else {
				// If no "kpi_group" is selected, return an empty array
				return [];
			}
		});

		this.parent_kpi_selected = ko.computed(() => {
			return this.parent_kpi();
		});
	}

	async btn_unlink_parent ()
	{
		let confirm = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Unlink parent indicator?',
			title: 'Confirm'
		});

		if (confirm)
		{
			this.remove_parent(true);
			this.parent_kpi('');
		}
	}

	async loadkpiGroupNames ()
	{
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'kpi',
				table: 'v_kpi_groups',
				filter: [],
			});

			if (result.status === 'OK')
			{
				let groupNames = result.records.map((record) => ({
						kpi_group_id: ko.observable(record.kpi_group_id),
						name: ko.observable(record.name),
				}));
				this.availableGroups(groupNames);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
	
	async save_kpi_click ()
	{
		if (!this.name()) 
		{
			window.document.getElementById('name').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Indicator name missing!'});
			return;
		}
		else if (!this.kpi_nr()) 
		{
			window.document.getElementById('kpi_nr').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Indicator number missing!'});
			return;
		}
		else if (!this.idx()) 
		{
			window.document.getElementById('idx').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Indicator index number missing!'});
			return;
		}
		else if (!this.selected_group())
		{
			window.document.getElementById('group').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Indicator group missing!'});
			return;
		}
		else if (!this.selected_uom())
		{
			window.document.getElementById('uom').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Unit missing!'});
			return;
		}

		let data = [];

		if (this.remove_parent())
		{
			data = {
				kpi_id: this.kpi_id(),
				name: this.name(),
				kpi_nr: this.kpi_nr(),
				parent_kpi_id: null,
				parent_kpi: null,
				kpi_group_id: this.selected_group(),
				uom: this.selected_uom(),
				flag_names: this.selected_flag(),
				idx: this.idx(),
				fields: {
					'add': [
						{name: 'description', value: this.description()},
						{name: 'notes', value: this.notes()},
						{'reporting frequency': this.selected_reporting_frequency()},
						{name: 'capture_manual', value: this.capture_manual()},
						{name: 'capture_automatic', value: this.capture_auto()}
					]
				}
			}
		}
		else
		{
			data = {
				kpi_id: this.kpi_id(),
				name: this.name(),
				kpi_nr: this.kpi_nr(),
				parent_kpi_id: this.parent_kpi(),
				kpi_group_id: this.selected_group(),
				uom: this.selected_uom(),
				flag_names: this.selected_flag(),
				idx: this.idx(),
				fields: {
					'add': [
						{name: 'description', value: this.description()},
						{name: 'notes', value: this.notes()},
						{'reporting frequency': this.selected_reporting_frequency()},
						{name: 'capture_manual', value: this.capture_manual()},
						{name: 'capture_automatic', value: this.capture_auto()}
					]
				}
			}
		}

		try {
			let response = Grape.fetches.postJSON('api/kpi/kpi', data);
			if (response.status != 'ERROR')
				await Grape.alerts.alert({type: 'success', title: 'Saved', message: 'Indicator saved successfully!'});
			else
				throw new Error(response.message || response.code);
		} catch (error) {
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Error occurred'});
		}
		this.dialog.close();
	}

	async close_click ()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}

class EditKPIClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditKPIViewModel(this); 
		this.name = 'EditKPI';
		this.parentViewModel = bindings.page;
		this.viewModel.parent_kpis(bindings.all_kpis);
		this.viewModel.reporting_frequency_options(bindings.time_period_options);

		this.init();
	}

	async init ()
	{
		document.title = 'Add or edit an Indicator'
		await this.updateData();

		if (this.bindings.kpi_data)
		{
			this.viewModel.kpi_id(this.bindings.kpi_data.kpi_id);
			this.viewModel.name(this.bindings.kpi_data.name);
			this.viewModel.kpi_nr(this.bindings.kpi_data.kpi_nr);
			this.viewModel.idx(this.bindings.kpi_data.idx);
			this.viewModel.parent_kpi(this.bindings.kpi_data.parent_kpi)
			this.viewModel.selected_uom(this.bindings.kpi_data.uom);
			this.viewModel.selected_group(this.bindings.kpi_data.kpi_group_id );
			
			let descriptionObj = JSON.parse(this.bindings.kpi_data.description || '{}');
			this.viewModel.description(descriptionObj.value || '');

			let notesObj = JSON.parse(this.bindings.kpi_data.notes || '{}');
			this.viewModel.notes(notesObj.value || '');

			if (this.bindings.kpi_data && this.bindings.available_uoms) 
			{
				if (this.bindings.kpi_data.uom)
				{
					let matching_uom = ko.utils.arrayFirst(this.viewModel.available_uoms() , (uom) => {
						return this.bindings.kpi_data.uom.includes(uom.name);
					});

					if (matching_uom)
						this.viewModel.selected_uom(matching_uom.name);
				}
			}

			if (this.bindings.kpi_data.parent_kpi)
			{
				let matching_parent_kpi = ko.utils.arrayFirst(this.viewModel.parent_kpis(), (a) => {
					return this.bindings.kpi_data.parent_kpi.includes(a.name);
				});

				if (matching_parent_kpi)
					this.viewModel.parent_kpi(matching_parent_kpi.kpi_id);
			}

			let matching_flag = ko.utils.arrayFirst(this.viewModel.kpi_flags_options(), (flag) => {
				return this.bindings.kpi_data.flag_names.includes(flag.name);
			});

			if (matching_flag)
				this.viewModel.selected_flag(matching_flag.name);

			if (this.bindings.kpi_data.reporting_frequency)
			{
				let matching_reporting_frequency = ko.utils.arrayFirst(this.viewModel.reporting_frequency_options(), (rf) => {
					return this.bindings.kpi_data.reporting_frequency.includes(rf.name);
				});

				if (matching_reporting_frequency)
					this.viewModel.selected_reporting_frequency(matching_reporting_frequency.name);
			}

			if (this.bindings.kpi_data.capture_manual)
			{
				let test =  this.bindings.kpi_data.capture_manual.includes('true');
				this.viewModel.capture_manual(test);
			}

			if (this.bindings.kpi_data.capture_automatic)
			{
				let test =  this.bindings.kpi_data.capture_automatic.includes('true');
				this.viewModel.capture_auto(test);
			}
		}

		let name = window.document.getElementById('name');
		name.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let kpi_nr = window.document.getElementById('kpi_nr');
		kpi_nr.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let group = window.document.getElementById('group');
		group.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let uom = window.document.getElementById('uom');
		uom.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});
	}

	async updateData()
	{
		// Load UOMs
		try {
			let UoM = await Grape.cache.fetch('UoM');
			this.viewModel.available_uoms(UoM);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while loading unit:', error);
		}

		//load flags
		try {
			let KPIFlagLookup = await Grape.cache.fetch('KPIFlagLookup');
			this.viewModel.kpi_flags_options(KPIFlagLookup);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while loading flag names:', error);
		}
	}
}

export default {
	name: 'EditKPI',
	dialog_class: EditKPIClass,
	template: template,
	provider: 'ps'
}
