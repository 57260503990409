import template from './dashboard-kpis.html';

class DashboardKPIsPageViewModel
{
	constructor (page)
	{
		this.page = page;

		this.fraction_green = ko.observable('0/0');
		this.fraction_amber = ko.observable('0/0');
		this.fraction_red = ko.observable('0/0');

		this.percentage_green = ko.observable('0');
		this.percentage_amber = ko.observable('0');
		this.percentage_red = ko.observable('0');
		this.view_table = ko.observable('grid');

		this.environmental_chbx = ko.observable(true);
		this.social_chbx = ko.observable(false);
		this.governance_chbx = ko.observable(false);

		this.environmental_kpis = ko.observableArray([]);
		this.social_kpis = ko.observableArray([]);
		this.governance_kpis = ko.observableArray([]);

		this.kpi_bu_targets_status = ko.observableArray([]);
		this.total_records = ko.observableArray([]);

		this.grid_data = ko.observableArray([]);
		this.comments_data = ko.observableArray([]);

		// Environ.. pagination
		this.current_page_number1 = ko.observable(1);
		this.current_page_size1 = ko.observable(5);
		this.page_count1 = ko.observable(1);

		//Social... pagination
		this.current_page_number2 = ko.observable(1);
		this.current_page_size2 = ko.observable(5);
		this.page_count2 = ko.observable(1);

		//Governance pagination
		this.current_page_number3 = ko.observable(1);
		this.current_page_size3 = ko.observable(5);
		this.page_count3 = ko.observable(1);

		//search
		this.search_gov_string = ko.observable('');
		this.search_env_string = ko.observable('');
		this.search_soc_string = ko.observable('');
		this.search_gov_bu_string = ko.observable('');
		this.search_soc_bu_string = ko.observable('');
		this.search_env_bu_string = ko.observable('');

		//filters
		this.selected_category_gov = ko.observableArray();
		this.selected_category_soc = ko.observableArray();
		this.selected_category_env = ko.observableArray();
		this.available_categories = ko.observableArray([]);

		this.search_gov_string.subscribe((value) => {
			this.page.load_governance_kpis();
		});

		this.search_env_string.subscribe((value) => {
			this.page.load_environmental_kpis();
		});

		this.search_soc_string.subscribe((value) => {
			this.page.load_social_kpis();
		});

		this.search_gov_bu_string.subscribe((value) => {
			this.page.load_governance_kpis();
		});

		this.search_env_bu_string.subscribe((value) => {
			this.page.load_environmental_kpis();
		});

		this.search_soc_bu_string.subscribe((value) => {
			this.page.load_social_kpis();
		});

		this.environmental_chbx.subscribe((value) => {
			this.page.updateData();
			this.page.load_environmental_kpis();
		});

		this.social_chbx.subscribe((value) => {
			this.page.updateData();
			this.page.load_social_kpis();
		});

		this.governance_chbx.subscribe((value) => {
			this.page.updateData();
			this.page.load_governance_kpis();
		});

		this.selected_category_gov.subscribe((value) => {
			this.page.load_governance_kpis();
		});

		this.selected_category_soc.subscribe((value) => {
			this.page.load_social_kpis();
		});

		this.selected_category_env.subscribe((value) => {
			this.page.load_environmental_kpis();
		});
	}

	async btn_refresh_dashboard_click ()
	{
		Grape.alerts.alert({ type: 'info', message: 'Refreshing the dashboard. The page will automatically refresh when successful.' });
		let response = await Grape.fetches.getJSON('/api/kpi/mv/refresh', {});
		if (response.status == 'OK')
			location.reload();
		else
			Grape.alerts.alert({ type: 'error', title: 'Error', message: response.message});
	}

	page_click_environmental (page_number)
	{
		this.current_page_number1(page_number);
		this.page.load_environmental_kpis();
	}

	page_click_social (page_number)
	{
		this.current_page_number2(page_number);
		this.page.load_social_kpis();
	}

	page_click_governance (page_number)
	{
		this.current_page_number3(page_number);
		this.page.load_governance_kpis();
	}

	btn_download_data_click (data)
	{
		console.log('downloaded data click: ', data);
	}

	btn_view_graph_data_click (data)
	{
		console.log('view graph data click: ', data);
	}

	btn_view_grid_data_click (data)
	{
		this.view_table('grid');
	}

	btn_view_comments_data_click (data)
	{
		this.view_table('comments');
	}

	async btn_view_monthly_target (data)
	{
		data.baseline = data.baseline_value;
		data.uom = data.target_uom;
		data.business_unit_name = data.bu_name;
		await Grape.dialog.open('KPIBUTargetsmonthly', {data: data});
	}
}

class DashboardKPIsPage
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new DashboardKPIsPageViewModel(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Indicators Dashboard';
		// Load BU Types
		try
		{
			let result = await Grape.cache.fetch('BusinessUnitTypeLookup');
			{
				let data = [];
				for (let i = 0; i < result.length; i++)
					data.push(result[i].name);
				this.viewModel.available_categories(data);
			} 
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		}

		this.load_environmental_kpis();
	}

	async update_totals ()
	{
		let total_options = {
			table: 'mv_kpi_bu_targets_status_totals',
			schema: 'kpi',
			limit: 1
		};
		
		try {
			let result = await Grape.fetches.getJSON('/api/record', total_options);
			if (result.status !== 'ERROR' && result.records.length)
			{
				let totals = result.records[0]

				this.viewModel.total_records(totals.total_records);
				this.viewModel.fraction_green(totals.total_green + '/' + totals.total_records);
				this.viewModel.fraction_red(totals.total_red + '/' + totals.total_records);
				this.viewModel.fraction_amber(totals.total_amber + '/' + totals.total_records);

				this.viewModel.percentage_green(totals.percentage_green);
				this.viewModel.percentage_red(totals.percentage_red);
				this.viewModel.percentage_amber(totals.percentage_amber);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async updateData ()
	{
		this.update_totals();
	}

	async load_environmental_kpis ()
	{
		let options = {
			table: 'mv_kpi_bu_targets_status',
			schema: 'kpi',
			offset: (this.viewModel.current_page_number1() - 1) * this.viewModel.current_page_size1(),
			limit: this.viewModel.current_page_size1()
		}

		// LOGIC: filter
		let filterOptions = [];
		filterOptions.push({
			field: 'kpi_group_name',
			operand: '=',
			value: 'Environmental'
		});	
		options.filter = filterOptions;

		//LOGIC: Pagination
		if(this.viewModel.current_page_number1() && this.viewModel.current_page_size1())
		{
			options.limit = this.viewModel.current_page_size1();
			options.offset = (this.viewModel.current_page_number1()-1) * this.viewModel.current_page_size1();
		}

		//LOGIC: Search
		if (this.viewModel.search_env_string() && this.viewModel.search_env_string().trim() != '')
		{
			options.filter.push({
				field: 'kpi_name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_env_string()}%`
			});
		}

		if (this.viewModel.search_env_bu_string() && this.viewModel.search_env_bu_string().trim() != '')
		{
			options.filter.push({
				field: 'bu_name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_env_bu_string()}%`
			});
		}

		//LOGIC: Type Filter
		if (this.viewModel.selected_category_env().length > 0)
		{
			options.filter.push({
				field: 'business_unit_type_value',
				operand: 'IN',
				value: this.viewModel.selected_category_env()
			});
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status !== 'ERROR')
			{
				this.viewModel.environmental_kpis(result.records);
				this.viewModel.environmental_kpis(result.records.map(record => ({
					...record,
					reporting_comments: (record.reporting_comments ?? []).slice().reverse().slice(0, 10)
				})));
				this.viewModel.page_count1(Math.floor(result.total/result.limit)+1);
			}
		}
		catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async load_social_kpis ()
	{
		let options = {
			table: 'mv_kpi_bu_targets_status',
			schema: 'kpi',
			offset: (this.viewModel.current_page_number2() - 1) * this.viewModel.current_page_size2(),
			limit: this.viewModel.current_page_size2()
		}

		// LOGIC: filter
		let filterOptions = [];
		filterOptions.push({
			field: 'kpi_group_name',
			operand: '=',
			value: 'Social'
		});
		options.filter = filterOptions;

		//LOGIC: Pagination
		if(this.viewModel.current_page_number2() && this.viewModel.current_page_size2())
		{
			options.limit = this.viewModel.current_page_size2();
			options.offset = (this.viewModel.current_page_number2()-1) * this.viewModel.current_page_size2();
		}

		//LOGIC: Search
		if (this.viewModel.search_soc_string() && this.viewModel.search_soc_string().trim() != '')
		{
			options.filter.push({
				field: 'kpi_name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_soc_string()}%`
			});
		}

		if (this.viewModel.search_soc_bu_string() && this.viewModel.search_soc_bu_string().trim() != '')
		{
			options.filter.push({
				field: 'bu_name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_soc_bu_string()}%`
			});
		}

		//LOGIC: Type Filter
		if (this.viewModel.selected_category_soc().length > 0)
		{
			options.filter.push({
				field: 'business_unit_type_value',
				operand: 'IN',
				value: this.viewModel.selected_category_soc()
			});
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status !== 'ERROR')
			{
				this.viewModel.social_kpis(result.records);
				this.viewModel.social_kpis(result.records.map(record => ({
					...record,
					reporting_comments: (record.reporting_comments ?? []).slice(0, 10)
				})));
				this.viewModel.page_count2(Math.floor(result.total/result.limit)+1);
			}
		}
		catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async load_governance_kpis ()
	{
		let options = {
			table: 'mv_kpi_bu_targets_status',
			schema: 'kpi',
			offset: (this.viewModel.current_page_number3() - 1) * this.viewModel.current_page_size3(),
			limit: this.viewModel.current_page_size3()
		}

		// LOGIC: filter
		let filterOptions = [];
		filterOptions.push({
			field: 'kpi_group_name',
			operand: '=',
			value: 'Governance'
		});
		options.filter = filterOptions;

		//LOGIC: Pagination
		if(this.viewModel.current_page_number3() && this.viewModel.current_page_size3())
		{
			options.limit = this.viewModel.current_page_size3();
			options.offset = (this.viewModel.current_page_number3()-1) * this.viewModel.current_page_size3();
		}

		//LOGIC: Search
		if (this.viewModel.search_gov_string() && this.viewModel.search_gov_string().trim() != '')
		{
			options.filter.push({
				field: 'kpi_name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_gov_string()}%`
			});
		}

		if (this.viewModel.search_gov_bu_string() && this.viewModel.search_gov_bu_string().trim() != '')
		{
			options.filter.push({
				field: 'bu_name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_gov_bu_string()}%`
			});
		}

		//LOGIC: Type Filter
		if (this.viewModel.selected_category_gov().length > 0)
		{
			options.filter.push({
				field: 'business_unit_type_value',
				operand: 'IN',
				value: this.viewModel.selected_category_gov()
			});
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status !== 'ERROR')
			{
				this.viewModel.governance_kpis(result.records);
				this.viewModel.governance_kpis(result.records.map(record => ({
					...record,
					reporting_comments: (record.reporting_comments ?? []).slice(0, 10)
				})));
				this.viewModel.page_count3(Math.floor(result.total/result.limit)+1);
			}
		}
		catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]dashboard/dashboard-kpis',
	page_id: 'dashboard-kpis',
	page_class: DashboardKPIsPage,
	template: template
}
