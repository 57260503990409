/**
 * Cache for SDG Features
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'SDGFeatures',
	options: {
		table: 'v_sdg_feature',
		schema: 'sdg',
		sortfield: 'sdg_feature_id'
	}
};
