import template from './kpi-projects.html';
import KPIViewModel from '../../../lib/KPIViewModel.js';

class KPIBUVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.kpivm = new KPIViewModel(page.bindings.kpi_id || null);
		this.kpi_id = ko.observable(page.bindings.kpi_id);
		this.kpi_projects = ko.observableArray();

		// permissions
		this.edit_projects = ko.observable(false);
	}

	async btn_add_project_click ()
	{
		await Grape.dialog.open('AddKpiProject', {kpi_id: this.kpi_id(), kpi_projects: this.kpi_projects()});
		this.page.updateData();
	}

	async btn_edit_kpi_click (data)
	{
		await Grape.dialog.open('EditKPIBU', {mode: 'edit_project_kpi', note: data.kpi_project_note, project_kpi_id: data.project_kpi_id, kpi_id: data.kpi_id, project_id: data.project_id});
		this.page.updateData();
	}

	async btn_remove_kpi_click (data)
	{
		let result = await Grape.alerts.confirm(
			{
				type: 'warning',
				message: `Are you sure you want to unlink ${data.project_name}?`,
				title: 'warning'
			}
		);

		if (result)
		{
			let options = {
				project_id: data.project_id,
				kpi_id: data.kpi_id
			}

			let response = await fetch('/api/kpi/project-unlink', {
				method: 'DELETE',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify(options)
			});

			if (response.ok)
			{
				Grape.alerts.alert({
					type: 'success',
					title: 'Success',
					message: 'Project unlinked successfully'
				});
			} 
			else 
				throw new Error(response.message || response.code || 'Unknown Error');

			this.page.updateData();
		}
	}

	async load()
	{
		return await this.kpivm.load(); 
	}
}

class KPIBUPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPIBUVM(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Indicator Projects';

		let permission = await window.user_permission(['admin', 'Setup ESG Indicator', 'Setup associated Project Indicators']);
		if (permission)
			this.viewModel.edit_projects(permission);

		this.viewModel.load();
	}

	async updateData ()
	{
		// Get related projects
		let options = {
			table: 'v_kpi_projects',
			schema: 'kpi',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'kpi_id',
				operand: '=',
				value: this.viewModel.kpi_id()
			}]
		}

		let result = await Grape.fetches.getJSON('/api/record', options);
		if (result.status != 'ERROR')
			this.viewModel.kpi_projects(result.records);
			
		else 
			throw new Error(result.message || result.code);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]kpi/projects-related',
	page_class: KPIBUPage,
	template: template
}
