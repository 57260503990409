
class KPIViewModel
{
	constructor (kpi_id)
	{
		this.kpi_id = ko.observable(kpi_id);
		this.kpi_nr = ko.observable('');
		this.uom = ko.observable('');
		this.idx = ko.observable(0);
		this.name = ko.observable('');
		this.notes = ko.observable('');
		this.description = ko.observable('');
		this.flag_names = ko.observable([]);
		this.reporting_frequency = ko.observable('');
	}

	async load ()
	{
		let result = await Grape.fetches.getJSON('/api/record', {
			schema: 'kpi', table: 'dv_kpis',
			filter: [{field: 'kpi_id', oper: '=', value: this.kpi_id()}],
			fields: ['name', 'kpi_nr', 'uom', 'idx', 'flag_names', 'description', 'notes', 'reporting_frequency']
		});
		
		if (!result.records)
			console.log('No data');
		
		this.name(result.records[0].name);
		this.kpi_nr(result.records[0].kpi_nr);
		this.uom(result.records[0].uom);
		this.idx(result.records[0].idx);
		this.flag_names(result.records[0].flag_names);
		this.description(result.records[0].description);
		this.notes(result.records[0].notes);
		this.reporting_frequency(result.records[0].reporting_frequency);
	}

	async save ()
	{
		// TODO
		let obj = {
		};
	}
}

export default KPIViewModel;
