import template from './sdg-search.html';

class SDGSearch
{
	constructor (page)
	{
		this.page = page;
		this.is_loading = ko.observable(false);
		this.search_string = ko.observable();
		this.sdg_id = ko.observable();
		this.sdgs = ko.observableArray();
		this.selected_item = ko.observable();
		this.targets = ko.observableArray();
		this.indicators = ko.observableArray();
		this.search_results = ko.observableArray();
		this.selected_sdg = ko.observableArray();
		this.selected_target = ko.observableArray();
		this.selected_indicator = ko.observableArray();
		this.filtered_indicators = ko.observableArray();
		this.filtered_targets = ko.observableArray();
		this.img_path = ko.observable();
		this.title = ko.observableArray();
		let last_search_sdg_tab = localStorage.getItem('last_search_sdg_tab' || 'kpi');
		this.selected_tab = ko.observable(last_search_sdg_tab);
		this.item_projects = ko.observableArray();
		this.item_kpis = ko.observableArray().extend({
			rateLimit: 50
		});

		this.search_string.subscribe(() => {
			this.page.updateData();
			//this.updateSearch();
		});
	}

	async updateSearch()
	{

		const searchString = this.search_string().toLowerCase();
		const combinedResults = [];
	
		// Search in SDGs
		this.sdgs().forEach(sdg => {
			if (sdg.sdg_name && sdg.sdg_name.toLowerCase().includes(searchString)) {
				combinedResults.push({ displayText: "SDG:" + sdg.sdg_name, ...sdg });
			}
		});
	
		// Search in Targets
		this.targets().forEach(target => {
			if (target.target_description && target.target_description.toLowerCase().includes(searchString)) {
				combinedResults.push({ displayText: "Target: " + target.target_nr, ...target });
			}
		});
	
		// Search in Indicators
		this.indicators().forEach(indicator => {
			if (indicator.ind_description && indicator.ind_description.toLowerCase().includes(searchString)) {
				combinedResults.push({ displayText: "Indicator: " + indicator.indicator_nr, ...indicator });
			}
		});
	
		this.search_results(combinedResults);
	}

	async select_item(data) {
		this.title([]);
		this.img_path(data.img_path);
		this.search_string('');
		this.search_results('');
		this.filtered_indicators(this.indicators().filter(x => x.sdg_target_id === data.sdg_target_id));
		this.filtered_targets(this.targets().filter(x => x.sdg_id === data.sdg_id));

		if (data.type === 'SDG') {
			let sdg = this.sdgs().find(x => x.sdg_id === data.sdg_id);
			this.selected_item(sdg);
			this.selected_indicator(null);
			this.selected_target(null);

			this.filtered_indicators(this.indicators().filter(x => x.sdg_id === data.sdg_id));
			this.title.push({name: data.sdg_name, id: data.sdg_id, nr: data.sdg_nr, type: data.type});

		} else if (data.type === 'Target') {
			let t = this.targets().find(x => x.sdg_target_id === data.sdg_target_id);
			this.selected_item(t);
			let sdg = this.sdgs().find(sdg => sdg.sdg_id === data.sdg_id);
			this.selected_sdg(sdg);
			this.selected_indicator(null);
			this.title.push({name: data.target_description, id: data.sdg_target_id, nr: data.target_nr, type: data.type});

		} else if (data.type === 'Indicator') {
			let ind = this.indicators().find(x => x.indicator_id === data.indicator_id);
			this.selected_item(ind);
			let sdg = this.sdgs().find(x => x.sdg_id === data.sdg_id);
			this.selected_sdg(sdg);
			let t = this.targets().find(x => x.sdg_target_id === data.sdg_target_id);
			this.selected_target(t);
			this.title.push({name: data.ind_description, id: data.indicator_id, nr: data.indicator_nr, type: data.type});
		}

		let id = this.title()[0].id;
		this.page.updateInfo(id);
	}

	switch_tabs (data, event)
	{
		let tabname = event.currentTarget.getAttribute('data-tabname');
		this.selected_tab(tabname);

		localStorage.setItem('last_search_sdg_tab', tabname);
		document.querySelectorAll('.ps-tabs li').forEach(tab => tab.classList.remove('active'));
		event.currentTarget.classList.add('active');
	}

	toggle_bu_kpi(data)
	{
		data.show_kbu(!data.show_kbu());
	}

	toggle_projects_kpi(data)
	{
		data.show_p(!data.show_p());
	}

	toggle_bu_project(data)
	{
		data.show_bup(!data.show_bup());
	}

	toggle_kpi_project(data)
	{
		data.show_kpip(!data.show_kpip());
	}
}

class SDGSearchPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new SDGSearch(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'SDG Search';
		let last_search_sdg_tab = localStorage.getItem('last_search_sdg_tab') || 'kpi';
		document.querySelector(`.ps-tabs li[data-tabname='${last_search_sdg_tab}']`).classList.add('active');
	}

	async updateData ()
	{
		//GET SDGs
		try {
			let sdgs = await Grape.cache.fetch('SDGs');
			let with_img = sdgs.map(item => ({
				...item,
				img_path: `./SDG_icons/Goal-${String(item.sdg_nr).padStart(2, '0')}.png`,
				sdg_goal: item.goal,
				sdg_name: item.sdg_name,
				sdg_id: item.sdg_id,
				sdg_nr: item.sdg_nr,
				type: 'SDG'
			}));
			this.viewModel.sdgs(with_img);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while getting SDG data:', error);
		}
		
		//GET SDG Targets
		try {
			let targets = await Grape.cache.fetch('SDGTargets');
			let with_img = targets.map(item => ({
				...item,
				img_path: `./SDG_target_icons/Target_${String(item.target_nr)}.png`,
				target_description: item.description,
				sdg_id: item.sdg_id,
				sdg_target_id: item.sdg_target_id,
				target_nr: item.target_nr,
				type: 'Target'
			}))
			this.viewModel.targets(with_img);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while getting SDG target data:', error);
		}

		//GET SDG Targets Indicators
		try {
			let indicators = await Grape.cache.fetch('SDGTargetIndicators');
			let with_img = indicators.map(item => ({
				...item,
				img_path: `./SDG_icons/Goal-${String(item.sdg_nr).padStart(2, '0')}.png`,
				sdg_goal: item.goal,
				ind_description: item.ind_description,
				indicator_id: item.indicator_id,
				indicator_nr: item.indicator_nr,
				sdg_id: item.sdg_id,
				sdg_name: item.sdg_name,
				sdg_nr: item.sdg_nr,
				sdg_target_id: item.sdg_target_id,
				target_description: item.target_description,
				target_nr: item.target_nr,
				type: 'Indicator'
			}));
			this.viewModel.indicators(with_img);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while getting SDG target indicator data:', error);
		}
	}

	async updateInfo(id) {
		//GET related kpis and projects for items:
		let type = this.viewModel.selected_item().type;
		let options;
		let project_opions;
		if (type === 'SDG')
		{
			options = {
				table: 'v_sdg_kpi',
				schema: 'sdg',
				filter_join: 'AND',
				join: 'OR',
				filter: [{
					field: 'sdg_id',
					operand: '=',
					value: id
				}]
			}

			project_opions = {
				table: 'v_sdg_projects',
				schema: 'sdg',
				filter_join: 'AND',
				join: 'OR',
				filter: [{
					field: 'sdg_id',
					operand: '=',
					value: id
				}]
			}
		} else if (type === 'Target')
		{
			options = {
				table: 'v_sdg_target_kpi',
				schema: 'sdg',
				filter_join: 'AND',
				join: 'OR',
				filter: [{
					field: 'sdg_target_id',
					operand: '=',
					value: id
				}]
			}
			project_opions = {
				table: 'v_sdg_target_project',
				schema: 'sdg',
				filter_join: 'AND',
				join: 'OR',
				filter: [{
					field: 'sdg_target_id',
					operand: '=',
					value: id
				}]
			}
		} else if (type === 'Indicator')
		{
			options = {
				table: 'v_sdg_target_indicator_kpi',
				schema: 'sdg',
				filter_join: 'AND',
				join: 'OR',
				filter: [{
					field: 'indicator_id',
					operand: '=',
					value: id
				}]
			}
			project_opions = {
				table: 'v_sdg_target_indicator_project',
				schema: 'sdg',
				filter_join: 'AND',
				join: 'OR',
				filter: [{
					field: 'indicator_id',
					operand: '=',
					value: id
				}]
			}
		} else
			return;

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				let kpis = result.records.map(x => ({
					...x,
					show_kbu: ko.observable(false),
					show_p: ko.observable(false)
				}));
				this.viewModel.item_kpis(kpis);

				this.viewModel.item_kpis().forEach(item => {
					item.linked_business_units = item.linked_business_units || [];
					item.linked_projects = item.linked_projects || [];
				});
			}

			else 
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', project_opions);
			if (result.status != 'ERROR')
			{
				let projects = result.records.map(x => ({
					...x,
					show_bup: ko.observable(false),
					show_kpip: ko.observable(false)
				}));

				this.viewModel.item_projects(projects);

				this.viewModel.item_projects().forEach(item => {
					item.linked_business_units = item.linked_business_units || [];
				});
			}
			else 
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}		
	}	
}

export default {
	route: '[/]sdg/search',
	page_class: SDGSearchPage,
	template: template
}
