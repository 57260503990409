import template from './edit-project-user.html';

class EditProjectUserViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.mode = ko.observable();
		this.user_id = ko.observable();
		this.role_names = ko.observableArray([]);
		this.selected_role = ko.observableArray([]);
		this.available_projects = ko.observableArray([]);
		this.selected_projects = ko.observable();
		this.available_users = ko.observableArray([]);
		this.selected_user = ko.observable();
		this.username = ko.observable();
		this.project_name = ko.observable();
		this.linked_roles = ko.observableArray([]);
		this.selected_linked_role = ko.observable();
		this.linked_users = ko.observableArray();
		this.add_disabled = ko.observable(true);
		this.remove_disabled = ko.observable(true);
		this.add_projects = ko.observableArray();
		this.all_projects = ko.observableArray();
		this.project_users = ko.observable();
		this.linked_projects = ko.observableArray();
		this.all_selected = ko.observable(false);
		this.search_string = ko.observable('');
		this.title = ko.observable('');
		this.current_user = ko.observable();
		this.selected_linked_role = ko.observable();

		this.selected_user.subscribe((value) => {
			if (value[0])
				this.username(value[0].username);
			this.linked_projects([]);
			this.linked_roles([]);
			this.selected_projects([]);
			this.available_projects([]);
			this.selected_role([]);
			this.selected_linked_role(undefined);
			this.dialog.updateData();
		});

		this.selected_linked_role.subscribe((value) => {
			this.selected_role([]);
			this.available_projects(this.all_projects());
		
			if (value && this.username())
			{
				let user_projects = this.project_users().find(user => user.username === this.username())?.all_projects || [];

				let filtered_projects = user_projects.filter(project => project.roles.includes(value));
		
				let linked_projects = filtered_projects.map(project => ({
					...project,
					name: project.project_name
				}));

				let available_projects = this.all_projects().filter(project => 
					!linked_projects.some(linked_project => linked_project.name === project.name)
				);

				this.linked_projects(linked_projects);
				this.available_projects(available_projects);
			} 
			else
			{
				this.linked_projects([]);
				this.available_projects(this.all_projects());
			}
		});

		this.selected_role.subscribe((selected_role) => {
			this.available_projects(this.all_projects());
			if (selected_role.length > 0)
				this.selected_linked_role(undefined);
		});

		this.username.subscribe((value) => {
			if (value !== undefined)
			{
				let matching_user = this.available_users().find(user => user.username === value[0]);
				if (matching_user)
					this.user_id(matching_user.user_id);
			}
			else
			{
				let matching_user = this.available_users().find(user => user.username === this.available_users()[0].username);
				if (matching_user)
					this.user_id(matching_user.user_id);

				this.username(this.available_users()[0].username);
			}
		});

		this.all_selected.subscribe((value) => {
			if (value === true)
			{
				this.selected_projects(ko.utils.arrayMap(this.available_projects(), project => {
					return { name: project.name, project_id: project.project_id };
				}));
			}
		});

		this.search_string.subscribe(async (value) => {
			await this.dialog.load_projects();

			if (value != '')
			{
				// Filter out available_projects to only include matches that are in all_projects after the load
				this.available_projects(this.all_projects().filter(project =>
					this.available_projects().some(available_project => available_project.name === project.name)
				));
			}
			else
				this.available_projects(this.all_projects());
		});
	}

	async btn_add_role_click()
	{
		let role = this.selected_role();

		if ((!role.length > 0 && this.selected_linked_role() == undefined) || this.username() == undefined)
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Please ensure that a role and a user is selected!'});
			return;
		}

		let projects_to_add = this.selected_projects();
		this.add_projects(this.selected_projects());
	
		if (projects_to_add && projects_to_add.length > 0)
		{
			// Add the Projects to linked_projects
			this.linked_projects.push(...projects_to_add);
	
			// Remove the Projects from available_projects based on their names
			projects_to_add.forEach(PROJECT => {
				this.available_projects.remove(project => project.project_id === PROJECT.project_id);
			});
	
			// If all_selected is true, clear selected_projects
			if (this.all_selected())
			{
				this.selected_projects([]);
				this.all_selected(false);
			}
		}
	
		this.add_disabled(true);
		this.remove_disabled(true);

		if (!role.length > 0)
		{
			let projects_to_add = this.add_projects().map(project => project.project_id);

			let user = {
				project_ids: projects_to_add,
				user_data: {
					'add': [{
						'username': this.username(),
						'role': this.selected_linked_role()
					}]
				}
			};

			let response = await fetch('/api/catalytix/catalytix', {
				method: 'POST',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify(user)
			});

			if (!response.ok)
			{
				Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Error occurred' });
				console.error('Unknown Error:', error_data);
			}
			else
				Grape.alerts.alert({ type: 'success', title: 'Saved', message: 'Saved successfully!' });
		}
	}

	async btn_remove_role_click ()
	{
		let role = this.selected_role();

		if ((!role.length > 0 && this.selected_linked_role() == undefined) || this.username() == undefined)
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Please ensure that a role and a user is selected!'});
			return;
		}

		let projects_to_remove = this.selected_projects();

		if (projects_to_remove && projects_to_remove.length > 0)
		{
			this.available_projects.push(...projects_to_remove);
			ko.utils.arrayForEach(projects_to_remove, role => {
				this.linked_projects.remove(role);
			});
			this.selected_projects([]);
		}

		this.add_disabled(true);
		this.remove_disabled(true);

		if (!role.length > 0 && this.selected_linked_role() != undefined)
		{
			let to_remove = projects_to_remove.map(project => project.project_id);
			let success;

			for (let item of to_remove)
			{
				try {
					let options ={
						project_id: item,
						username: this.username()
					}

					let response = await fetch('/api/catalytix/catalytix', {
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(options),
					});

					if (response.ok)
						success = true;
						
				} catch (error)
				{
					await Grape.alerts.alert({type: 'error', title: 'Error', message: 'Error removing Project(s)' + error.message});
					break;
				}
			}

			if (success)
				Grape.alerts.alert({title: 'Success!', message: 'Project(s) removed successfully!', type: 'success'});
		}
	}

	async btn_cancel_click ()
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: '', message: 'Are you sure you want to close this dialog?'});
		if (response)
			this.dialog.close();
	}

	async btn_save_click ()
	{
		let projects_to_add = this.add_projects().map(p => p.project_id);
		let role = this.selected_role();
		
		if (!role.length > 0 || this.username() == undefined)
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Please ensure that a role and a user is selected!'});
			return;
		}

		try
		{
			let success = true;
			let error_data;

			for (let x of role)
			{
				let user = {
					project_ids: projects_to_add,
					user_data: {
						'add': [{
							'username': this.username(),
							'user_id': this.user_id(),
							'role': x
						}]
					}
				};
	
				let response = await fetch('/api/catalytix/catalytix', {
					method: 'POST',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(user)
				});

				if (!response.ok)
				{
					error_data = response.json();
					success = false;
				}
			}

			if (success)
			{
				await Grape.alerts.alert({ type: 'success', title: 'Saved', message: 'Saved successfully!' });
				this.dialog.close(true);
			}
			else
			{
				Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Error occurred' });
				console.error('Unknown Error:', error_data);
			}
		} catch (error) {
			console.error('Save Error:', error);
		}
	}

	get_current_user_role_info(filtered_roles)
	{
		this.linked_roles([]);
		filtered_roles.forEach(role => {
			let match = this.role_names().find(x => x.name.toLowerCase() === role);
			if (match !== undefined && !this.linked_roles().some(x => x.name === match.name))
			{
				this.linked_roles.push(match);
				this.role_names.remove(match);
			} 
			else
				console.log(`No match found for role: ${role}`);
		});
	}
}

class EditProjectUserPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditProjectUserViewModel(this);
		this.viewModel.mode(bindings.mode);
	}

	async init ()
	{
		document.title = 'Edit User';

		await this.updateData();

		this.viewModel.selected_user([this.bindings.name]);
		this.viewModel.username(this.bindings.name);

		if (this.viewModel.mode() === 'new')
		{
			this.viewModel.title('ADD PROJECT USER');
			this.viewModel.linked_users(this.bindings.added_users);
			this.viewModel.linked_roles([]);
		}

		if (this.viewModel.mode() === 'edit') {
			let name = this.bindings.user_data.username;
			this.viewModel.username(name);
			this.viewModel.title(`EDIT PROJECT USER: ${name}`);
			
			let roles = this.bindings.user_data.roles.split(', ').map(role => role.trim().toLowerCase());
			let filtered_roles = roles.filter(role => role !== null);
			this.viewModel.get_current_user_role_info(filtered_roles);
		}
	}

	async updateData ()
	{
		// Load roles
		try {
			let result = {
				table: 'kpi_role',
				schema: 'kpi',
				offset: 0,
				limit: 1000,
				filter: []
			}

			let response = await Grape.fetches.getJSON('/api/record', result);
			if (response.status != 'ERROR')
				this.viewModel.role_names(response.records);

		} catch (error)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		// Load users
		try {
		let result = await Grape.cache.fetch('Users');
			this.viewModel.available_users(result);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		await this.load_projects();

		// Load Project Users
		try {
			let result = await Grape.cache.fetch('ProjectUser');
			this.viewModel.project_users(result.map(user => ({
				...user,
				projects: user.all_projects.map(p => ({
					project_id: p.project_id,
					roles: p.roles,
					project_name: p.name
				}))
			})));

			try {
				if (this.viewModel.username())
				{
					let user = result.filter(user => user.username == this.viewModel.username());
					this.viewModel.current_user(user);
					if (user)
					{
						let current_roles = this.viewModel.current_user()[0].roles;
						if (current_roles)
						{
							let roles = current_roles.split(', ').map(role => role.trim().toLowerCase());
							let filtered_roles = roles.filter(role => role !== null);
							this.viewModel.get_current_user_role_info(filtered_roles);
						}
					}
				}
			} catch {}
		} catch (error)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}
	}

	async load_projects ()
	{
		// Load projects
		try {
			let options = {
				table: 'dv_projects',
				schema: 'kpi',
				filter: [],
				limit: 1000
			}

			//LOGIC: Search
			if (this.viewModel.search_string() && this.viewModel.search_string() != '')
			{
				options.filter.push({
					field: 'name',
					operand: 'ILIKE',
					value: `%${this.viewModel.search_string()}%`
				})
			}

			let result = await Grape.fetches.getJSON('/api/record', options)
			if (result.status != 'ERROR')
				this.viewModel.all_projects(result.records);
		} catch (error)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}
	}
}

export default {
	name: 'EditProjectUserDialog',
	dialog_class: EditProjectUserPage,
	template: template,
	provider: 'ps'
}
