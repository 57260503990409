/**
 * KPI Setup Page
 */
import template from './kpi-setup.html';
import KPIViewModel from '../../lib/KPIViewModel.js';

class KPISetupVM
{
	constructor (page)
	{
		this.page = page;
		this.kpivm = new KPIViewModel(page.bindings.kpi_id || null);
		this.current_route = ko.observable();
		this.current_page_title = ko.observable('');

		//permissions
		this.view_users = ko.observable(false);
		this.add_indicator = ko.observable(false);
		
		this.selected_page = ko.computed(() => {
			return window.AppState.selected_page();
		});

		if (page.bindings.from === 'overview')
		{
			window.AppState.selected_page('Setup');
			localStorage.setItem('selected_navbar_page', 'Setup');
		}

		this.search_string = ko.observable();
		this.kpis = ko.observableArray();
		this.kpi_id = ko.observable();
		this.all_kpis = ko.observableArray();

		this.search_string.subscribe((value) => {
			this.kpi_id(value.kpi_id);
			this.page.updateData();
		});
		
		this.tabs = ko.observableArray([{
			route: '[/]kpi/bu-related',
			title: 'Associated Business Unit(s)',
			heading_text: 'Associated Business Unit(s) and Targets',
			icon: 'fa-thin fa-building',
			visible: true
		}, {
			route: '[/]kpi/kpi-related',
			title: 'Reporting',
			heading_text: 'Frameworks',
			icon: 'fa-thin fa-info-circle',
			visible: true
		}, {
			route: '[/]kpi/projects-related',
			title: 'Associated Project(s)',
			heading_text: 'Associated Project(s)',
			icon: 'fa-thin fa-diagram-project',
			visible: true
		},{
			route: '[/]kpi/kpi-users',
			title: 'Users',
			heading_text: 'Users',
			icon: 'fa-thin fa-users',
			visible: this.view_users()
		}]);

		this.current_route.subscribe((newPageId) => {
			let matchingTab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === newPageId;
			});
			this.current_page_title(matchingTab ? matchingTab.heading_text : '');
		}, this);

		// Re-evaluate the users tab visibility
		this.view_users.subscribe(val => {
			let users_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === '[/]kpi/kpi-users';
			});

			if (users_tab)
			{
				users_tab.visible = val;
				this.tabs.valueHasMutated();
			}
		});

		// Handle the visible tabs (used in html)
		this.visible_tabs = ko.computed(() => {
			return ko.utils.arrayFilter(this.tabs(), (tab) => {	
				return tab.visible === true;
			});
		});
	}

	async btn_create_kpi_click ()
	{
		Grape.dialog.open('EditKPI', {kpi_id: null, all_kpis: this.all_kpis()});
		this.page.updateData();
	}

	async clear_kpi_value_filter_click ()
	{
		this.search_string('');
	}

	async load ()
	{
		return await this.kpivm.load();
	}
	
	load_tab (route)
	{
		window.localStorage.setItem('last_kpisetupsidebar_page', route);
		const element = this.page.element.querySelector('#page_content');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id '${route}' not found`);
		
		Grape.pages.load_page(page, element, {kpi_id: this.kpivm.kpi_id()}, {}, () => {});
	}
}

class KPISetupPage 
{
	constructor (bindings, element, page)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new KPISetupVM(this);
	}
	
	async init ()
	{
		document.title = 'KPI Setup';

		let permission = await window.user_permission(['admin', 'View ESG Indicator Users Page']);
		if (permission)
			this.viewModel.view_users(permission);

		let permission_add = await window.user_permission(['Setup ESG Indicator', 'admin']);
		if (permission_add)
			this.viewModel.add_indicator(permission_add);

		if (this.bindings.kpi_id)
		{
			window.localStorage.setItem('last_visited_kpi_id', this.bindings.kpi_id);
			this.viewModel.kpivm.kpi_id(this.bindings.kpi_id);
			this.viewModel.load();
		} 
		else if (window.localStorage.getItem('last_visited_kpi_id'))
		{
			let last_kpi_id = window.localStorage.getItem('last_visited_kpi_id');
			this.viewModel.kpivm.kpi_id(last_kpi_id);
			this.viewModel.load();
		} 
		else
			throw new Error('Page not found (no linked KPI)');

		this.viewModel.load();
		if (window.localStorage.getItem('last_kpisetupsidebar_page'))
			this.viewModel.load_tab(window.localStorage.getItem('last_kpisetupsidebar_page'));
		else
			this.viewModel.load_tab('[/]kpi/bu-related');

		//Get KPI info for bindings
		let options_all = {
			table: 'dv_kpis',
			schema: 'kpi',
			filter: []
		}
		let result = await Grape.fetches.getJSON('/api/record', options_all);
		if (result.status != 'ERROR') 
			this.viewModel.all_kpis(result.records);
		else 
			throw new Error(result.message || result.code);
	}

	async updateData()
	{
		if (this.bindings.kpi_id && this.bindings.kpi_id != this.viewModel.kpivm.kpi_id())
		{
			this.viewModel.kpivm.kpi_id(this.bindings.kpi_id);
			this.viewModel.load();
		}

		//Get KPI info for searching
		let options = {
			table: 'dv_kpis',
			schema: 'kpi',
			filter: []
		}

		if (this.viewModel.search_string() && this.viewModel.search_string().trim() != '')
		{
			options.filter.push({
				field: 'name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_string()}%`
			});

			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR') 
				this.viewModel.kpis(result.records);
			else 
				throw new Error(result.message || result.code);
		}
		else 
			this.viewModel.kpis([]);
	}
}

export default {
	route: '[/]kpi/kpi-setup/:kpi_id',
	page_class: KPISetupPage,
	template: template
}
