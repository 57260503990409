import template from './export-data.html';

class ExportDataVM
{
	constructor (page)
	{
		this.page = page;
		this.system_setup = ko.observable(true);
		this.users = ko.observable(true);
		this.indicators = ko.observable(true);
		this.business_unit_setup = ko.observable(true);
		this.indicator_bu_baseline_and_target = ko.observable(true);
		this.indicator_bu_measures = ko.observable(true);
		this.projects_setup = ko.observable(true);
		this.projects_targets = ko.observable(true);
		this.projects_measures = ko.observable(true);
		this.projects_updates = ko.observable(true);
		this.export_file_name = ko.observable('');
	}

	async btn_export_click ()
	{
		let sessionid = Grape.currentSession.session_id;

		let filename;

		if (this.export_file_name() === '')
			filename = 'Data Export ' + moment().format('MM_DD_YYYY') + '.xlsx';
		else
			filename = this.export_file_name() + '.xlsx';

		let options = {};

		if (this.system_setup()) options["System Setup"] = "/api/DataExport-SystemSetup";
		if (this.users()) options["Users"] = "/api/DataExport-Users";
		if (this.indicators()) options["KPIs"] = "/api/DataExport-KPIs";
		if (this.business_unit_setup()) options["BU setup"] = "/api/DataExport-BusinessUnit";
		if (this.indicator_bu_baseline_and_target()) options["KPI_BU Baseline and Targets"] = "/api/DataExport-KPI_BU_target";
		if (this.indicator_bu_measures()) options["KPI_BU measures"] = "/api/DataExport-KPI_BU_measures";
		if (this.projects_setup()) options["Projects Setup"] = "/api/DataExport-ProjectsSetup";
		if (this.projects_targets()) options["Projects Targets"] = "/api/DataExport-ProjectsTargets";
		if (this.projects_measures()) options["Projects Measures"] = "/api/DataExport-ProjectsMeasures";
		if (this.projects_updates()) options["Projects Updates"] = "/api/DataExport-ProjectsUpdates";	

		let request_data = {
			options: options,
			filename: filename,
			sessionid: sessionid
		};

		try
		{
			let result = await Grape.fetches.postJSON('/export/system/data', request_data);
			
			if (result.status != 'ERROR')
				console.log('Success!', result);
			else
				throw new Error(result.message || result.code);
		}
		catch (error)
		{
			console.error(error);
		}
	}
}

class ExportDataPage
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ExportDataVM(this);
	}

	async init ()
	{
		document.title = 'Export Data';
	}
}

export default {
	route: '[/]admin/export-data',
	page_class: ExportDataPage,
	template: template
}
