import template from './edit-project-objective.html';

class EditProjectObjectiveViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.objectives = ko.observableArray([]);
		this.project_id = ko.observable();
	}

	btn_add_objective_click (data)
	{
		this.objectives.push({name: undefined});
	}

	btn_remove_objective_click (data)
	{
		this.objectives.remove(data);
	}

	async btn_save_click()
	{
		let objectData = {
			project_id: this.project_id(),
			fields: {
				add: [{ name: 'objective', value: this.objectives() }],
			},
		};

		try
		{
			let response;
			if (objectData.project_id !== undefined)
			{
				response = await fetch('/api/kpi/project', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(objectData),
				});

				if (response.ok)
				{
					let result = await response.json();
					if (result.status === 'OK')
					{
						await Grape.alerts.alert({type: 'success', title: 'Saved', message: 'Project Objective saved successfully!'});
						this.dialog.close(true);
					} 
					else
						console.error('Error:', result.message);
				}
			}
		} catch (error) {
			console.error('Error:', error);
		}
	}

	async btn_close_click ()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}
class EditProjectObjectiveClass
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditProjectObjectiveViewModel(this); // Name of the ViewModel defined above
		this.name = 'EditProjectObjective'; // Unique dialog Name
		this.parentViewModel = bindings.page;

		if (bindings.objectData)
		{
			let objectData = bindings.objectData;

			if (objectData.objective)
			{
				try {
					let objectivesArray = JSON.parse(objectData.objective);
					this.viewModel.objectives(objectivesArray);
				}
				catch (error) {
					console.error('Error parsing project objectives:', error);
				}
			}
		}
		this.viewModel.objectives(bindings.objectives && bindings.objectives.length > 0 ? bindings.objectives : [{ name: undefined }]);
		this.viewModel.project_id(bindings.project_id);
	}
}

export default {
	name: 'EditProjectObjective',
	dialog_class: EditProjectObjectiveClass,
	template: template,
	provider: 'ps'
}
