import template from './sdg-overview.html';
import { SDGTest } from './sdg-overview-sidebar';

class SDGOverviewVM
{
	constructor (page)
	{
		this.page = page;
		this.sdgvm = new SDGTest();
		this.is_loading = ko.observable(false);
		document.title = 'SDG Overview'
		this.sdgs = ko.observableArray();
		this.sdg_kpis = ko.observableArray();
		this.show_popup = ko.observable();
		this.sdg_projects = ko.observableArray();
		this.opened_popup = ko.observable();
		this.sdg_logo_gif = ko.observable('./climate-logo.gif');
		this.selected_page = ko.computed(() => {
			return window.AppState.selected_page();
		});
	}

	hide_popup (data)
	{		
		data.show_popup(false);
	}

	btn_open_search_click()
	{
		this.sdgvm.change_tab();
	}

	display_popup(data)
	{
		this.opened_popup(data);
		data.show_popup(true);
	}

	async btn_view_sdg_info_click (data)
	{
		await Grape.dialog.open('SDGInfo', {sdg_info: data});
	}

	async btn_open_sdg_click (data)
	{
		window.open(`#/frameworks/sdg/detail/${data.sdg_id}`, '_self');
	}
}

class SDGOverviewPage
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new SDGOverviewVM(this);
		this.timer = null;
		this.viewModel.display_popup = this.viewModel.display_popup.bind(this.viewModel);
		this.viewModel.hide_popup = this.viewModel.hide_popup.bind(this.viewModel);
		this.viewModel.sdg_kpis = this.viewModel.sdg_kpis.bind(this.viewModel);
		this.viewModel.sdg_projects = this.viewModel.sdg_projects.bind(this.viewModel);
	}

	async init ()
	{
		document.title = 'SDG Overview';		
	}

	async updateData ()
	{
		//GET related kpi's and projects
		let options_links = {
			table: 'v_sdg_kpi_project',
			schema: 'sdg',
			offset: 0,
			limit: 50000
		}

		// Fetch and process KPIs and Projects
		try {
			let result = await Grape.fetches.getJSON('/api/record', options_links);
			if (result.status != 'ERROR') {
				let kpi_map = new Map(result.records.map(x => [x.sdg_id, x.kpis || []]));
				let project_map = new Map(result.records.map(x => [x.sdg_id, x.projects || []]));

				Grape.cache.fetch('SDGs').then(result => {
					let sdgs = result.map(sdg => ({
						...sdg,
						img_src: `./SDG_icons/Goal-${String(sdg.sdg_nr).padStart(2, '0')}.png`,
						show_popup: ko.observable(false),
						kpis: ko.observableArray(kpi_map.get(sdg.sdg_id) || []),
						projects: ko.observableArray(project_map.get(sdg.sdg_id) || []),
						total: ((kpi_map.get(sdg.sdg_id) || []).length + (project_map.get(sdg.sdg_id) || []).length)
					}));
	
					this.viewModel.sdgs(sdgs);
					
				}).catch(error => {
					Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
					console.error(error);
				});
			}
		} catch (error) {
			console.error(error);
		}
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]sdg/overview',
	page_class: SDGOverviewPage,
	template: template
}
