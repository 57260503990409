import template from './kpi-data-warnings.html';

class KPIDataWarningsViewModel
{
	constructor (page)
	{
		document.title = 'Indicator Data Checks';
		this.page = page;

		// permissions
		this.is_visible = ko.observable(false);

		this.selected_data_check_tab = ko.observable('data_quality');
		this.user_features = ko.observableArray();
	}

	btn_back_click ()
	{
		window.Grape.navigate('[/]kpi/kpi-list');
	}
}

class KPIDataWarningsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPIDataWarningsViewModel(this);
		this.onload = true;
		this.page = this;
	}

	async updateData ()
	{
		let permission = await window.user_permission([]);
		if (permission)
			this.viewModel.is_visible(permission);
	}
}

export default {
	route: '[/]kpi/kpi-data-warnings',
	page_class: KPIDataWarningsPage,
	template: template
}
