import template from './BaselinePerBU.html';

class BaselinePerBUvm
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.kpi_id = ko.observable();
		this.kpi_nr = ko.observable();
		this.kpi_name = ko.observable();
		this.baseline_date = ko.observable();
		this.bu_nr = ko.observable();
		this.bu_name = ko.observable();
		this.bu_id = ko.observable();
		this.default_uom = ko.observable();
		this.baseline_value = ko.observable();
		this.kpi_business_units = ko.observableArray();
		this.selected_bu = ko.observable();
		this.projects = ko.observableArray();
		this.selected_project = ko.observable();
		this.target_value = ko.observable();
		this.target_date = ko.observable();
		this.project_nr = ko.observable('');
		this.project_name = ko.observable('');
		this.project_id = ko.observable('');
		this.time_period_options = ko.observableArray();
		this.selected_kpi = ko.observable();
		this.selected_uom = ko.observable(this.default_uom());
		this.kpi_bu_target_id = ko.observable();
		this.mode = ko.observable();
		this.uom_options = ko.observableArray();
		this.kpi_uom = ko.observable();
		this.selected_period = ko.observable();
		this.cumulative = ko.observable(false);
		this.snapshot = ko.observable(false);
		this.increase = ko.observable(false);
		this.decrease = ko.observable(false);

		this.cumulative.subscribe((newVal) => {
			if (newVal === true)
				this.snapshot(false);
		});

		this.snapshot.subscribe((newVal) => {
			if (newVal === true)
				this.cumulative(false);
		});

		this.increase.subscribe((newVal) => {
			if (newVal === true)
				this.decrease(false);
		});

		this.decrease.subscribe((newVal) => {
			if (newVal === true)
				this.increase(false);
		});
		
		this.selected_bu.subscribe((value) => {
			this.bu_nr(value.bu_nr);
			this.bu_name(value.business_unit_name);
			this.bu_id(value.business_unit_id);
		});

		this.selected_project.subscribe((value) => {
			if (value)
			{
				this.project_nr(value.project_nr);
				this.project_name(value.project_name);
			}
			else
				this.project_nr('');
				this.project_name('');
		});

		this.selected_kpi.subscribe((value) => {
			this.kpi_id(value.kpi_id);
			this.kpi_nr(value.kpi_nr);
			this.kpi_name(value.kpi_name);
			this.default_uom(value.uom);
			this.selected_uom(value.uom);
		});
	}

	async btn_save_click ()
	{
		let result = {};
		
		if (!this.kpi_name()) 
		{
			window.document.getElementById('kpi-select').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please choose an indicator'});
		}
		else if (!this.bu_name())
		{
			window.document.getElementById('bu-select').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please choose a business unit'});
		}
		else if (!this.baseline_value())
		{
			window.document.getElementById('baseline_input').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please enter a baseline value'});
		}
		else if (!this.baseline_date())
		{
			window.document.getElementById('baseline_date_input').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please choose a baseline date'});
		}
		else if (!this.target_value())
		{
			window.document.getElementById('target_input').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please enter a target value'});
		}
		else if (!this.target_date())
		{
			window.document.getElementById('target_date_input').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please choose a target date'});
		}
		else
		{
			if (this.mode() === 'from_kpi' || this.mode() === 'edit_from_kpi')
			{
				result = {
					target_id: this.kpi_bu_target_id(),
					kpi_id: this.kpi_id(),
					baseline: this.baseline_value(),
					baseline_date: this.baseline_date(),
					nominal_target: this.target_value(),
					target_date: this.target_date(),
					business_unit: this.bu_name,
					business_unit_id: this.bu_id(),
					kpi_business_unit_id: this.selected_bu().kpi_business_unit_id,
					measure_period: this.selected_period(),
					target_uom: this.selected_uom(),
					cumulative: this.cumulative(),
					increase: this.increase()
				}

				if (this.selected_project())
					result.project_id = this.selected_project().project_id;
				else
					result.project_id = null;

			} 
			else if (this.mode() === 'from_bu' || this.mode() === 'edit_from_bu')
			{
				result = {
					target_id: this.kpi_bu_target_id(),
					kpi_id: this.kpi_id(),
					baseline: this.baseline_value(),
					baseline_date: this.baseline_date(),
					nominal_target: this.target_value(),
					target_date: this.target_date(),
					business_unit: this.bu_name(),
					business_unit_id: this.bu_id(),
					kpi_business_unit_id: this.selected_kpi().kpi_business_unit_id,
					measure_period: this.selected_period(),
					target_uom: this.selected_uom(),
					cumulative: this.cumulative(),
					increase: this.increase()
				}
				if (this.selected_project())
					result.project_id = this.selected_project().project_id;
				else
					result.project_id = null;
			}
			
			let response = await fetch('api/kpi/kpi-target', {
				method: 'POST',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify(result)
			});
	
			if (response.ok)
				console.log('Baseline target per BU added successfully');
			else
				throw new Error(response.message || response.code || 'Unknown error');
	
			this.dialog.close();
		}
	}

	async btn_close_click()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to cancel?', type: 'warning'});
		if (response)
			this.dialog.close(false);	
	}
}

class BaselinePerBU
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new BaselinePerBUvm(this);
		this.name = 'BaselinePerBU';
		this.viewModel.uom_options(bindings.uom_options);
		this.viewModel.kpi_id(bindings.kpi_id);
		this.viewModel.kpi_nr(bindings.kpi_nr);
		this.viewModel.kpi_name(bindings.kpi_name);
		this.viewModel.bu_id(bindings.bu_id);
		this.viewModel.bu_name(bindings.bu_name);
		this.viewModel.bu_nr(bindings.bu_nr);
		this.viewModel.default_uom(bindings.target_uom || this.viewModel.default_uom());
		this.viewModel.kpi_uom(bindings.target_uom);
		//this.viewModel.kpi_business_units(bindings.kpi_business_units);
		this.viewModel.mode(bindings.mode);
		this.viewModel.time_period_options(bindings.period);

		this.init();
	}

	async init ()
	{
		document.title = 'Add Baseline Target per Business Unit Dialog';
		await this.updateData();

		if (this.bindings.baseline_data && this.bindings.baseline_data.cumulative === true)
			this.viewModel.cumulative(true);
		else
			this.viewModel.snapshot(true);

		if (this.bindings.baseline_data && this.bindings.baseline_data.increase === true)
			this.viewModel.increase(true);
		else
			this.viewModel.decrease(true);

		if (this.viewModel.mode() === 'from_kpi')
		{
			if (this.viewModel.uom_options().some(uom => uom.name === this.bindings.uom))
				this.viewModel.selected_uom(this.bindings.uom);
					
			else if (this.viewModel.uom_options().some(uom => uom.symbol_html === this.bindings.uom))
			{
				// Find the uom with the matching symbol_html
				let matching_uom = this.viewModel.uom_options().find(uom => uom.symbol_html === this.bindings.uom);

				if (matching_uom)
					this.viewModel.selected_uom(matching_uom.name);
			}
		}

		if (this.viewModel.mode() === 'edit_from_bu' || this.viewModel.mode() === 'edit_from_kpi')
		{
			this.viewModel.kpi_name(this.bindings.baseline_data.kpi_name);
			this.viewModel.kpi_id(this.bindings.baseline_data.kpi_id);
			this.viewModel.kpi_nr(this.bindings.baseline_data.kpi_nr);
			this.viewModel.bu_id(this.bindings.bu_id);
			this.viewModel.bu_name(this.bindings.baseline_data.business_unit_name);
			this.viewModel.project_id(this.bindings.baseline_data.project_id);
			//this.viewModel.selected_project(this.bindings.baseline_data.project_name);
			this.viewModel.default_uom(this.bindings.baseline_data.uom);
			this.viewModel.baseline_date(this.bindings.baseline_data.baseline_date);
			this.viewModel.baseline_value(this.bindings.baseline_data.baseline);
			this.viewModel.target_date(this.bindings.baseline_data.target_date);
			this.viewModel.target_value(this.bindings.baseline_data.nominal_target);
			this.viewModel.kpi_bu_target_id(this.bindings.baseline_data.target_id);
			this.viewModel.selected_period(this.bindings.baseline_data.measure_period);
			this.viewModel.time_period_options(this.bindings.period);
			this.viewModel.project_nr(this.bindings.baseline_data.project_nr);
			
			// Check to see if data from bindings exists in the arrays to populate the dropdowns accordingly
			let matching_kpi = ko.utils.arrayFirst(this.viewModel.kpi_business_units(), (kpi) => {
				return kpi.kpi_id === this.bindings.baseline_data.kpi_id;
			});
		
			if (matching_kpi)
				this.viewModel.selected_kpi(matching_kpi);

			let matching_project = ko.utils.arrayFirst(this.viewModel.projects(), (project) => {;
				return project.project_id === this.bindings.baseline_data.project_id;
			});

			if (matching_project)
			{
				this.viewModel.selected_project(matching_project);
				this.viewModel.project_nr(matching_project.project_nr);
			}
			
			let matching_bu = ko.utils.arrayFirst(this.viewModel.kpi_business_units(), (bu) => {
				return bu.business_unit_name === this.bindings.baseline_data.business_unit_name;
			});
		
			if (matching_bu)
				this.viewModel.selected_bu(matching_bu);

			let matching_uom = ko.utils.arrayFirst(this.viewModel.uom_options(), (uom) => {
				return uom.name === this.bindings.baseline_data.target_uom;
			});

			if (matching_uom)
				this.viewModel.selected_uom(matching_uom.name);
		}

		let kpi_select = window.document.getElementById('kpi-select');
		kpi_select.addEventListener('change', function() 
		{
			this.classList.remove('ps-invalid-input');
		});

		let bu_select = window.document.getElementById('bu-select');
		bu_select.addEventListener('change', function() 
		{
			this.classList.remove('ps-invalid-input');
		});

		let baseline_input = window.document.getElementById('baseline_input');
		baseline_input.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let baseline_date_input = window.document.getElementById('baseline_date_input');
		baseline_date_input.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let target_input = window.document.getElementById('target_input');
		target_input.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let target_date_input = window.document.getElementById('target_date_input');
		target_date_input.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});
	}

	async updateData ()
	{
		//GET Associated business units for this KPI
		let options = {
			table: 'v_kpi_business_units',
			schema: 'kpi',
			offset: 0,
			limit: 2000,
			filter_join: 'AND',
			join: 'OR',
			filter: []
		}

		//Get related projects
		if (this.viewModel.mode() === 'from_bu' || this.viewModel.mode() === 'edit_from_bu')
		{
			options.filter.push({
				field: 'business_unit_id',
				operand: '=',
				value: this.viewModel.bu_id()
			});
			
			try {
				let result = await Grape.fetches.getJSON('/api/record', options);
				if (result.status != 'ERROR')
				{
					this.viewModel.kpi_business_units(result.records);
				}
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error)
			}

			let data = {
				table: 'v_business_unit_projects',
				schema: 'kpi',
				filter: [{
					field: 'business_unit_id',
					operand: '=',
					value: this.viewModel.bu_id()
				}]
			};
	
			let result_project = await Grape.fetches.getJSON('/api/record', data);
			if (result_project.status != 'ERROR')
				this.viewModel.projects(result_project.records);
			else
				throw new Error(result_project.message || result_project.code);
		}

		if (this.viewModel.mode() === 'from_kpi' || this.viewModel.mode() === 'edit_from_kpi')
		{
			options.filter.push({
				field: 'kpi_id',
				operand: '=',
				value: this.viewModel.kpi_id()
			});

			try {
				let result = await Grape.fetches.getJSON('/api/record', options);
				if (result.status != 'ERROR')
				{
					this.viewModel.kpi_business_units(result.records);
				}
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error)
			}

			let data = {
				table: 'v_kpi_projects',
				schema: 'kpi',
				filter: [{
					field: 'kpi_id',
					operand: '=',
					value: this.viewModel.kpi_id()
				}]
			};
	
			let result_project = await Grape.fetches.getJSON('/api/record', data);
			if (result_project.status != 'ERROR')
				this.viewModel.projects(result_project.records);
			else
				throw new Error(result_project.message || result_project.code);
		}
	}
}

export default {
	name: 'BaselinePerBU',
	dialog_class: BaselinePerBU,
	template: template,
	provider: 'ps'
}
