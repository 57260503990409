import template from './project-user.html';
import ProjectViewModel from '../../../../lib/ProjectViewModel';

class ProjectUsersVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.projectvm = new ProjectViewModel(page.bindings.project_id || null );
		this.users = ko.observableArray([]);
		this.username = ko.observable();
		this.email = ko.observable();
		this.project_id = ko.observable();
		this.project_name = ko.observable();

		// permissions
		this.can_remove = ko.observable(false);

		// Computed observable to check if there are any users with roles
		this.no_users_with_roles = ko.computed(() => {
			return !this.users().some(user => user.roles && user.roles.trim().length > 0);
		});
	}

	async btn_new_user_click ()
	{
		let project_id = this.project_id();
		await Grape.dialog.open('EditProjectUserDialog', {project_id, mode: 'new', added_users: this.users()});
		this.page.updateData();
	}

	async btn_delete_user_click (data)
	{
		let project_id = this.project_id();
		let user_id = data.user_id;

		let answer = await Grape.alerts.confirm({type: 'warning', title: 'Warning', message: 'Are you sure you want to remove this project user?'});

		if (answer)
		{
			let response = await fetch('/api/catalytix/catalytix', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({user_id, project_id}),
			});

			if (!response.ok)
			{
				let error_data = await response.json();
				console.error('Error deleting user:', error_data);
				return;
			}
			else
			{
				await Grape.alerts.alert({type: 'success', title: 'Saved', message: 'Successfully removed Project User from all associated projects!'});
				this.page.updateData();
			}
		}
	}

	async load()
	{
		return await this.projectvm.load();
	}
}

class ProjectUsersPage
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ProjectUsersVM(this);
		this.timer = null;
		this.viewModel.project_id(bindings.project_id);
	}

	async init ()
	{
		document.title = 'Project Users';

		let permission = await window.user_permission(['admin']);
		if (permission)
			this.viewModel.can_remove(true);
	}

	async updateData ()
	{
		let user_data = {
			table: 'v_project_users',
			schema: 'kpi',
			sortfield: 'user_id',
			sortorder: 'ASC',
			filter:[]
		}

		let users = await Grape.fetches.getJSON('/api/record', user_data);

		if (users.status != 'ERROR')
		{
			let id = parseInt(this.viewModel.project_id());
			let p_users = users.records
				.filter(user => user.all_projects && user.all_projects.some(project => project.project_id === id))
				.map(user => {
					let matching_project = user.all_projects.find(project => project.project_id === id);
					return {
						...user,
						all_projects: matching_project ? [matching_project] : [{ roles: [] }]
					};
				});
			this.viewModel.users(p_users);
			
		}
		else
			throw new Error(users.message || users.code)
	}
}

export default {
	route: '[/]projects/project-users',
	page_class: ProjectUsersPage,
	template: template
}
