import template from './bu-project-users.html';
import BUViewModel from '../../../lib/BUViewModel.js';

class BUUsersVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.buuservm = new BUViewModel(page.bindings.bu_id || null);
		this.users = ko.observableArray([]);
		this.username = ko.observable();
		this.email = ko.observable();
		this.business_unit_id = ko.observable();

		// permissions
		this.can_remove = ko.observable(false);

		// Computed observable to check if there are any users with roles
		this.no_users_with_roles = ko.computed(() => {
			return !this.users().some(user => user.roles && user.roles.trim().length > 0);
		});
	}

	async btn_new_user_click ()
	{
		let business_unit_id = this.business_unit_id();
		await Grape.dialog.open('EditBUUserDialog', {business_unit_id, mode: 'new', added_users: this.users()});
		this.page.updateData();
	}

	async btn_delete_user_click (data)
	{
		let business_unit_id = this.business_unit_id();
		let user_id = data.user_id;

		let answer = await Grape.alerts.confirm({type: 'warning', title: 'Warning', message: 'Are you sure you want to remove this BU user?'});

		if (answer)
		{
			let response = await fetch('/api/catalytix/catalytix', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({user_id, business_unit_id}),
			});

			if (!response.ok)
			{
				let errorData = await response.json();
				console.error('Error deleting user:', errorData);
				return;
			}
			else
			{
				await Grape.alerts.alert({type: 'success', title: 'Saved', message: 'Successfully removed BU User from all associated business units!'});
				this.page.updateData();
			}
		}
	}
	async load ()
	{
		return await this.buuservm.load();
	}
}

class BUUsersPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new BUUsersVM(this);
		this.timer = null;
		this.viewModel.business_unit_id(bindings.bu_id);
	}

	async init ()
	{
		document.title = 'Business Unit Users';

		let permission = await window.user_permission(['admin']);
		if (permission)
			this.viewModel.can_remove(true);

		this.viewModel.load();
	}

	async updateData ()
	{
		let user_data = {
			table: 'v_business_unit_users',
			schema: 'kpi',
			sortfield: 'user_id',
			sortorder: 'ASC',
			filter: []
		}

		let users = await Grape.fetches.getJSON('/api/record', user_data);

		if (users.status != 'ERROR')
		{
			let id = parseInt(this.viewModel.business_unit_id());
			
			let b_users = users.records
				.filter(user => user.all_business_units && user.all_business_units.some(bu => bu.business_unit_id === id))
				.map(user => {
					let matchingBU = user.all_business_units.find(bu => bu.business_unit_id === id);
					return {
						...user,
						all_business_units: matchingBU ? [matchingBU] : [{ roles: [] }]
					};
				});

			this.viewModel.users(b_users);
		}
		else
			throw new Error(users.message || users.code)
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]bu/project-users',
	page_class: BUUsersPage,
	template: template
}
