/**
 * Cache for Projects in editKPIUser
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'UserKPIs',
	options: {
		tablename: 'dv_kpis',
		schema: 'kpi',
		filter: []
	}
};
