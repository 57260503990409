import template from './bu-related-projects.html';
import BUViewModel from '../../../lib/BUViewModel.js';

class BUProjectsVM
{
	constructor (page)
	{
		this.page = page;
		this.buprojectvm = new BUViewModel(page.bindings.bu_id || null);
		this.bu_id = ko.observable();
		this.bu_projects = ko.observableArray();
		this.bu_nr = ko.observable(this.buprojectvm.bu_nr);
		this.bu_note = ko.observable();
		this.name = ko.observable(page.bindings.name);
		this.bu_type_value = ko.observable();

		// permissions
		this.can_add = ko.observable(false);
	}

	async btn_add_related_project_click ()
	{
		await Grape.dialog.open('AddBUProject', {bu_id: this.bu_id(), bu_projects: this.bu_projects()});
		this.page.updateData();
	}

	async btn_remove_project_click (data)
	{
		let result = await Grape.alerts.confirm({
				type: 'warning',
				message: `Are you sure you want to unlink ${data.project_name}?`,
				title: 'warning'
		});

		if (result)
		{
			let options = {
				project_id: data.project_id,
				business_unit_id: data.business_unit_id
			}

			let response = await fetch('/api/kpi/business-unit-project-unlink', {
				method: 'DELETE',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify(options)
			});

			if (response.ok)
			{
				Grape.alerts.alert({
					type: 'success',
					title: 'Success',
					message: `${data.project_name} unlinked successfully`
				});
			}
			else 
				throw new Error(response.message || response.code || 'Unknown Error');
			this.page.updateData();
		}
	}

	async btn_edit_project_click (data)
	{
		await Grape.dialog.open('EditKPIBU', {mode: 'edit_project_bu', business_unit_id: data.business_unit_id, note: data.bu_project_note, project_business_id: data.project_business_id, project_id: data.project_id});
		this.page.updateData();
	}

	async load ()
	{
		return await this.buprojectvm.load();
	}
}

class BUProjectsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new BUProjectsVM(this);
		this.timer = null;
		this.viewModel.bu_id(bindings.bu_id);
		this.viewModel.name(bindings.name);
		this.viewModel.bu_type_value(bindings.business_unit_type_value);
		this.viewModel.bu_note(bindings.bu_project_note);
	}

	async init ()
	{
		document.title = 'Business Unit Projects';

		let permission = await window.user_permission(['admin', 'Setup Business Unit', 'Setup associated Projects BU']);
		if (permission)
			this.viewModel.can_add(true);

		this.viewModel.load();
	}

	async updateData ()
	{
		//Get Related Business units projects
		let options_bu = {
			table: 'v_business_unit_projects',
			schema: 'kpi',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'business_unit_id',
				operand: '=',
				value: this.viewModel.bu_id()
			}]
		}

		let result_bu = await Grape.fetches.getJSON('/api/record', options_bu);
		if (result_bu.status != 'ERROR')
			this.viewModel.bu_projects(result_bu.records);
		else 
			throw new Error(result_bu.message || result_bu.code);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]bu/related-projects',
	page_class: BUProjectsPage,
	template: template
}
