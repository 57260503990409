import template from './navbar.html';
import NavbarHelper from '@platsoft/ui-ps/src/navbar/NavbarHelper.js';

class NavbarViewModel extends NavbarHelper
{
	constructor (Grape)
	{
		super(Grape);
	}

	async init()
	{
		super.init();
		this.selected_page = ko.observable(localStorage.getItem('selected_navbar_page') || '');

		// permissions
		this.capturer = ko.observable(false);
		this.view_sdg = ko.observable(false);
		this.view_project_details = ko.observable(false);
		this.view_project_capture = ko.observable(false);
		this.view_kpi_setup = ko.observable(false);
		this.view_bu_setup = ko.observable(false);
		
		// Observable for the logo path
		this.logo_path = ko.observable(localStorage.getItem('logo_path') || './Catalytix_Logo_option_Final_Formats_Catalaytix_Logo_option_8.1.png');

		Grape.themes.mode.subscribe((newValue) => {
			this.update_logo();
		});

		window.Grape.update_visible_roles();

		let capture_permission = await window.user_permission(['Capture ESG Indicator Page', 'admin', 'ESG Indicator Value History Page', 'Approve Indicator Captured Values']);
		if (capture_permission)
			this.capturer(capture_permission);

		let sdg_permission = await window.user_permission(['View UN SDGs', 'Setup related SDG Indicators', 'Setup related SDG Projects', 'admin']);
		if (sdg_permission)
			this.view_sdg(sdg_permission);

		let permission_p_details = await window.user_permission(['admin', 'View Projects', 'Edit Project details and objectives', 'Setup Project', 'Setup associated BUs and ESG Indicators', 'Edit Project associated Targets', 'Project progress status and history', 'Update Project progress', 'Approve Update on Project progress', 'View Project Users Page', 'Add Project associated Targets']);
		if (permission_p_details)
			this.view_project_details(permission_p_details);

		let permission_p_capture = await window.user_permission(['admin', 'Update Project progress', 'Project progress status and history', 'Approve Update on Project progress']);
		if (permission_p_capture)
			this.view_project_capture(permission_p_capture);

		let permission_kpi_setup = await window.user_permission(['admin', 'Environmental Indicator List', 'Social Indicator List', 'Governance Indicator List', 'Selected ESG Indicator List', 'Setup ESG Indicator', 'Edit ESG Indicator', 'Setup associated BU Indicators', 'Setup associated Project Indicators', 'Setup associated Frameworks', 'View ESG Indicator Users Page']);
		if (permission_kpi_setup)
			this.view_kpi_setup(true);

		let permission_bu_setup = await window.user_permission(['admin', 'View Business Units', 'Edit Business Unit', 'Setup Business Unit', 'Setup associated Indicator BU', 'Setup associated Projects BU', 'View Business Unit Users Page']);
		if (permission_bu_setup)
			this.view_bu_setup(true);
	}

	log_out_btn ()
	{
		localStorage.removeItem('current_user_roles');
		window.location.href = '#/logout';
	}

	update_logo ()
	{
		let theme = Grape.themes.getMode();
		if (theme === 'dark')
			this.logo_path('Catalytix_Logo_option_Final_Formats_Catalytix_Logo_White.png');
		else
			this.logo_path('./Catalytix_Logo_option_Final_Formats_Catalaytix_Logo_option_8.1.png');
	
		localStorage.setItem('logo_path', this.logo_path());
	}
}

export default {
	name: 'ko-navbar',
	viewModel: NavbarViewModel,
	module_type: 'ko',
	template: template
}
