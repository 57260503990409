
class ProjectViewModel
{
	constructor(project_id)
	{
		this.project_id = ko.observable(project_id);
		this.name = ko.observable();
		this.external_pk = ko.observable('');
		this.project_nr = ko.observable('');
		this.description = ko.observable('');
		this.flag_names = ko.observable([]);
		this.start_date = ko.observable();
		this.end_date = ko.observable();
		this.project_type = ko.observable();
		this.project_owner = ko.observable();
		this.project_sponsor = ko.observable();
		this.project_champion = ko.observable();
	}

	async load()
	{
		let result = await Grape.fetches.getJSON('/api/record', {
			schema: 'kpi', table: 'dv_projects',
			filter: [{field: 'project_id', oper: '=', value: this.project_id()}],
			fields: ['name', 'project_nr', 'external_pk',  'flag_names', 'description',
				'start_date', 'end_date', 'project_type', 'project_sponsor', 'project_owner',
				'project_champion'
			]
		});

		if (result.records.length != 1)
			throw new Error('No data');
		this.name(result.records[0].name);
		this.external_pk(result.records[0].external_pk);
		this.project_nr(result.records[0].project_nr);
		this.description(result.records[0].description);
		this.flag_names(result.records[0].flag_names);
		this.start_date(result.records[0].start_date);
		this.end_date(result.records[0].end_date);
		this.project_type(result.records[0].project_type);
		this.project_sponsor(result.records[0].project_sponsor);
		this.project_owner(result.records[0].project_owner);
		this.project_champion(result.records[0].project_champion);
	}

	async save()
	{
		// TODO
		let obj = {
		};
	}
}

export default ProjectViewModel;
