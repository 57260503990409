
// CACHES
import UoM from './UoM.js';
import Features from './features.js';
import KPIFieldLookup from './KPIFieldLookup.js';
import KPIFlagLookup from './KPIFlagLookup.js';
import KPIUserRoleLookup from './KPIUserRoleLookup.js';
import KPIValueStatusLookup from './KPIValueStatusLookup.js';
import ProjectFieldLookup from './ProjectFieldLookup.js';
import ProjectUserRoleLookup from './ProjectUserRoleLookup.js';
import ProjectFlagLookup from './ProjectFlagLookup.js';
import ResourceRoleLookup from './ResourceRoleLookup.js';
import UserAccessKeyTypeLookup from './UserAccessKeyTypeLookup.js';
import Projects from './Projects.js';
import Users from './Users.js';
import UserKPIs from './UserKPIs.js';
import UserRoles from './UserRoles.js';
import BusinessUnitFlagLookup from './BusinessUnitFlagLookup.js';
import BusinessUnitTypeLookup from './BusinessUnitTypeLookup.js';
import BusinessUnitField from './BusinessUnitFieldLookup.js';
import TimePeriodTypeLookup from './TimePeriodTypeLookup.js';
import SDGs from './SDGs.js';
import SDGTargets from './SDGTargets.js';
import SDGIndicators from './SDGIndicators.js';
import SDGKPI from './SDGKPI.js';
import SDGProject from './SDGProject.js';
import SDGFeatures from './sdg_features.js';
import KPIS from './KPIS.js';
import KPIUsers from './KPIUsers.js';
import ProjectUser from './ProjectUser.js';
import BUUser from './BUUser.js';


export default [
	UoM,
	Features,
	KPIFieldLookup,
	KPIFlagLookup,
	KPIUserRoleLookup,
	KPIValueStatusLookup,
	ProjectFieldLookup,
	ProjectUserRoleLookup,
	ProjectFlagLookup,
	ResourceRoleLookup,
	UserAccessKeyTypeLookup,
	Projects,
	Users,
	UserKPIs,
	UserRoles,
	BusinessUnitFlagLookup,
	BusinessUnitTypeLookup,
	BusinessUnitField,
	TimePeriodTypeLookup,
	SDGs,
	SDGTargets,
	SDGIndicators,
	SDGKPI,
	SDGProject,
	SDGFeatures,
	KPIS,
	KPIUsers,
	ProjectUser,
	BUUser
];
