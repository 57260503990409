import template from './users-setup.html';

class UsersSetupVM
{
	constructor (page) 
	{
		this.page = page;
		this.selected_page = ko.computed(() => {
			return window.AppState.selected_page();
		});

		this.tabs = ko.observableArray([{
			route: '[/]users-setup/users',
			title: 'Users',
			icon: 'fa-solid fa-users'
		}, {
			route: '[/]users-setup/roles',
			title: 'Roles',
			icon: 'fa-solid fa-chart-network'
		}, {
			route: '[/]users-setup/features',
			title: 'Features',
			icon: 'fa-solid fa-sparkles'
		}]); 
		this.current_route = ko.observable();
	}
	
	load_tab (route)
	{
		window.localStorage.setItem('last_users_page', route);
		const element = this.page.element.querySelector('#page_content');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id '${route}' not found`);
		
		Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class UsersSetupPage 
{
	constructor (bindings, element, page) 
	{
		this.viewModel = new UsersSetupVM(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init ()
	{
		document.title = 'Users Setup';
		if (window.localStorage.getItem('last_users_page'))
			this.viewModel.load_tab(window.localStorage.getItem('last_users_page'));
		else
			this.viewModel.load_tab('[/]users-setup/users');
	}
}

export default {
	route: '[/]admin/users-setup',
	page_class: UsersSetupPage,
	template: template
}
