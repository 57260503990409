/**
 * Cache for all KPI users
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'KPIUsers',
	options: {
		tablename: 'v_kpi_users',
		schema: 'kpi'
	}
};