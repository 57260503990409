/**
 * Cache for all Project users
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'ProjectUser',
	options: {
		tablename: 'v_project_users',
		schema: 'kpi'
	}
};