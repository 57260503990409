import template from './back-to-top.html';

class BackToTopComponentViewModel
{
	constructor(params)
	{
		this.params = params;
	}

	back_to_top ()
	{
		console.log('running');
		window.document.body.scrollTop = 0; // For Safari
		window.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}
}

export default {
	name: 'back-to-top',
	viewModel: BackToTopComponentViewModel,
	module_type: 'ko',
	template: template
}
