import template from './sdg-about.html';

class SDGAbout
{
	constructor (page)
	{
		this.page = page;
		this.is_loading = ko.observable(false);
	}
}

class SDGAboutPage
{

	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new SDGAbout(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'SDG About';
	}

	async updateData ()
	{
	}
}

export default {
	route: '[/]sdg/about',
	page_class: SDGAboutPage,
	template: template
}
