import template from './project-targets-monthly.html';

class ProjectTargetsMonthlyVM
{
	constructor (dialog)
	{
		this.dialog = dialog;

		let last_target_tab = localStorage.getItem('last_target_tab') || 'table';
		this.selected_tab = ko.observable(last_target_tab);
		this.kpi_bu_targets = ko.observableArray();

		this.project_data = ko.observableArray([]);

		this.project_status = ko.observable();
		this.project_progress = ko.observable();
		this.project_stage = ko.observable();
		this.project_progress_notes = ko.observable();
		this.project_measure_notes = ko.observable();

		this.project_name = ko.observable();
		this.project_nr = ko.observable();
		this.project_owner = ko.observable();
		this.project_sponsor = ko.observable();
		this.project_champion = ko.observable();
		this.kpi_name = ko.observable();
		this.bu_name = ko.observable();
		this.uom = ko.observable();
		this.baseline_date = ko.observable();
		this.baseline_value = ko.observable();
		this.target_date = ko.observable();
		this.target_value = ko.observable();
		this.target_id = ko.observable();

		// Pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(15);
		this.page_count = ko.observable(1);

		// Date Filters
		this.selected_date = ko.observable('');
		this.sort_field = ko.observable('date');
		this.sort_order = ko.observable('ASC');

		this.project_status_style = ko.computed(() => {
			let style;
			switch (this.project_status())
			{
				case 'Green':
					style = { backgroundColor: '#87a747', color: 'white' };
					break;
				case 'Amber':
					style = { backgroundColor: '#eba61d', color: 'white' };
					break;
				case 'Red':
					style = { backgroundColor: '#911602', color: 'white' };
					break;
				default:
					style = {};
			}
			return style;
		});

		this.selected_date.subscribe((value) => {
			if (value === '')
			{
				this.project_status('');
				this.project_progress('');
				this.project_stage('');
				this.project_progress_notes('');
				this.project_measure_notes('');
			}
			else
				this.find_closest_date(value);

			this.dialog.updateData();
		});
	}

	find_closest_date (selected_date)
	{
		if (!selected_date) return;
	
		let closest_date = this.project_data().reduce((closest, current) => {
			let current_date = new Date(current.updated_date);
			let selected = new Date(selected_date);
			let closest_date = closest ? new Date(closest.updated_date) : null;
	
			if (closest_date === null || Math.abs(current_date - selected) < Math.abs(closest_date - selected))
				return current;
			return closest;
		});
	
		if (closest_date)
		{
			this.project_status(closest_date.overall_status);
			this.project_progress(closest_date.completion);
			this.project_stage(closest_date.project_stage);
			this.project_progress_notes(closest_date.progress_notes);
			this.project_measure_notes(closest_date.reading_note)
		}
	}

	sort_column (column)
	{
		if (this.sort_field() === column)
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		else
		{
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.dialog.updateData();
	}

	status_style (status)
	{
		let style;
		switch (status)
		{
			case 'Green':
				style = { backgroundColor: '#87a747', color: 'white' };
				break;
			case 'Amber':
				style = { backgroundColor: '#eba61d', color: 'white' };
				break;
			case 'Red':
				style = { backgroundColor: '#911602', color: 'white' };
				break;
			default:
				style = {};
		}
		return style;
	}

	switch_tabs (data, event)
	{
		let tabname = event.currentTarget.getAttribute('data-tabname');
		this.selected_tab(tabname);

		localStorage.setItem('last_target_tab', tabname);
		
		document.querySelectorAll('.ps-tabs li').forEach(tab => tab.classList.remove('active'));
		event.currentTarget.classList.add('active');
	}

	async btn_close_click ()
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: '', message: 'Are you sure you want to close this dialog?'});
		if (response)
			this.dialog.close(false);
	}

	async init_chart()
	{
		var myChart = echarts.init(document.getElementById('project-target-graph'));

		let chart_options = await Grape.fetches.getJSON('/api/echarts/chart/options',
		{
			chart_name: 'ProjectChart',
			data_source_options: {filter: [{f: 'target_id', o: '=', v: this.target_id()}]}
		});
		
		chart_options.legend.formatter = new Function('name', chart_options.legend.formatter);
		chart_options.tooltip.formatter = new Function('params', chart_options.tooltip.formatter);

		myChart.setOption(chart_options);
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.dialog.updateData();
	}
}

class ProjectTargetsMonthly
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProjectTargetsMonthlyVM(this);
		this.name = 'ProjectTargetsMonthly';

		this.viewModel.project_data(bindings.project_data);
		this.viewModel.project_name(bindings.data.project_name);
		this.viewModel.project_nr(bindings.data.project_nr);
		this.viewModel.project_owner(bindings.data.project_owner);
		this.viewModel.project_sponsor(bindings.data.project_sponsor);
		this.viewModel.project_champion(bindings.data.project_champion);
		this.viewModel.kpi_name(bindings.data.kpi_name);
		this.viewModel.bu_name(bindings.data.business_unit_name);
		this.viewModel.uom(bindings.data.uom);
		this.viewModel.baseline_date(bindings.data.baseline_date);
		this.viewModel.baseline_value(bindings.data.baseline_value);
		this.viewModel.target_date(bindings.data.target_date);
		this.viewModel.target_value(bindings.data.nominal_target);
		this.viewModel.target_id(bindings.data.target_id);

		this.init();
	}

	async init () 
	{
		document.title = 'View project monthly targets';
		let last_target_tab = localStorage.getItem('last_target_tab') || 'table';
		document.querySelector(`.ps-tabs li[data-tabname='${last_target_tab}']`).classList.add('active');

		await this.updateData();

		await this.viewModel.init_chart();
	}

	async updateData ()
	{
		//GET Baseline Target per Business Unit
		let options = {
			table: 'v_project_targets_monthly_rag',
			schema: 'kpi',
			offset: 0,
			filter_join: 'AND',
			limit: 100,
			join: 'OR',
			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field(),
			filter: [{
				field: 'target_id',
				operand: '=',
				value: this.viewModel.target_id()
			}]
		}

		//LOGIC: Pagination
		if(this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		if (this.viewModel.selected_date())
		{
			options.filter.push({
				field: 'date',
				operand: '<=',
				value: this.viewModel.selected_date()
			});
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				this.viewModel.kpi_bu_targets(result.records);
				this.viewModel.page_count(Math.floor(result.total/result.limit)+1);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}
}

export default {
	name: 'ProjectTargetsMonthly',
	dialog_class: ProjectTargetsMonthly,
	template: template,
	provider: 'ps'
}
