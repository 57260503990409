
import EditKPI from './EditKPI/EditKPI.js';
import EditBUDialog from './EditBU/EditBU.js';
import AddKPIBUDialog from './Add-KPI-BU/Add-KPI-BU.js';
import EditKPIBUDialog from './EditKPIBU/EditKPIBU.js';
import EditProject from './EditProject/edit-project.js';
import AddKpiProject from './Add-KPI-Project/AddKpiProject.js';
import AddBUProject from './Add-BU-Project/Add-BU-Project.js';
import EditProjectTarget from './EditProjectTargets/edit-project-target.js';
import EditProjectDetails from './EditProjectDetails/edit-project-details.js';
import EditProjectObjective from './EditProjectObjective/edit-project-objective.js';
import BaselinePerBU from './BaselinePerBU/BaselinePerBU.js';
import EditUserDialog from './EditUserDialog/edit-user-dialog.js';
import EditRoleDialog from './EditRoleDialog/edit-role-dialog.js';
import EditKPIUser from './EditKPIUser/edit-kpi-user.js';
import EditProjectUser from './EditProjectUser/edit-project-user.js';
import EditBUUser from './EditBUUser/edit-bu-user.js';
import KPIValueDetails from './KPIValueDetails/kpi-value-details.js';
import KPIBUTargetsmonthly from './KPIBUTargetMonthly/KPI-BU-Targets-monthly.js';
import ProjectTargetsMonthly from './ProjectTargetMonthly/project-targets-monthly.js';
import LogFile from './ViewLogFile/ViewLogFile.js';

import SDGInfo from './SDGInfo/SDGInfo.js';
import AddSDGKPIDialog from './Add-SDG-KPI/Add-SDG-KPI.js';
import AddSDGProject from './Add-SDG-Project/Add-SDG-Project.js';
import EditSDGKPI from './EditSDGKPI/EditSDGKPI.js';
import EditSDGProject from './EditSDGProject/EditSDGProject.js';

export default [
	EditKPI,
	EditBUDialog,
	AddKPIBUDialog,
	EditKPIBUDialog,
	EditProject,
	AddKpiProject,
	AddBUProject,
	EditProjectTarget,
	EditProjectDetails,
	EditProjectObjective,
	BaselinePerBU,
	EditUserDialog,
	EditRoleDialog,
	EditKPIUser,
	EditProjectUser,
	EditBUUser,
	KPIValueDetails,
	KPIBUTargetsmonthly,
	ProjectTargetsMonthly,
	LogFile,
	SDGInfo,
	AddSDGKPIDialog,
	AddSDGProject,
	EditSDGKPI,
	EditSDGProject
];
