/**
 * Cache for SDGs
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'SDGs',
	options: {
		table: 'v_sdgs',
		schema: 'sdg',
		sortfield: 'sdg_id',
		filter: []
	}
};