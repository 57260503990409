import template from './KPI-BU-Targets-monthly.html';

class KPIBUTargetsmonthlyVM
{
	constructor (dialog)
	{
		this.dialog = dialog;

		let last_target_tab = localStorage.getItem('last_target_tab') || 'table';
		this.selected_tab = ko.observable(last_target_tab);
		this.kpi_bu_targets = ko.observableArray();
		this.kpi_name = ko.observable();
		this.kpi_business_units = ko.observableArray();
		this.target_id = ko.observable();
		this.bu_name = ko.observable();
		this.target_date = ko.observable();
		this.target_value = ko.observable();
		this.baseline_date = ko.observable();
		this.baseline_value = ko.observable();
		this.reading_uom = ko.observable();
		this.bu_type = ko.observable();

		// Pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(15);
		this.page_count = ko.observable(1);

		// Date Filters
		this.selected_from_date = ko.observable('');
		this.selected_to_date = ko.observable('');
		this.sort_field = ko.observable('date');
		this.sort_order = ko.observable('ASC');

		this.selected_from_date.subscribe((value) => {
			this.dialog.updateData();
		});

		this.selected_to_date.subscribe((value) => {
			this.dialog.updateData();
		});

	}

	sort_column (column)
	{
		if (this.sort_field() === column)
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		else
		{
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.dialog.updateData();
	}

	rag_status_style (rag_status)
	{
		let style;
		switch (rag_status)
		{
			case 'Green':
				style = { backgroundColor: '#87a747', color: 'white' };
				break;
			case 'Amber':
				style = { backgroundColor: '#eba61d', color: 'white' };
				break;
			case 'Red':
				style = { backgroundColor: '#911602', color: 'white' };
				break;
			default:
				style = {};
		}
		return style;
	}

	switch_tabs (data, event)
	{
		let tabname = event.currentTarget.getAttribute('data-tabname');
		this.selected_tab(tabname);

		localStorage.setItem('last_target_tab', tabname);
		
		document.querySelectorAll('.ps-tabs li').forEach(tab => tab.classList.remove('active'));
		event.currentTarget.classList.add('active');
	}

	async btn_close_click ()
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: '', message: 'Are you sure you want to close this dialog?'});
		if (response)
			this.dialog.close(false);
	}

	async init_chart()
	{
		var myChart = echarts.init(document.getElementById('kpi-bu-graph'));

		let chart_options = await Grape.fetches.getJSON('/api/echarts/chart/options',
		{
			chart_name: 'KPIChart',
			data_source_options: {filter: [{f: 'target_id', o: '=', v: this.target_id()}]}
		});
		
		chart_options.legend.formatter = new Function('name', chart_options.legend.formatter);
		chart_options.tooltip.formatter = new Function('params', chart_options.tooltip.formatter);

		myChart.setOption(chart_options);
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.dialog.updateData();
	}
}

class KPIBUTargetsmonthly
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPIBUTargetsmonthlyVM(this);
		this.name = 'KPIBUTargetsmonthly';
		this.viewModel.kpi_name(bindings.data.kpi_name);
		this.viewModel.kpi_business_units(bindings.kpi_business_units);
		this.viewModel.target_id(bindings.data.target_id);
		this.viewModel.bu_name(bindings.data.business_unit_name);
		this.viewModel.target_date(bindings.data.target_date);
		this.viewModel.target_value(bindings.data.nominal_target);
		this.viewModel.baseline_date(bindings.data.baseline_date);
		this.viewModel.baseline_value(bindings.data.baseline);
		this.viewModel.reading_uom(bindings.data.uom);
		this.viewModel.bu_type(bindings.data.business_unit_type);

		this.init();
	}

	async init () 
	{
		document.title = 'View Indicator BU monthly targets';
		let last_target_tab = localStorage.getItem('last_target_tab') || 'table';
		document.querySelector(`.ps-tabs li[data-tabname='${last_target_tab}']`).classList.add('active');

		await this.updateData();

		await this.viewModel.init_chart();
	}

	async updateData ()
	{
		//GET Baseline Target per Business Unit
		let options = {
			table: 'v_kpi_bu_targets_monthly_rag',
			schema: 'kpi',
			offset: 0,
			filter_join: 'AND',
			limit: 100,
			join: 'OR',
			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field(),
			filter: [{
				field: 'target_id',
				operand: '=',
				value: this.viewModel.target_id()
			}]
		}

		//LOGIC: Pagination
		if(this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		//Date filters
		if (this.viewModel.selected_from_date())
		{
			options.filter.push({
				field: 'date',
				operand: '>=',
				value: this.viewModel.selected_from_date()
			});
		}

		if (this.viewModel.selected_to_date())
		{
			options.filter.push({
				field: 'date',
				operand: '<=',
				value: this.viewModel.selected_to_date()
			});
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				this.viewModel.kpi_bu_targets(result.records);
				this.viewModel.page_count(Math.floor(result.total/result.limit)+1);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}
}

export default {
	name: 'KPIBUTargetsmonthly',
	dialog_class: KPIBUTargetsmonthly,
	template: template,
	provider: 'ps'
}
