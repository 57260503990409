import template from './sdg-measures.html';

class SDGMeasures
{
	constructor (page)
	{
		this.page = page;
		this.is_loading = ko.observable(false);
	}
}

class SDGMeasuresPage
{

	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new SDGMeasures(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'SDG Measures';
	}

	async updateData ()
	{
	}
}

export default {
	route: '[/]sdg/measures',
	page_class: SDGMeasuresPage,
	template: template
}
