/**
 * Cache for Projects in editProjectUser
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'Projects',
	options: {
		tablename: 'dv_projects',
		schema: 'kpi',
		filter: []
	}
};
