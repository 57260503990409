import template from './SDGInfo.html';

class SDGInfoDialogvm
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.dialog_title = ko.observable();
	}

	async load ()
	{
		this.dialog.updateData();
	}

	async close_click()
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: '', message: 'Are you sure you want to close this dialog?'});
		if (response)
			this.dialog.close(false);
	}
}

class SDGInfoDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new SDGInfoDialogvm(this);
		this.name = 'SDGInfo';
		this.viewModel.dialog_title(bindings.sdg_info.sdg_name);

		this.init();
	}

	async init ()
	{
		document.title = 'SDG Linked Indicators and Projects';
		this.viewModel.load();
	}

	async updateData ()
	{
	}
}

export default {
	name: 'SDGInfo',
	dialog_class: SDGInfoDialog,
	template: template,
	provider: 'ps'
}
