import template from './view-approve-project.html';

ko.bindingHandlers.formattedDate = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());
		var formattedDate = new Date(value).toLocaleDateString();
		element.innerText = formattedDate;
	},
};

ko.bindingHandlers.formattedDateRange = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());

		if (typeof value === 'string') {
			var dateRangeMatch = value.match(/\[(.*?),(.*?)\)/);

			if (dateRangeMatch) {
				var startDate = new Date(dateRangeMatch[1]);
				var endDate = new Date(dateRangeMatch[2]);

				var formattedStartDate = startDate.toLocaleDateString();
				var formattedEndDate = endDate.toLocaleDateString();

				element.innerText = `(${formattedStartDate} - ${formattedEndDate})`;
				return;
			}
		}
		element.innerText = value;
	},
};

class ProjectViewDataVM
{
	constructor (page)
	{
		this.page = page;
		this.project_id = ko.observable();
		this.project_nr = ko.observable();
		this.project_name = ko.observable();
		this.project_description = ko.observable();
		this.search_string = ko.observable('');
		this.approve_kpi_projects = ko.observableArray([]);
		this.updated_date = ko.observable();
		this.projects = ko.observableArray();
		this.selected_project = ko.observable();

		// pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(5);
		this.page_count = ko.observable(1);


		this.search_string.subscribe((value) => {
			this.page.updateData();
		});

		this.selected_project.subscribe((value) => {
			this.page.updateData();
		});
	}

	format_reporting_period(period) {
		let dates = period.slice(1, -1).split(',');
		return dates[0].replace(/-/g, '/') + ' - ' + dates[1].replace(/-/g, '/');
	}

	get_background_color (status)
	{
		let background_color_class = '';
		switch (status)
		{
			case 'Green':
				background_color_class = '#87a747';
				break;
			case 'Amber':
				background_color_class = '#eba61d';
				break;
			case 'Red':
				background_color_class = '#911602';
				break;
		}
		return background_color_class;
	}

	btn_clear_project_value_filter_click ()
	{
		this.search_string('');
		this.selected_project('');
		this.project_id('');
		this.project_nr('');
		this.project_description('');
		this.project_name('');
		this.page.updateData();
	}

	btn_select_project_click (project)
	{
		this.search_string('');
		this.selected_project(project);
		this.project_name(project.name);
		this.project_description(project.description)
		this.project_id(project.project_id);
		this.project_nr(project.project_nr);
	}

	page_click (page_number)
	{
		if (page_number < 1)
			page_number = 1;
		else if (page_number > this.page_count())
			page_number = this.page_count();
		this.current_page_number(page_number);
		this.page.updateData();
	}


	async btn_confirm_project_value_click (data)
	{
		let options = {
			project_status_id: data.project_status_id,
			confirmed: true,
			confirmed_date: moment().format('YYYY-MM-DD')
		}

		let result = await Grape.fetches.postJSON('/api/project/project-value-confirm', options);
		if (result.status != 'ERROR')
		{
			Grape.alerts.alert({type: 'success', title: 'Success', message: 'Project confirmed'});
			this.page.updateData();
		}
		else
		{
			Grape.alerts.alert({type: 'warning', title: 'Could not save', message: 'Something went wrong...'});
			throw new Error(result.message || result.code);
		}
	}

	async btn_delete_project_value_click (data)
	{
		let result = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Are you sure you want to delete this Project Value ?',
			title: 'Warning',
		});

		if (result)
		{
			let options = {
				project_status_id: data.project_status_id
			}

			let response = await fetch('/api/project/project-value', {
				method: 'DELETE',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(options)
			});

			if (response.ok)
				console.log('Deleted successfully')
			else
				throw new Error(response.message || response.code || 'Unknown Error');
			this.page.updateData();
		}
	}
}

class ProjectViewData
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProjectViewDataVM(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Approve Projects';
	}

	async updateData ()
	{
		let options= {
			table: 'v_project_values',
			schema: 'kpi',
			limit: this.viewModel.current_page_size(),
			offset: (this.viewModel.current_page_number() - 1) * this.viewModel.current_page_size(),
			filter_join: 'AND',
			join: 'OR',
			sortorder: 'DESC',
			filter: [{
				field: 'confirmed',
				operand: '=',
				value: false
			}]
		}

		if (this.viewModel.selected_project())
		{
			options.filter.push({
				field: 'project_id',
				operand: '=',
				value: this.viewModel.selected_project().project_id
			});
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				let formatted_records = result.records.map(record => {
					if (record.reporting_period)
						record.reporting_period = this.viewModel.format_reporting_period(record.reporting_period);
				
					return record;
				});

				this.viewModel.approve_kpi_projects(formatted_records);
				this.viewModel.page_count(Math.ceil(result.total / this.viewModel.current_page_size()));
			} else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}

		let project_options = {
			table: 'dv_projects',
			schema: 'kpi',
			fields: ['name', 'project_id','project_nr', 'description'],
			filter: []
		}

		if (this.viewModel.search_string() && this.viewModel.search_string().trim() !== '')
		{
			project_options.filter.push({
				field: 'name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_string()}%`
			});

			let result = await Grape.fetches.getJSON('/api/record', project_options);
			if (result.status !== 'ERROR')
				this.viewModel.projects(result.records);
			else
				console.error(result.message || result.code);
		}
		else
			this.viewModel.projects([]);
	}
}
export default {
	route: '[/]projects/approve-project',
	page_class: ProjectViewData,
	template: template
}
