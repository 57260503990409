/**
 * Cache for ResourceRoleLookup
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'ResourceRoleLookup',
	options: {
		table: 'v_lookup_value',
		schema: 'grape',
		sortfield: 'lookup_name',
		filter: [{field: 'lookup_name', operator: '=', value: 'ResourceRole'}]
	}
};

