import template from './dashboard-powerBI-link.html';

class DashboardPBIPageViewModel
{
	constructor (page)
	{
		this.page = page;
		this.bi_link = ko.observable();
		this.title = ko.observable();
	}

	btn_open_new_tab_click ()
	{
		window.open(this.bi_link(), '_blank');
	}
}

class DashboardPBIPage
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new DashboardPBIPageViewModel(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Dashboard - Power BI';

		// Get Power BI link
		if (Grape.config.public_settings.power_bi_link)
		{
			let url_result = Grape.config.public_settings.power_bi_link;
			this.viewModel.bi_link(url_result);
		}

		// Get Power BI title
		if (Grape.config.public_settings.iframe_title)
		{
			let title = Grape.config.public_settings.iframe_title;
			this.viewModel.title(title);
		}
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]dashboard/dashboard-powerBI',
	page_id: 'dashboard-powerBI',
	page_class: DashboardPBIPage,
	template: template
}
