/**
 * KPI Capture Page
 */
import template from './kpi-capture.html';

class KPICaptureViewModel
{
	constructor (page)
	{
		document.title = 'Indicator Capture';
		this.page = page;
		this.current_page_title = ko.observable('');
		this.current_route = ko.observable();

		// permissions
		this.view_capture_tab = ko.observable(false);
		this.view_history_tab = ko.observable(false);
		this.view_approve_draft_tab = ko.observable(false);

		this.selected_page = ko.computed(() => {
			return window.AppState.selected_page();
		});
		
		this.tabs = ko.observableArray([{
			route: '[/]kpi/capture-data',
			title: 'Capture Data',
			heading_text: 'Capture Data',
			icon: 'fa-thin fa-solid fa-camera-polaroid',
			visible: this.view_capture_tab()
		}, {
			route: '[/]kpi/previous-data',
			title: 'Previous Data',
			heading_text: 'History',
			icon: 'fa-thin fa-diagram-previous',
			visible: this.view_history_tab()
		}, {
			route: '[/]kpi/view-approve-data',
			title: 'View & Approve / Drafts',
			heading_text: 'View & Approve',
			icon: 'fa-thin fa-check-to-slot',
			visible: this.view_approve_draft_tab()
		}]);
		
		this.current_route.subscribe((newPageId) => {
			let matchingTab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === newPageId;
			});
			this.current_page_title(matchingTab ? matchingTab.heading_text : '');
		}, this);

		// Re-evaluate the capture tab visibility
		this.view_capture_tab.subscribe(val => {
			let capture_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === '[/]kpi/capture-data';
			});

			if (capture_tab)
			{
				capture_tab.visible = val;
				this.tabs.valueHasMutated();
			}
		});

		// Re-evaluate the history tab visibility
		this.view_history_tab.subscribe(val => {
			let history_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === '[/]kpi/previous-data';
			});

			if (history_tab)
			{
				history_tab.visible = val;
				this.tabs.valueHasMutated();
			}
		});

		// Re-evaluate the approve and draft tab visibility
		this.view_approve_draft_tab.subscribe(val => {
			let approve_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === '[/]kpi/view-approve-data';
			});

			if (approve_tab)
			{
				approve_tab.visible = val;
				this.tabs.valueHasMutated();
			}
		});

		// Handle the visible tabs (used in html)
		this.visible_tabs = ko.computed(() => {
			return ko.utils.arrayFilter(this.tabs(), (tab) => {	
				return tab.visible === true;
			});
		});
	}
	
	async load_tab (route)
	{
		window.localStorage.setItem('last_kpicapturesidebar_page', route);
		const element = this.page.element.querySelector('#page_content');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id '${route}' not found`);
		
		await Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class KPICapturePage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPICaptureViewModel(this);
		this.onload = true;
	}

	async init ()
	{
		document.title = 'Indicator Capture';

		let view_capture_permisson = await window.user_permission(['admin', 'Capture ESG Indicator Page']);
		if (view_capture_permisson)
			this.viewModel.view_capture_tab(true);

		let view_history_permission = await window.user_permission(['admin', 'ESG Indicator Value History Page']);
		if (view_history_permission)
			this.viewModel.view_history_tab(true);

		let view_approve_permission = await window.user_permission(['admin', 'Capture ESG Indicator Page', 'Approve Indicator Captured Values']);
		if (view_approve_permission)
			this.viewModel.view_approve_draft_tab(true);

		if (this.bindings.page_id)
			this.viewModel.load_tab(this.bindings.page_id);
		else if (window.localStorage.getItem('last_kpicapturesidebar_page'))
			this.viewModel.load_tab(window.localStorage.getItem('last_kpicapturesidebar_page'));
		else
			this.viewModel.load_tab('[/]kpi/capture-data');
	}
}

export default {
	route: '[/]kpi/kpi-capture',
	page_class: KPICapturePage,
	template: template
}
