import template from './kpi-list.html';

class KPIListViewModel
{
	constructor (page)
	{
		document.title = 'Indicator Overview';
		this.page = page;

		// permissions
		this.is_visible = ko.observable(false);

		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(13);
		this.page_count = ko.observable(1);
		this.search_string = ko.observable('');
		this.sort_field = ko.observable('kpi_nr');
		this.sort_order = ko.observable('ASC');

		this.available_uoms = ko.observableArray();
		this.reporting_frequency_options = ko.observableArray();
		this.kpis = ko.observableArray();
		this.kpi_grouped = ko.observable({});
		this.kpi_group_names = ko.observable();
		this.kpi_tabs = ko.observableArray();
		this.kpi_flags_options = ko.observableArray();
		this.selected_kpi_tab = ko.observable();

		this.search_string.subscribe((value) => {
			this.current_page_number(1);
			this.page.updateData();
		});

		this.current_page_size.subscribe((value) => {
			this.page.onload = true;
		});
		this.load_kpi_tabs();
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_data_warning_nav_click()
	{
		window.Grape.navigate('[/]kpi/kpi-data-warnings');
	}

	async load_kpi_tabs()
	{
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'kpi',
				table: 'v_kpi_groups',
				filter: [],
			});
		
			if (result.status === 'OK')
			{
				let tabs = result.records.map((record) => {
					let icon_class = this.get_icons(record.name);
					return {
						kpi_group_id: ko.observable(record.kpi_group_id),
						name: ko.observable(record.name),
						icon: ko.observable(icon_class),
					};
				});
				
				this.kpi_tabs(tabs);
				this.selected_kpi_tab(this.kpi_tabs()[0]);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	get_icons(tab)
	{
		let icons = {
			'Environmental': 'fa-regular fa-seedling',
			'Governance': 'fa-light fa-scale-balanced ',
			'Social': 'fa-light fa-users',
			// If other groups or tabs are added, add them here with their appropriate icons. 
		};
		// Default icon
		return icons[tab] || 'fa-solid fa-chart-bar';
	}

	async btn_delete_kpi_click (data)
	{
		let confirmed = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Please note that deleting an Indicator will remove all relevant data of this Indicator including captured values, baseline targets etc.',
			title: 'Warning'
		});

		if (confirmed)
		{
			let result = await Grape.alerts.confirm({
				type: 'danger',
				message: 'Are you sure you want to delete this Indicator? (this cannot be undone)',
				title: 'Warning'
			});

			if (result)
			{
				let kpi_id = data.kpi_id;
				try {
					let response = await Grape.fetches.fetch(`/api/kpi/kpi?kpi_id=${kpi_id}`, {
						method: 'DELETE',
					});

					if (response.status === 200)
						this.page.updateData();
					else
						console.error('Failed to delete Indicator');
				} catch (error) {
					console.error('Network error:', error);
				}
			}
		}
	}
	
	btn_select_tab_click (tab)
	{
		this.search_string('');
		this.current_page_number(1);
		this.selected_kpi_tab(tab);
		this.page.updateData();
	}

	async btn_edit_kpi_click (data)
	{
		await Grape.dialog.open('EditKPI', {kpi_data: data, flag_options: this.kpi_flags_options(), all_kpis: this.kpis(), available_uoms: this.available_uoms(), time_period_options: this.reporting_frequency_options()});
		this.page.updateData();
	}
	
	async btn_add_kpi_click ()
	{
		await Grape.dialog.open('EditKPI', { kpi_id: null, flag_options: this.kpi_flags_options(), all_kpis: this.kpis(), available_uoms: this.available_uoms(), time_period_options: this.reporting_frequency_options()});
		this.page.updateData();
	}

	// Sort fields
	sort_column (column)
	{
		if (this.sort_field() === column)
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		else
		{
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.page.updateData();
	}

	set_default_group ()
	{
		let defaul_group_name = 'Environmental';
		let default_group = this.kpi_group_names().find((group) => group === defaul_group_name);
		this.selected_kpi_tab(default_group);
		this.page.updateData();
	}
}

class KPIListPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPIListViewModel(this);
		this.onload = true;
		this.page = this;
	}

	async updateData ()
	{
		let permission = await window.user_permission(['Setup ESG Indicator', 'admin']);
		if (permission)
			this.viewModel.is_visible(permission);

		// Get list of kpi's
		let options = {
			table: 'dv_kpis',
			schema: 'kpi',
			offset: 0,
			limit: 50000,
			filter_join: 'AND',
			join: 'OR',
			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field(),
			filter: [],
		};

		let response = await Grape.fetches.getJSON('/api/record', options);
		if (response.status != 'ERROR')
			this.viewModel.kpis(response.records);

		if (this.viewModel.selected_kpi_tab())
		{
			options.filter.push({
				field: 'kpi_group_id',
				operand: '=',
				value: this.viewModel.selected_kpi_tab().kpi_group_id(),
			});
		}

		// LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '')
		{
			options.filter.push({
				field: 'name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_string()}%`
			})
		}

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			let kpi_grouped = {};
			let names = [];
			if (result.status == 'OK')
			{
				for (let r of result.records)
				{
					if (!kpi_grouped.hasOwnProperty(r.kpi_group))
					{
						kpi_grouped[r.kpi_group] = [];
						names.push(r.kpi_group);
					}
					kpi_grouped[r.kpi_group].push(r);
					this.viewModel.page_count(Math.floor(result.total/result.limit)+1);
				}

				this.viewModel.kpi_grouped(kpi_grouped);
				this.viewModel.kpi_group_names(names);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		// Load UOMs
		let uom = {
			table: 'v_units',
			schema: 'uom',
			filter: []
		}

		let uom_data = await Grape.fetches.getJSON('/api/record', uom);

		if (uom_data.status != 'ERROR')
			this.viewModel.available_uoms(uom_data.records);
		else
			throw new Error (uom_data.message || uom_data.code)

		let kpi_flags = {
			table: 'v_lookup_value',
			schema: 'grape',
			sortfield: 'idx',
			filter: [{
				field: 'lookup_name',
				operand: '=',
				value: 'KPIFlag'
			}]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', kpi_flags);
			if (result.status != 'ERROR')
				this.viewModel.kpi_flags_options(result.records);
			else
				throw new Error(result.message || result.code)
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		}

		try {
			let result = await Grape.cache.fetch('TimePeriodTypeLookup');
			this.viewModel.reporting_frequency_options(result);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	route: '[/]kpi/kpi-list',
	page_class: KPIListPage,
	template: template
}
