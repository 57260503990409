/**
 * Cache for Grape v_users
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'Users',
	options: {
		tablename: 'v_users',
		schema: 'grape',
		filter: []
	}
};
