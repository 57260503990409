import template from './features.html';

class FeaturesSubPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.features = ko.observableArray([]);
		this.project_features = ko.observableArray([]);
		this.bu_features = ko.observableArray([]);
		this.kpi_features = ko.observableArray([]);
		this.sdg_features = ko.observableArray([]);

		let last_feature_tab = localStorage.getItem('last_feature_tab') || 'kpi';
		this.selected_tab = ko.observable(last_feature_tab);
	}

	switch_tabs (data, event)
	{
		let tabname = event.currentTarget.getAttribute('data-tabname');
		this.selected_tab(tabname);

		localStorage.setItem('last_feature_tab', tabname);
		
		document.querySelectorAll('.ps-tabs li').forEach(tab => tab.classList.remove('active'));
		event.currentTarget.classList.add('active');
	}
}

class FeaturesSubPagePage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new FeaturesSubPageVM(this);
		this.bindings = bindings;
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Admin - Features';
		let last_feature_tab = localStorage.getItem('last_feature_tab') || 'kpi';
		document.querySelector(`.ps-tabs li[data-tabname='${last_feature_tab}']`).classList.add('active');
	}

	async updateData ()
	{
		const self = this;
		try {
			Grape.cache.fetch('Features', function(data) {
				data.forEach((record) => {
					switch (record.category) {
						case 'K':
							self.viewModel.kpi_features.push(record);
							break;
						case 'B':
							self.viewModel.bu_features.push(record);
							break;
						case 'P':
							self.viewModel.project_features.push(record);
							break;
					}
				});
			});
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		try {
			Grape.cache.fetch('SDGFeatures', function(data) {
				self.viewModel.sdg_features(data);
			});
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]users-setup/features',
	page_id: '/users-setup/features',
	page_class: FeaturesSubPagePage,
	template: template
}
