
import template from './projects-overview.html';

ko.bindingHandlers.formattedDate = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());
		var formattedDate = new Date(value).toLocaleDateString();
		element.innerText = formattedDate;
	},
};

class ProjectOverviewVM
{
	constructor (page)
	{
		document.title = 'ProjectsOverview';
		this.loaded = ko.observable(false);
		this.page = page;
		this.projects = ko.observableArray([]);
		this.start_date = ko.observable();
		this.end_date = ko.observable();

		// permissions
		this.view_projects = ko.observable(false);
		this.add_project = ko.observable(false);

		// pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(15);
		this.page_count = ko.observable(1);
		//search
		this.search_string = ko.observable('');

		//sort
		this.sort_field = ko.observable('name');
		this.sort_order = ko.observable('DESC');

		//filtering
		this.project_flags = ko.observableArray([]);
		this.selected_project_flags = ko.observableArray([]);

		this.selected_project_flags.subscribe((value) => {
			this.page.updateData();
		});

		this.search_string.subscribe((value) => {
			this.page.updateData();
		});

		this.start_date.subscribe((value) => {
			this.page.updateData();
		});

		this.end_date.subscribe((value) => {
			this.page.updateData();
		});
	}

	// Sort fields
	sort_column (column)
	{
		if (this.sort_field() === column)
		{
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		} else {
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.page.updateData();
	}

	async btn_delete_project_click (project)
	{
		let confirmed = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Please note that deleting a Project will remove all relevant data of this Project including captured values, baseline targets etc.',
			title: 'Warning'
		});

		if (confirmed)
		{
			let result = await Grape.alerts.confirm({
				type: 'danger',
				message: 'Are you sure you want to delete this Project? (this cannot be undone)',
				title: 'Warning'
			});

			if (result)
			{
				try {
					let response = await Grape.fetches.fetch(`/api/kpi/project?project_id=${project.project_id}`, {
						method: 'DELETE',
					});

					if (response.status === 200)
						this.page.updateData();
					else
						console.error('Failed to delete project');
				} catch (error) {
					console.error('Network error:', error);
				}
			}
		}
	}

	btn_add_project_click ()
	{
		Grape.dialog.open('EditProject', {projectData: {}, title: 'New Project'})
		.then(() => {
			this.page.updateData();
		})
		.catch((error) => {
			console.error('Error adding Project:', error);
		});
	}

	btn_edit_project_click (project)
	{
		Grape.dialog.open('EditProject', { projectData: project, title: 'Edit Project'})
		.then(() => {
			this.page.updateData();
		})
		.catch((error) => {
			console.error('Error editing the project:', error);
		});
	}

	btn_clear_filters_click() {
		this.selected_project_flags([]);
		this.start_date(null);
		this.end_date(null);
		this.search_string('');

		this.page.updateData();
		}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}
}

class ProjectOverviewPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProjectOverviewVM(this);
		this.timer = null;
	}

	async init()
	{
		document.title = 'Project Overview';

		// permissions
		let permission_add = await window.user_permission(['admin', 'Setup Project']);
		if (permission_add)
			this.viewModel.add_project(permission_add);
		let permission_view = await window.user_permission(['admin', 'Projects', 'Edit Project details and objectives', 'Setup Project',' Setup associated BUs and ESG Indicators', 'Project related Targets', 'Project progress status and history', 'Update Project progress', 'Approve Update on Project progress', 'View Project Users Page', 'Add Project associated Targets']);
		if (permission_view)
			this.viewModel.view_projects(permission_view)

		try {
			// get flags
			let ProjectFlagLookup = await Grape.cache.fetch('ProjectFlagLookup');
			let flag_names = [];
					for (let i = 0; i < ProjectFlagLookup.length; i++)
						flag_names.push(ProjectFlagLookup[i].name);
			this.viewModel.project_flags(flag_names);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while loading flag names:', error);
		}
	}

	async updateData()
	{
		// get projects
		let options = {
			table: 'dv_projects',
			schema: 'kpi',
			offset: (this.viewModel.current_page_number() - 1) * this.viewModel.current_page_size(),
			limit: this.viewModel.current_page_size(),
			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field(),
			filter_join: 'AND',
			join: 'OR',
			filter: []
		}

		//LOGIC: filter
		if (this.viewModel.selected_project_flags().length > 0)
		{
			options.filter.push({
				field: 'flag_names',
				operand: '@>',
				value: this.viewModel.selected_project_flags()
			});
		}

		if (this.viewModel.start_date() && this.viewModel.end_date()) {
			options.filter.push({
				field: 'start_date',
				operand: '>=',
				value: this.viewModel.start_date()
			}, {
				field: 'end_date',
				operand: '<=',
				value: this.viewModel.end_date()
			});
		} else if (this.viewModel.start_date()) {
			options.filter.push({
				field: 'start_date',
				operand: '>=',
				value: this.viewModel.start_date()
			});
		} else if (this.viewModel.end_date()) {
			options.filter.push({
				field: 'end_date',
				operand: '<=',
				value: this.viewModel.end_date()
			});
		}

		//LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '')
		{
			options.filter.push({
				field: 'name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_string()}%`
			});
		}

		//LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status === 'OK')
			{
				this.viewModel.projects(result.records);
				this.viewModel.page_count(Math.floor(result.total/result.limit)+1);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('Error fetching project data:', error);
		}
	}
}

export default {
	route: '[/]projects/projects-overview',
	page_class: ProjectOverviewPage,
	template: template
}
