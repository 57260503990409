import template from './EditKPIBU.html';

class EditKPIBUDialogvm
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.note = ko.observable();
		this.kpi_id = ko.observable();
		this.business_unit_id = ko.observable();
		this.kpi_business_unit_id = ko.observable();
		this.mode = ko.observable();
		this.project_business_id = ko.observable();
		this.project_kpi_id = ko.observable();
		this.project_id = ko.observable();
	}

	async save_click ()
	{
		let result = [];
		if (this.mode() === 'edit_project_bu' || this.mode() === 'edit_project_kpi')
		{
			if (this.mode() === 'edit_project_bu')
			{
				result = {
					project_business_id: this.project_business_id(),
					business_unit_id: this.business_unit_id(),
					project_id: this.project_id(),
					note: this.note()
				}

				let response = fetch('/api/kpi/business-unit-project-link', {
					method: 'POST',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(result)
				});
				if (response.status != 'ERROR')
					console.log('note changed successfully. ');
			}
			else if (this.mode() === 'edit_project_kpi')
			{
				result = {
					project_kpi_id: this.project_kpi_id(),
					kpi_id: this.kpi_id(),
					project_id: this.project_id(),
					note: this.note()
				}

				let response = fetch('/api/kpi/project-link', {
					method: 'POST',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(result)
				});
				if (response.status != 'ERROR')
					console.log('note changed successfully. ');
			}
		} 
		else
		{
			result = {
				kpi_business_unit_id: parseInt(this.kpi_business_unit_id()),
				business_unit_id: parseInt(this.business_unit_id()),
				kpi_id: parseInt(this.kpi_id()),
				note: this.note()
			}

			let response = await Grape.fetches.postJSON('/api/kpi/kpi-business-unit', result);
			if (response.status == 'OK')
				console.log('Saved successfully');
			else 
				throw new Error(response.message || response.code || 'Unknown Error');
		}

		this.dialog.close();
	}

	async close_click ()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}

class EditKPIBUDialog
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new EditKPIBUDialogvm(this);
		this.name = 'EditKPIBU';
		this.viewModel.kpi_id(bindings.kpi_id);
		this.viewModel.business_unit_id(bindings.business_unit_id);
		this.viewModel.note(bindings.note);
		this.viewModel.kpi_business_unit_id(bindings.kpi_business_unit_id);
		this.viewModel.mode(bindings.mode);

		if (this.viewModel.mode() === 'edit_project_bu')
		{
			this.viewModel.project_business_id(bindings.project_business_id);
			this.viewModel.business_unit_id(bindings.business_unit_id);
			this.viewModel.project_id(bindings.project_id);
		}
		else if (this.viewModel.mode() === 'edit_project_kpi')
		{
			this.viewModel.project_kpi_id(bindings.project_kpi_id);
			this.viewModel.kpi_id(bindings.kpi_id);
			this.viewModel.project_id(bindings.project_id);
		}

		this.init();
	}

	async init ()
	{
		document.title = 'Edit KPI BU Dialog';
	}

	async updateData ()
	{
	}
}

export default {
	name: 'EditKPIBU',
	dialog_class: EditKPIBUDialog,
	template: template,
	provider: 'ps'
}
