import template from './kpi-value-details.html';

class KPIValueDetailsvm
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.kpi_name = ko.observable();
		this.bu_name = ko.observable();
		this.captured_date = ko.observable();
		this.confirmed = ko.observable();
		this.user_confirmed = ko.observable();
		this.date_confirmed = ko.observable();
		this.status = ko.observable();
		this.value = ko.observable();
		this.project = ko.observable();
		this.reading_date = ko.observable()
		this.start_date = ko.observable();
		this.end_date = ko.observable();
		this.uom = ko.observable();
		this.user_captured = ko.observable();
		this.note = ko.observable();
	}

	async btn_close_click ()
	{
		this.dialog.close(false)
	}
}

class KPIValueDetails
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new KPIValueDetailsvm(this);
		this.name = 'KPIValueDetails';
		this.viewModel.kpi_name(bindings.data.kpi);
		this.viewModel.bu_name(bindings.data.business_unit);
		this.viewModel.captured_date(bindings.data.captured_date);
		this.viewModel.confirmed(bindings.data.confirmed);
		this.viewModel.user_confirmed(bindings.data.user_confirmed);
		this.viewModel.date_confirmed(bindings.data.confirmed_date);
		this.viewModel.status(bindings.data.kpi_value_status);
		this.viewModel.value(bindings.data.reading_value);
		this.viewModel.project(bindings.data.project);
		this.viewModel.reading_date(bindings.data.reading_date);
		this.viewModel.start_date(bindings.data.reporting_period_start);
		this.viewModel.end_date(bindings.data.reporting_period_end);
		this.viewModel.uom(bindings.data.reading_uom);
		this.viewModel.user_captured(bindings.data.user_captured);
		this.viewModel.note(bindings.data.reading_note);
		
		this.init();
	}

	async init ()
	{
		document.title = 'Add Baseline Target per Business Unit Dialog';
	}
}

export default {
	name: 'KPIValueDetails',
	dialog_class: KPIValueDetails,
	template: template,
	provider: 'ps'
}
