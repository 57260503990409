/* Application entry-point */
/* Import CSS */
import './css/Roboto.css';
import './css/catalytix.css';

/* Import local code */
import pages from './pages/index.js';
import dialogs from './dialogs/index.js';
import components from './components/index.js';
import appcaches from './caches/index.js';

/* Plugin */
import Plugin from './lib/Plugin.js';

/* Themes */
import './themes/theme-catalytix-light.css';
import './themes/theme-catalytix-dark.css';

window.AppState = {
	selected_page: ko.observable(localStorage.getItem('selected_navbar_page') || ''),
	current_user_roles: ko.observable()
};

/* Define default routes */
const default_routes_container = {
	guest: '/login',
	all: '/dashboard/dashboard'
};

Grape.themes.registerThemes([
	{ name: 'Catalytix', className: 'catalytix' }
]);

Grape.themes.setThemeDefaults('catalytix', 'light');

// Define the custom binding for thousands and two decimals
ko.bindingHandlers.format_number = {
	update: (element, valueAccessor) => {
		let value = ko.unwrap(valueAccessor());
		let formatted_value = "";

		// Check if the value is a number
		if (!isNaN(parseFloat(value)) && isFinite(value)) {
			// Format the number with thousands separator and two decimal places
			formatted_value = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);

			// Replace commas with spaces for thousands separator
			formatted_value = formatted_value.replace(/,/g, ' ');
		}

		// Apply the formatted value to the element
		element.innerText = formatted_value;
	}
};

Grape.util = Grape.utils;

Grape.utils.register_caches(appcaches);
Grape.utils.register_components(components);
Grape.utils.register_pages(pages);
Grape.utils.register_dialogs(dialogs);

document.addEventListener('DOMContentLoaded', () => {
	/* Setup app */
	Grape.config.default_routes = default_routes_container;
	Grape.plugins.register(Plugin);

	// Function to set localStorage item if not already set
	function check_ls_exists (key, value)
	{
		if (window.localStorage.getItem(key) === null)
			window.localStorage.setItem(key, value);
	}

	/* Set localStorage for all the sidebars if not already set */
	check_ls_exists('last_kpisetupsidebar_page', '[/]kpi/bu-related');
	check_ls_exists('last_kpicapturesidebar_page', '[/]kpi/capture-data');
	check_ls_exists('last_busidebar_page', '[/]bu/details');
	check_ls_exists('last_details_projectsidebar_page', '[/]projects/projectdetail');
	check_ls_exists('last_capture_projectsidebar_page', '[/]projects/capture-project');
	check_ls_exists('last_users_page', '[/]users-setup/users');
	check_ls_exists('last_visited_project_id', '1');
	check_ls_exists('last_visited_kpi_id', '1');
	check_ls_exists('last_visited_bu_id', '1');

	/* Get current user's role */
	window.user_permission = async (features = []) => {
		if (Grape.currentSession != null)
		{
			let username = Grape.currentSession.user.username;

			let options = {
				table: 'v_user_permissions',
				schema: 'kpi',
				filter: [{
					field: 'username',
					operand: '=',
					value: username
				}]
			};

			let result = await Grape.fetches.getJSON('api/record/', options);

			if (result.status !== 'ERROR' && result.records && result.records.length > 0)
			{
				let record = result.records[0];
				let user_features = record.all_features ? record.all_features.split(', ') : [];
	
				let grape_roles = Grape.currentSession.user.user_roles || [];
				user_features = user_features.concat(grape_roles);
	
				let all_features = [...new Set(user_features)];
	
				localStorage.setItem('current_user_roles', JSON.stringify({ features: all_features }));
	
				if (all_features.includes('admin')) return true;
	
				if (!all_features.length) {
					console.log('No user data found');
				}
	
				let has_feature_permission = features.some(feature => all_features.includes(feature));
				return has_feature_permission;
			}
		}
	}

	/* Start app */
	async function start()
	{
		localStorage.removeItem('current_user_roles');
		await Grape.init();
		user_permission();
	}

	start();
});
