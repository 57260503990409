import template from './user-page.html';

import ClassicEditor from '../../lib/3rdparty/ckeditor/ckeditor.js';

class UserPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;

		this.edit_mode = ko.observable(false);
		this.is_changed = ko.observable(false);
	
		this.path = ko.observable('');
		this.page_content = ko.observable('');
		this.page_title = ko.observable('');
	}

	/**
	 * Set and load path
	 */
	async load(path)
	{
		this.path(path);
		const response = await fetch(path);
		const data = await response.text();
		this.page_content(data);
		this.loaded(true);
	}

	btnEdit_click()
	{
		this.loaded(false);
		this.edit_mode(true);
		this.create_ckeditor();
		this.loaded(true);
	}

	async btnSave_click()
	{
		this.loaded(false);
		let data = this.editor.getData();
		
		await fetch(this.path(), {
			method: 'PUT',
			body: data
		});

		this.edit_mode(false);
		this.destroyCKEditor();
		document.querySelector('#edit-ckeditor').innerHTML = '';
		await this.load(this.path());
	}

	btnCancel_click()
	{
		this.loaded(false);
		this.edit_mode(false);
		this.destroyCKEditor();
		document.querySelector('#edit-ckeditor').innerHTML = '';
		this.loaded(true);
		this.edit_mode(false);
	}

	/**
	 * Create CKEditor instance in div#edit-ckeditor
	 */
	async create_ckeditor () {
		if (this.editor)
			return;
		const ckeditor_config = {
			toolbar: [
					'heading',
				'|',
					'bold',
					'italic',
					'fontColor',
					'highlight',
					'removeFormat',
				'|',
					'Link',
					'bulletedList',
					'numberedList',
				'|',
					'imageUpload',
					'blockQuote',
					'insertTable',
					'todoList',
				'|',
					'codeBlock',
					'code',
				'|',
					'sourceEditing',
					'undo',
					'redo'
			],
			image: {
				styles: ['alignLeft', 'alignCenter', 'alignRight'],
				toolbar: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight', '|', 'imageResize']
			},
			table: {
				contentToolbar: [ 'tableRow', 'tableColumn', 'mergeTableCells' ]
			}
		};
		try {
			this.editor = await ClassicEditor.create(
				document.querySelector('#edit-ckeditor'),
				ckeditor_config
			);

			console.debug('Possible menu item names:', Array.from( this.editor.ui.componentFactory.names() ));

			if (this.page_content())
			{
				this.editor.setData(this.page_content());
				this.is_changed(false);
			}

			this.editor.model.document.on('change:data',
				() => { this.is_changed(true); }
			);
		} catch (err) {
			console.error(err);

			Grape.alert({
				type: 'error',
				title: 'Error',
				message: err.message
			});
		}
	}

	async destroyCKEditor()
	{
		await this.editor.destroy();
		this.editor = null;
	}
}

class UserPagePage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new UserPageVM(this);
	}

	async init ()
	{
		document.title = 'Catalytix Content';

		if (this.bindings.path)
			this.viewModel.load(this.bindings.path);
	}

	async updateData ()
	{
	}

	teardown ()
	{
	}
}

export default {
	route: '[/]user-page',
	page_class: UserPagePage,
	template: template
}
