import template from './dashboard.html';

class DashboardVM
{
	constructor (page) 
	{
		this.page = page;
		this.dashboard_sidebar_visibility = ko.observable('true');

		this.tabs = ko.observableArray([{
			route: '[/]dashboard/dashboard-kpis',
			title: 'Indicators Dashboard',
			icon: 'fa-solid fa-house'
		},{
			route: '[/]dashboard/dashboard-projects',
			title: 'Projects Dashboard',
			icon: 'fa-solid fa-list-check'
		},{
			route: '[/]dashboard/dashboard-powerBI',
			title: 'Power BI Dashboard',
			icon: 'fa-solid fa-magnifying-glass-chart'
		}]); 
		this.current_route = ko.observable();

		this.dashboard_sidebar_visibility.subscribe((val) => {
			let sidebar = document.getElementById('dashboard-sidebar');
			let ul = document.getElementById('dashboard-ul');

			if (val === 'true')
			{
				sidebar.style.minWidth = '';
				ul.style.width = '';
				window.localStorage.setItem('dashboard_sidebar_open', 'true');
			}
			else if (val === 'false')
			{
				sidebar.style.minWidth = '3.5em';
				ul.style.width = '3.5em';
				window.localStorage.setItem('dashboard_sidebar_open', 'false');
			}
		});

		let state = window.localStorage.getItem('dashboard_sidebar_open') || 'true';
		this.dashboard_sidebar_visibility(state);
	}
	
	load_tab (route)
	{
		window.localStorage.setItem('last_dashboard_page', route);
		const element = this.page.element.querySelector('#page_content');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error('Page not found');
		Grape.pages.load_page(page, element, {}, {}, () => {});
	}

	btn_toggle_visibility ()
	{
		if (this.dashboard_sidebar_visibility() === 'true')
			this.dashboard_sidebar_visibility('false');
		else if (this.dashboard_sidebar_visibility() === 'false')
			this.dashboard_sidebar_visibility('true');
	}
}

class DashboardPage 
{
	constructor (bindings, element, page) 
	{
		this.viewModel = new DashboardVM(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init ()
	{
		document.title = 'Dashboard';
		if (window.localStorage.getItem('last_dashboard_page'))
			this.viewModel.load_tab(window.localStorage.getItem('last_dashboard_page'));
		else
			this.viewModel.load_tab('[/]dashboard/dashboard-kpis');
	}
}

export default {
	route: '[/]dashboard/dashboard',
	page_class: DashboardPage,
	template: template
}
