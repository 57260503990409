import template from './edit-project.html';

class EditProjectViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.project_id = ko.observable();
		this.name = ko.observable();
		this.kpi_id = ko.observableArray([]);
		this.business_unit_id = ko.observableArray([]);
		this.kpi_group = ko.observable();
		this.project_data = ko.observableArray([]);
		this.objectives=ko.observableArray([{name: undefined}]);
		this.project_nr = ko.observable();
		this.description = ko.observable();
		this.availableKPIs = ko.observableArray([]);
		this.start_date = ko.observable();
		this.end_date = ko.observable();
		this.maxObjectives = 10;
		this.selectedKPIs = ko.observableArray([]);
		this.selectedBUs = ko.observableArray([]);
		this.availableBUs = ko.observableArray([]);
		this.project_flags_options = ko.observableArray([]);
		this.selected_flag = ko.observableArray('');

		this.is_date_valid = ko.computed(() => {
			let start_date = this.start_date();
			let end_date = this.end_date();
			if (start_date && end_date)
				return moment(end_date).isSameOrAfter(moment(start_date));

			return true;
		});
		
		this.loadFlagNames();
	}

	btn_add_objective_click (data)
	{
		this.objectives.push({name: undefined});
	}

	btn_remove_objective_click (data)
	{
		this.objectives.remove(data);
	}

	async loadFlagNames() {
		try {
			let ProjectFlagLookup = await Grape.cache.fetch('ProjectFlagLookup');
			this.project_flags_options(ProjectFlagLookup);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while loading flag names:', error);
		}
	}

	async save_click ()
	{
		if (!this.name())
		{
			window.document.getElementById('project-name').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please insert a project name'});
		} 
		else if (!this.project_nr())
		{
			window.document.getElementById('project-nr').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please insert a project nr'});
		} 
		else if (!this.description())
		{
			window.document.getElementById('description').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please insert a description'});
		} else if (!this.start_date())
		{
			window.document.getElementById('start-date').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please select a start date.'});
		} else if (!this.end_date())
		{
			window.document.getElementById('end-date').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Could not save', message: 'Please select an end date'});
		}
		else
		{
			let projectData = {
				name: this.name(),
				project_nr: this.project_nr(),
				project_id: this.project_id(),
				flag_names: this.selected_flag(),
				fields: {
					'add': [
						{'name': 'description', 'value': this.description()},
						{'name': 'start_date', 'value' : this.start_date()},
						{'name': 'end_date','value': this.end_date()},

					]
				}
			};

			if (this.objectives() && this.objectives().length > 0 && JSON.stringify(this.objectives()) !== '[{}]') {
				projectData.fields['add'].push({ 'name': 'objective', 'value': this.objectives() });
			}

			try {
				let response;
	
				if (projectData.project_id)
				{
					// Update an existing project
					response = await fetch('/api/kpi/project', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(projectData),
					});
				}
				else
				{
					// Create a new project
					response = await fetch('/api/kpi/project', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(projectData),
					});
				}
	
				if (response.ok)
				{
					let result = await response.json();
	
					if (result.status === 'OK')
					{
						await Grape.alerts.alert({type: 'success', title: 'Saved', message: 'Project saved successfully!'});
						this.dialog.close(true);
					}
					else
						console.error('Error:', result.message);
				}
				else
					console.error('Network error:', response.statusText);
			} catch (error) {
				console.error('Error:', error);
			}
		}
	}

	async close_click ()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}

class EditProjectClass
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditProjectViewModel(this);
		this.name = 'EditProject';
		this.parentViewModel = bindings.page;

		if (bindings.projectData) 
		{
			let projectData = bindings.projectData;
			this.viewModel.project_id(projectData.project_id);
			this.viewModel.name(projectData.name);
			this.viewModel.project_nr(projectData.project_nr);
			this.viewModel.description(projectData.description);
			this.viewModel.start_date(projectData.start_date);
			this.viewModel.end_date(projectData.end_date);

			if (projectData.objective)
			{
				try { 
					let objectivesArray = JSON.parse(projectData.objective);
					this.viewModel.objectives(objectivesArray);
				} catch (error) {
					console.error('Error parsing project objectives:' , error);
				}
			}
		}
		this.viewModel.project_data(bindings.project_data || [] );

		let project_name = window.document.getElementById('project-name');
		project_name.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});
		
		let project_nr = window.document.getElementById('project-nr');
		project_nr.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let description = window.document.getElementById('description');
		description.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let start_date = window.document.getElementById('start-date');
		start_date.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let end_date = window.document.getElementById('end-date');
		end_date.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		this.init();
	}

	init ()
	{
	}
}

export default {
	name: 'EditProject',
	dialog_class: EditProjectClass,
	template: template,
	provider: 'ps'
}
