import template from './edit-project-target.html';

class EditProjectTargetViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.project_id = ko.observable();
		this.name = ko.observable();
		this.kpi_id = ko.observable();
		this.target_uom = ko.observable();
		this.kpi_group = ko.observable();
		this.project_nr = ko.observable();
		this.kpi_nr = ko.observable();
		this.kpi_name = ko.observable();
		this.description = ko.observable();
		this.available_kpis = ko.observableArray([]);
		this.target_date = ko.observable();
		this.available_uoms = ko.observableArray();
		this.selected_uom = ko.observable();
		this.selected_kpi = ko.observableArray([]);
		this.baseline_date = ko.observable();
		this.baseline = ko.observable();
		this.nominal_target = ko.observable();
		this.target_id = ko.observable();
		this.measure_period = ko.observable();
		this.cumulative = ko.observable(false);
		this.snapshot = ko.observable(false);
		this.increase = ko.observable(false);
		this.decrease = ko.observable(false);

		this.cumulative.subscribe((newVal) => {
			if (newVal === true)
				this.snapshot(false);
		});

		this.snapshot.subscribe((newVal) => {
			if (newVal === true)
				this.cumulative(false);
		});

		this.increase.subscribe((newVal) => {
			if (newVal === true)
				this.decrease(false);
		});

		this.decrease.subscribe((newVal) => {
			if (newVal === true)
				this.increase(false);
		});
	}

	async btn_save_click ()
	{
		try
		{
			let kpi_id = this.selected_kpi()[0].kpi_id();
			let kpi_name = this.selected_kpi()[0].name();
			let kpi_nr = this.selected_kpi()[0].kpi_nr();
			let target_uom = this.selected_uom();
			let target_date = this.target_date();
			let baseline_date = this.baseline_date();
			let name = this.name();
			let project_id = this.project_id();
			let project_nr = this.project_nr();
			let baseline = this.baseline();
			let nominal_target = this.nominal_target();
			let target_id = this.target_id();
			let measure_period = this.measure_period();
			let cumulative = this.cumulative();
			let increase = this.increase();

			let project_target_data = {
				kpi_id,
				kpi_name,
				kpi_nr,
				target_uom,
				target_date,
				baseline_date ,
				name,
				project_id,
				project_nr,
				baseline,
				nominal_target,
				target_id,
				measure_period,
				cumulative,
				increase
			};
			let response = await fetch('/api/kpi/kpi-target', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(project_target_data)
			});

			if (response.ok)
			{
				let result = await response.json();
				if (result.status === 'OK')
				{
					await Grape.alerts.alert({type: 'success', title: 'Saved', message:'Project target saved successfully!'});
					this.dialog.close(true); // Close the dialog with success status
				}
				else
					Grape.alerts.alert({type: 'error', title: 'Error', message:'Failed to save project target!'});
			}
		} catch (error) {
			console.error('Save Error:', error);
			Grape.alerts.alert({type: 'error', title: 'Error', message:'An error occurred while saving. Please try again.'});
		}
	}

	 generate_unit_options ()
	 {
		if (this.selected_kpi().length > 0)
		{
			let kpi_uom = this.selected_kpi()[0].uom();
			this.selected_uom(kpi_uom);

			let updated_uoms = this.available_uoms().map(unit => ({
				symbol_html: unit.symbol_html,
				uom: unit.uom,
				isSelected: unit.uom === kpi_uom,
			}));
			return updated_uoms;
		}
		return this.available_uoms();
    }

	async btn_close_click ()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}

class EditProjectTargetClass
{
	constructor(bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditProjectTargetViewModel(this, bindings.data);
		this.name = 'EditProjectTarget';
		this.parentViewModel = bindings.page;

		this.viewModel.name(bindings.name);
		this.viewModel.project_id(bindings.project_id);
		this.viewModel.project_nr(bindings.project_nr);
		this.viewModel.baseline_date(bindings.baseline_date);
		this.viewModel.baseline(bindings.baseline);
		this.viewModel.target_date(bindings.target_date);
		this.viewModel.nominal_target(bindings.nominal_target);
		this.viewModel.name(bindings.kpi_name);
		this.viewModel.target_id(bindings.target_id);
		this.viewModel.measure_period(bindings.measure_period);

		if (bindings.cumulative === true)
			this.viewModel.cumulative(true);
		else
			this.viewModel.snapshot(true);

		if (bindings.increase === true)
			this.viewModel.increase(true);
		else
			this.viewModel.decrease(true);

		this.init();
	}

	async init ()
	{
		
		let uom_data = await Grape.cache.fetch('UoM');

			this.viewModel.available_uoms(uom_data);

		// Load KPIs
		let result = await Grape.fetches.getJSON('api/record', {
			schema: 'kpi',
			table: 'dv_kpis',
			filter: []
		});

		if (result.status === 'OK')
		{
			let kpis = result.records.map((record) => ({
				kpi_id: ko.observable(record.kpi_id),
				name: ko.observable(record.name),
				kpi_nr: ko.observable(record.kpi_nr),
				target_uom: ko.observable(record.target_uom),
				uom : ko.observable(record.uom)
			}));
			this.viewModel.available_kpis(kpis);
		}
		else
			throw new Error (result.message || result.code)

		let matched_kpi = this.viewModel.available_kpis().find(kpi => kpi.kpi_id() == this.bindings.kpi_id);
		if (matched_kpi)
			this.viewModel.selected_kpi([matched_kpi]);

		this.viewModel.selected_uom(this.bindings.target_uom );
	}
}

export default {
	name: 'EditProjectTarget',
	dialog_class: EditProjectTargetClass,
	template: template,
	provider: 'ps'
}
