import template from './projects-details.html';
import ProjectViewModel from '../../../lib/ProjectViewModel';

ko.bindingHandlers.formattedDate = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());
		var formattedDate = new Date(value).toLocaleDateString();
		element.innerText = formattedDate;
	},
};

class ProjectDetailContentPageVM
{
	constructor (page)
	{
		document.title = 'Project details'
		this.loaded = ko.observable(false);
		this.page = page;
		this.projectvm = new ProjectViewModel(page.bindings.project_id || null );
		this.objectives = ko.observableArray([]);
		this.project_id = ko.observable();
		this.project_name = ko.observable();
		this.project_type = ko.observable();
		this.start_date = ko.observable();
		this.end_date = ko.observable();
		this.user_data = ko.observableArray([]);
		this.role_name = ko.observable();
		this.username = ko.observable();

		// permissions
		this.can_edit = ko.observable(false);
	}

	async btn_edit_details_click ()
	{
		let project_details = {
			name: this.projectvm.name(),
			project_id: this.projectvm.project_id(),
			project_nr: this.projectvm.project_nr(),
			description: this.projectvm.description(),
			start_date: this.projectvm.start_date(),
			end_date: this.projectvm.end_date(),
			project_type: this.projectvm.project_type(),
			project_sponsor: this.projectvm.project_sponsor(),
			project_champion: this.projectvm.project_champion(),
			project_owner: this.projectvm.project_owner(),
		}

		let response = await Grape.dialog.open('EditProjectDetails', project_details);

		// reload so that the project # and description on all project pages actually change
		if (response)
			location.reload();
	}

	async btn_add_objectives_click ()
	{
		let project_name = this.projectvm.name();
		let project_id = this.projectvm.project_id();
		let response = await Grape.dialog.open('EditProjectObjective', {
			objectives: this.objectives(),
			name: project_name,
			project_id: project_id,
		})

		if (response)
			this.page.updateData();
	}

	async btn_edit_objective_click ()
	{
		let project_name = this.projectvm.name();
		let project_id = this.projectvm.project_id();
		let response = await Grape.dialog.open('EditProjectObjective', {
			objectives: this.objectives(),
			name: project_name,
			project_id: project_id,
		})

		if (response)
			this.page.updateData();
	}

	async btn_delete_objective_click (data)
	{
		let objective_to_delete = data;
		this.objectives.remove(objective_to_delete);
		this.page.update_project_with_objectives();
	}
}

class ProjectDetailContentPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProjectDetailContentPageVM(this);
		this.timer = null;
		this.parentViewModel = bindings.page;
	}

	async init ()
	{
		document.title = 'Project Details';

		let permission = await window.user_permission(['admin', 'Edit Project details and objectives', 'Setup new Project']);
		if (permission)
			this.viewModel.can_edit(permission);

		this.viewModel.project_id( this.bindings.project_id || null);
		await this.updateData();
	}

	async updateData()
	{
		let current_project_id = this.viewModel.project_id();
		let objective_data = {
			table: 'dv_projects',
			schema: 'kpi',
			filter: [
				{
					field: 'project_id',
					operand: '=',
					value: current_project_id,
				},
			],
		}
		try {
			let result = await Grape.fetches.getJSON('/api/record', objective_data);
			if (result.status !== 'ERROR')
			{
				if (Array.isArray(result.records) && result.records.length > 0)
				{
					let objective_value = result.records[0].objective; // Use 'objective' as the source
					let objectives_array = [];
					try {
						objectives_array = JSON.parse(objective_value);
					} catch (parseError) {
						objectives_array = [{ name: objective_value }];
					}

					this.viewModel.objectives(objectives_array);
					this.viewModel.projectvm.name(result.records[0].name);
					this.viewModel.projectvm.start_date(result.records[0].start_date);
					this.viewModel.projectvm.description(result.records[0].description);
					this.viewModel.projectvm.end_date(result.records[0].end_date);
					this.viewModel.projectvm.project_nr(result.records[0].project_nr);
					this.viewModel.projectvm.project_type(result.records[0].project_type);
					this.viewModel.projectvm.load()
				}
				else
					this.viewModel.objectives([]);
			}
		}
		catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async update_project_with_objectives ()
	{
		try {
			let project_data = {
				project_id: this.viewModel.projectvm.project_id(),
				fields: {
					add : [
						{
							name: 'objective',
							value: ko.toJSON(this.viewModel.objectives()),
						},
					]
				}
			};

			let response = await fetch ('api/kpi/project', {
				method: 'POST',
				headers:{
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(project_data)
			});

			if ( response.ok)
			{
				let result = await response.json();
				if (result === 'OK')
					Grape.alerts.alert({type: 'success', message: 'Project Objective successfully deleted!', title: 'Success'});
			}
		} catch (error) {
			console.error('Error:', error)
		}
	}
}

export default {
	route: '[/]projects/projectdetail',
	page_class: ProjectDetailContentPage,
	template: template
}
