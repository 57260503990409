import template from './project-target.html';
import ProjectViewModel from '../../../../lib/ProjectViewModel';

ko.bindingHandlers.formattedDate = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());
		var formattedDate = new Date(value).toLocaleDateString();
		element.innerText = formattedDate;
	},
};

class ProjectTargetVM
{
	constructor (page)
	{
		document.title = 'Project Target'
		this.loaded = ko.observable(false);
		this.projectvm =  new ProjectViewModel(page.bindings.project_id || null );
		this.page = page;
		this.current_page_id = ko.observable();
		this.current_page_title = ko.observable('');
		this.targets = ko.observableArray([]);
		this.available_uoms = ko.observableArray();
		this.selected_uom = ko.observable();
		this.kpi_nr = ko.observable()
		this.kpi_name = ko.observable();
		this.uom = ko.observable();

		// permissions
		this.add_target = ko.observable(false);
		this.edit_target = ko.observable(false);
	}

	btn_add_project_click ()
	{
		let project_target = {
			name: this.projectvm.name(),
			project_id: this.projectvm.project_id(),
			project_nr: this.projectvm.project_nr(),
		}
		Grape.dialog.open('EditProjectTarget', project_target)
		.then(() => {
			this.page.updateData();
		})
		.catch((error) => {
			console.error('Error adding Project:', error);
		});
	}

	btn_edit_project_click (data)
	{
		Grape.dialog.open('EditProjectTarget', data)
		.then(() => {
			this.page.updateData();
		})
		.catch((error) => {
			console.error('Error adding Project:', error);
		});
	}

	async btn_remove_target_click (data)
	{
		let alert = await Grape.alerts.confirm({type: 'warning', title: 'Confirm', message: 'Are you sure you want to delete this baseline & target?'})
		if (alert) {
			let options = {
				target_id: data.target_id
			}
			let response = await fetch('/api/kpi/kpi-target', {
				method: 'DELETE',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify(options)
			});

			if (response.ok)
				Grape.alerts.alert({type: 'success', title: 'Success', message: `Target for ${data.kpi_name} deleted successfully!`})
			else
				throw new Error(response.message || response.code || 'Unkown Error');
			this.page.updateData();
		}
	}
}

class ProjectTargetPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProjectTargetVM(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Project Target';

		let permission_add = await window.user_permission(['admin', 'Add Project associated Targets']);
		if (permission_add)
			this.viewModel.add_target(permission_add);

		let permission_edit = await window.user_permission(['admin', 'Add Project associated Targets', 'Edit Project associated Targets']);
		if (permission_edit)
			this.viewModel.edit_target(permission_edit);
	}

	async updateData ()
	{
		let current_project_id = this.viewModel.projectvm.project_id;
		let project_targets = {
			table: 'v_project_targets',
			schema: 'kpi',
			filter:[{
				field: 'project_id',
				operand: '=',
				value: current_project_id,
				},
			]
		}
		try {
			let result = await Grape.fetches.getJSON('/api/record', project_targets);
			if (result.status != 'ERROR')
				this.viewModel.targets(result.records);
			else
				throw new Error(result.message || result.code)
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	route: '[/]projects/project-target',
	page_class: ProjectTargetPage,
	template: template
}
