import template from './kpi-previous.html';

ko.bindingHandlers.formattedDate = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());
		var formattedDate = new Date(value).toLocaleDateString();
		element.innerText = formattedDate;
	},
};

ko.bindingHandlers.formattedDateRange = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());

		if (typeof value === 'string') {
			var dateRangeMatch = value.match(/\[(.*?),(.*?)\)/);

			if (dateRangeMatch) {
				var startDate = new Date(dateRangeMatch[1]);
				var endDate = new Date(dateRangeMatch[2]);

				var formattedStartDate = startDate.toLocaleDateString();
				var formattedEndDate = endDate.toLocaleDateString();

				element.innerText = `(${formattedStartDate} - ${formattedEndDate})`;
				return;
			}
		}
		element.innerText = value;
	},
};

class KPIPreviousDataVM
{
	constructor (page)
	{
		this.page = page;
		this.kpi_values = ko.observableArray();
		this.table_search_string = ko.observable('');
		this.display_completed = ko.observable('true');
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.page_count = ko.observable(1);
		this.selected_from_date = ko.observable('');
		this.selected_to_date = ko.observable('');
		this.available_projects = ko.observableArray([]);
		this.available_statuses = ko.observableArray([]);
		this.available_business_units = ko.observableArray([]);
		this.selected_projects = ko.observableArray([]);
		this.selected_statuses = ko.observableArray([]);
		this.selected_business_units = ko.observableArray([]);
		this.value_status = ko.observableArray();
		this.sort_field = ko.observable('captured_date');
		this.sort_order = ko.observable('ASC');
		this.kpis = ko.observableArray();
		this.confirmed = ko.observable();
		this.selected_kpi = ko.observable();

		// permissions
		this.can_remove = ko.observable(false);

		this.kpiSelected = () => {
			this.page.updateData();
		};

		this.table_search_string.subscribe((value) => {
			this.page.updateData();
		});

		this.display_completed.subscribe((value) => {
			//localStorage.setItem('display_completed', value);
			this.page.updateData();
		});

		this.selected_from_date.subscribe((value) => {
			this.page.updateData();
		});

		this.selected_to_date.subscribe((value) => {
			this.page.updateData();
		});

		this.selected_projects.subscribe((value) => {
			this.page.updateData();
		});

		this.selected_statuses.subscribe((value) => {
			this.page.updateData();
		});

		this.selected_business_units.subscribe((value) => {
			this.page.updateData();
		});
	}

	btn_bulk_edit_values_click ()
	{
		window.Grape.navigate('[/]kpi/bulk-value-edit');
	}

	format_reporting_period (period)
	{
		let dates = period.slice(1, -1).split(',');
		return dates[0].replace(/-/g, '/') + ' - ' + dates[1].replace(/-/g, '/');
	}

	async btn_view_kpi_value_click (data)
	{
		await Grape.dialog.open('KPIValueDetails', {data: data});
		this.page.updateData();
	}

	async btn_view_log_file_click (data)
	{
		await Grape.dialog.open('LogFile', {kpi_info: data});
	}

	sort_column (column)
	{
		if (this.sort_field() === column)
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		else
		{
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.page.updateData();
	}

	btn_clear_filters_click ()
	{
		this.selected_kpi('');
		this.selected_business_units([]);
		this.selected_statuses([]);
		this.selected_projects([]);
		this.selected_to_date(null);
		this.selected_from_date(null);
		this.table_search_string('');

		this.page.updateData();
	}

	async btn_delete_kpi_value_click (data)
	{
		let result = await Grape.alerts.confirm({
				type: 'warning',
				message: 'Are you sure you want to delete this Indicator Value?',
				title: 'Warning',
		});

		if (result)
		{
			let options = {
				kpi_value_id: data.kpi_value_id
			}

			let response = await fetch('/api/kpi/kpi-value', {
				method: 'DELETE',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(options)
			});

			if (response.ok)
				console.log('Deleted successfully')
			else
				throw new Error(response.message || response.code || 'Unknown Error');
			this.page.updateData();
		}
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_search_click (data)
	{
		this.table_search_string('');
		this.selected_kpi(data);
		this.page.updateData();
		this.kpis([]);
	}
}

class KPIPreviousData
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPIPreviousDataVM(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Indicator History';

		//TODO: Uncomment and use localStorage when users are used to system (Request from Hans that display_completed === true by default for now)
		//let is_display_completed = localStorage.getItem('display_completed');
		//this.viewModel.display_completed(is_display_completed === 'true');

		let permissions = await window.user_permission(['admin']);
		if (permissions)
			this.viewModel.can_remove(permissions);

		// Get list of projects
		let options_projects = {
			table: 'v_projects',
			schema: 'kpi',
			fields: ['project_id', 'name'],
			filter: []
		}

		let projects = await Grape.fetches.getJSON('/api/record', options_projects);

		if (projects.status != 'ERROR')
		{
			let project_names = [];
			for (let i = 0; i < projects.records.length; i++)
				project_names.push(projects.records[i].name);
			
			this.viewModel.available_projects(project_names);
		}
		else 
			throw new Error (projects.message || projects.code)

		
		//Get list of business units
		let business_units = {
			table: 'v_business_units',
			schema: 'kpi',
			fields: ['name'],
			limit: 1000
		}

		let result = await Grape.fetches.getJSON('/api/record', business_units);
		
		if (result.status != 'ERROR')
		{
			let business_unit_names = [];
			for (let i = 0; i < result.records.length; i++)
				business_unit_names.push(result.records[i].name);

			this.viewModel.available_business_units(business_unit_names);
		}
		else 
			throw new Error (result.message || result.code)

		try {
			let statuses = await Grape.cache.fetch('KPIValueStatusLookup');
			let status_names = [];
			for (let i = 0; i < statuses.length; i++)
				status_names.push(statuses[i].name);
			this.viewModel.available_statuses(status_names);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error)
			}
		}

	async updateData ()
	{
		let options = {
			table: 'dv_kpis',
			schema: 'kpi',
			offset: 0,
			fields: ['name'],
			filter_join: 'AND',
			join: 'OR',
			filter: [],
		};

		if (this.viewModel.table_search_string() && this.viewModel.table_search_string() != '')
		{
			options.filter.push({ 
				field: 'name', 
				operand: 'ILIKE', 
				value: `%${this.viewModel.table_search_string()}%` 
			});

			try {
				let result = await Grape.fetches.getJSON('/api/record', options);
				if (result.status == 'OK')
					this.viewModel.kpis(result.records);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error)
			}
		}

		let response = {
			table: 'v_kpi_values',
			schema: 'kpi',
			offset: 0,
			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field(),
			filter_join: 'AND',
			join: 'OR',
			filter: []
		}

		// LOGIC: filter 
		if (this.viewModel.selected_projects().length > 0)
		{
			response.filter.push({
				field: 'project',
				operand: 'IN',
				value: this.viewModel.selected_projects()
			});
		}

		if (this.viewModel.selected_business_units().length > 0)
		{
			response.filter.push({
				field: 'business_unit',
				operand: 'IN',
				value: this.viewModel.selected_business_units()
			});
		}

		if (this.viewModel.selected_statuses().length > 0)
		{
			response.filter.push({
				field: 'kpi_value_status',
				operand: 'IN',
				value: this.viewModel.selected_statuses()
			});
		}

		if (this.viewModel.selected_from_date())
		{
			response.filter.push({
				field: 'reporting_period_start',
				operand: '>=',
				value: this.viewModel.selected_from_date()
			});
		}

		if (this.viewModel.selected_to_date())
		{
			response.filter.push({
				field: 'reporting_period_end',
				operand: '<=',
				value: this.viewModel.selected_to_date()
			});
		}

		if (!this.viewModel.display_completed())
		{
			response.filter.push({
				field: 'confirmed', 
				operand: '=', 
				value: 'false'
			});
		}

		// LOGIC: Search
		if (this.viewModel.selected_kpi() && this.viewModel.selected_kpi() != '')
		{
			response.filter.push({ 
				field: 'kpi', 
				operand: 'ILIKE', 
				value: `%${this.viewModel.selected_kpi().name}%` 
			});
		}

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			response.limit = this.viewModel.current_page_size();
			response.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', response);

			if (result.status != 'ERROR')
			{
				let formatted_records = result.records.map(record => {
					if (record.reporting_period)
						record.reporting_period = this.viewModel.format_reporting_period(record.reporting_period);
				
					return record;
				});

				this.viewModel.kpi_values(formatted_records);
				this.viewModel.page_count(Math.floor(result.total/result.limit)+1);
			} else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		}

		try {
			let result = await Grape.cache.fetch('KPIValueStatusLookup');
			this.viewModel.value_status(result);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		}
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]kpi/previous-data',
	page_class: KPIPreviousData,
	template: template
}
