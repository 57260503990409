import template from './bulk-kpi-value-edit.html';

class BulkKPIEditViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(false);
		this.no_kpi_value_data = ko.observable(false);
		this.display_confirmed = ko.observable(localStorage.getItem('display_confirmed') === 'true');

		this.display_confirmed.subscribe((value) => {
			localStorage.setItem('display_confirmed', value);
			this.page.update_data();
		});
	}

	btn_back_click ()
	{
		window.Grape.navigate('[/]kpi/kpi-capture');
	}
	
	async btn_save_edits_clicked ()
	{
		let response = await Grape.alerts.confirm({ type: 'warning', message: 'Are you sure you want to save all changes?' });
		if (response)
			this.page.save_edits();
	}

	async btn_clear_edits_clicked ()
	{
		let response = await Grape.alerts.confirm({ type: 'warning', message: 'Are you sure you want to cancel and restore all changes?' });
		if (response)
		{
			this.page.clear_edits();
			this.page.update_data();
		}
	}
}

class BulkKPIEditClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new BulkKPIEditViewModel(this);
		this.name = 'BulkKPIEditClass';
	}

	async init ()
	{
		document.title = 'Indicator Value Bulk Edit';

		this.viewModel.loading(true);
		let container = document.querySelector('.data-cells');
		this.grid = document.createElement('canvas-datagrid');

		await this.update_data();

		if (!this.grid.data.length > 0)
		{
			this.viewModel.loading(false);
			return;
		}

		this.grid.style.height = '50vh';
		this.grid.style.width = '100%';
		container.appendChild(this.grid);

		this.grid.addEventListener('beginedit', (e) => {
			e.cell._oldValue = e.cell.value;
		});

		this.grid.addEventListener('endedit', (e) => {
			if (!this.grid.data || e.cell.boundRowIndex == null) return;

			if (e.cell._oldValue !== e.value)
			{
				let row = this.grid.data[e.cell.boundRowIndex];
				if (!row._edited_cells) row._edited_cells = {};

				row._edited_cells[e.cell.header.name] = {
					boundRowIndex: e.cell.boundRowIndex,
					boundColumnIndex: e.cell.boundColumnIndex,
					oldValue: e.cell._oldValue,
					newValue: e.value
				};

				this.grid.draw();
			}
		});

		this.grid.addEventListener('contextmenu', (e) => {
			if (!this.grid.data || e.cell.boundRowIndex == null) return;

			if (e.cell && !e.cell.isColumnHeader)
			{
				e.items.push({
					title: 'Delete Row',
					click: async () => {
						let response = await Grape.alerts.confirm({
							type: 'danger',
							message: 'Are you sure you want to delete the ENTIRE row?'
						});

						if (response)
						{
							let row = this.grid.data[e.cell.boundRowIndex];
							row._deleted = true;
							this.grid.draw();
						}
					}
				});
			}
		});

		this.grid.addEventListener('rendercell', (e) => {
			if (!this.grid.data || e.cell.boundRowIndex == null) return;

			let row = this.grid.data[e.cell.boundRowIndex];
			if (row)
			{
				if (row._deleted)
				{
					e.ctx.fillStyle = 'red';
					e.ctx.fillRect(e.cell.x, e.cell.y, e.cell.width, e.cell.height);
				}
				else if (row._edited_cells && row._edited_cells[e.cell.header.name])
				{
					e.ctx.fillStyle = 'yellow';
					e.ctx.fillRect(e.cell.x, e.cell.y, e.cell.width, e.cell.height);
				}
				else if (e.cell.value === '' || e.cell.value == null)
				{
					e.ctx.strokeStyle = 'orange';
					e.ctx.lineWidth = 2;
					e.ctx.strokeRect(e.cell.x, e.cell.y, e.cell.width, e.cell.height);
				}
			}
		});

		this.viewModel.loading(false);
	}

	async update_data ()
	{
		try
		{
			let obj = {
				table: 'v_kpi_values',
				schema: 'kpi',
				fields: [
					'kpi_value_id', 
					'business_unit', 
					'kpi', 
					'project', 
					'cumulative', 
					'flag_names', 
					'kpi_value_status', 
					'reporting_period', 
					'confirmed', 
					'reading_note', 
					'reading_date', 
					'reading_value', 
					'reading_uom'
				],
				filter: [],
				limit: 10000
			}

			if (!this.viewModel.display_confirmed())
			{
				obj.filter.push({
					field: 'confirmed', 
					operand: '=', 
					value: 'false'
				});
			}

			let result = await Grape.fetches.getJSON('/api/record', obj);

			if (result.status === 'OK')
			{
				this.grid.data = result.records;

				if (!this.grid.data.length > 0)
				{
					this.viewModel.no_kpi_value_data(true);
					return;
				}

				let header_row = this.grid.data[0];
				let new_schema = [];

				Object.keys(header_row).forEach((key, index) => { 
					if (key !== 'kpi_value_id')
						new_schema.push({ name: key, title: key });
				});

				this.grid.schema = new_schema;
				this.grid.draw();
			}
			else
				throw new Error(result.message || 'Error fetching KPI data');

		} catch (error) {
			Grape.alerts.alert({ type: 'error', message: error.message });
			console.error(error);
		}
	}

	collect_edits ()
	{
		let changes = { edited_cells: [], deleted_rows: [] };

		for (let i = 0; i < this.grid.data.length; i++)
		{
			let row = this.grid.data[i];
			if (row._deleted) changes.deleted_rows.push({ boundRowIndex: i, rowData: row });

			if (row._edited_cells)
				for (let key in row._edited_cells)
					if (row._edited_cells.hasOwnProperty(key))
						changes.edited_cells.push(row._edited_cells[key]);
		}
		return changes;
	}

	async save_edits ()
	{
		let changes = this.collect_edits();
		try
		{
			let updates = changes.edited_cells.map(edit => {
				let row = this.grid.data[edit.boundRowIndex];
				
				let payload = {
					kpi_value_id: row.kpi_value_id,
					business_unit: row.business_unit,
					kpi: row.kpi,
					project_name: row.project,
					cumulative: row.cumulative,
					flag_names: Array.isArray(row.flag_names) && row.flag_names.length ? '{' + row.flag_names.join(',') + '}' : '{}',
					kpi_value_status: row.kpi_value_status,
					reporting_period: row.reporting_period,
					confirmed: row.confirmed,
					reading_note: row.reading_note,
					reading_date: row.reading_date,
					reading_value: row.reading_value,
					reading_uom: row.reading_uom
				};

				return Grape.fetches.postJSON('/api/kpi/kpi-value', payload);
			});

			let deletes = changes.deleted_rows.map(row_info => {
				let row = row_info.rowData;
				let options = { kpi_value_id: row.kpi_value_id };

				return fetch('/api/kpi/kpi-value', {
					method: 'DELETE',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(options)
				}).then(response => response.json());
			});

			let results = await Promise.all([...updates, ...deletes]);
			let errors = results.filter(res => res.status !== 'OK');

			if (errors.length)
				Grape.alerts.alert({ type: 'warning', message: 'Some updates failed!' });
			else
			{
				Grape.alerts.alert({ type: 'success', message: 'Updated successfully!' });
				await this.update_data();
			}

		} catch (error) {
			Grape.alerts.alert({ type: 'error', message: error.message });
			console.error("Error saving KPI edits:", error);
		}
	}

	clear_edits ()
	{
		this.grid.data.forEach(row => {
			if (row._edited_cells)
				delete row._edited_cells;

			if (row._deleted)
				row._deleted = false;
		});

		this.grid.draw();
	}
}

export default {
	route: '[/]kpi/bulk-value-edit',
	page_class: BulkKPIEditClass,
	template: template
}