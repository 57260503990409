class SDGViewModel
{
	constructor(sdg_id, sdg_name)
	{
		this.sdg_nr = ko.observable('');
		this.sdg_id = ko.observable(sdg_id);
		this.sdg_name = ko.observable(sdg_name);
		this.goal = ko.observable('');
		this.img_path = ko.observable('');
	}

	async load()
	{
		//get SDG details
		let result = await Grape.fetches.getJSON('/api/record', {
			schema: 'sdg',
			table: 'v_sdgs',
			filter: [{field: 'sdg_id', 
				oper: '=',
				value: this.sdg_id()}],
			fields: ['sdg_name', 'sdg_id', 'sdg_nr', 'goal']
		});

		if (!result.records) 
			console.log('No data');
		
		let img_path = result.records.map(sdg => ({
			...sdg, 
			img_path: `./SDG_icons/Goal-${String(sdg.sdg_nr).padStart(2, '0')}.png`
		}));

		this.sdg_name(result.records[0].sdg_name);
		this.sdg_id(result.records[0].sdg_id);
		this.goal(result.records[0].goal);
		this.sdg_nr(result.records[0].sdg_nr);
		this.img_path(img_path[0].img_path);
	}

	async save ()
	{
	}
}

export default SDGViewModel;