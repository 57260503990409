import template from './kpi-user.html';

class KPIUsersVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.users = ko.observableArray([]);
		this.username = ko.observable();
		this.email = ko.observable();
		this.kpi_id = ko.observable();

		// permissions
		this.can_remove = ko.observable(false);

		// Computed observable to check if there are any users with roles
		this.no_users_with_roles = ko.computed(() => {
			return !this.users().some(user => user.roles && user.roles.trim().length > 0);
		});
	}

	async btn_new_user_click ()
	{
		let kpi_id = this.kpi_id();
		await Grape.dialog.open('EditKPIUserDialog', {kpi_id, mode: 'new', added_users: this.users()});
		this.page.updateData();
	}

	async btn_delete_user_click (data)
	{
		let kpi_id = this.kpi_id();
		let user_id = data.user_id;

		let answer = await Grape.alerts.confirm({type: 'warning', title: 'Warning', message: 'Are you sure you want to remove this Indicator user?'});

		if (answer)
		{
			let response = await fetch('/api/catalytix/catalytix', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({user_id, kpi_id}),
			});

			if (!response.ok)
			{
				let errorData = await response.json();
				console.error('Error deleting user:', errorData);
				return;
			}
			else
			{
				await Grape.alerts.alert({type: 'success', title: 'Saved', message: 'Successfully removed user from all indicators!'});
				this.page.updateData();
			}
		}
	}
}

class KPIUsersPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPIUsersVM(this);
		this.timer = null;
		this.viewModel.kpi_id(bindings.kpi_id);
	}

	async init ()
	{
		document.title = 'Indicator Users';

		let permission = await window.user_permission(['admin']);
		if (permission)
			this.viewModel.can_remove(true);
	}

	async updateData (v,e)
	{
		let user_data = {
			table: 'v_kpi_users',
			schema: 'kpi',
			sortfield: 'user_id',
			sortorder: 'ASC',
			filter: []
		}

		let users = await Grape.fetches.getJSON('/api/record', user_data);

		if (users.status != 'ERROR')
		{
			let id = parseInt(this.viewModel.kpi_id());

			let kpi_users = users.records
				.filter(user => user.all_kpis && user.all_kpis.some(kpi => kpi.kpi_id === id))
				.map(user => {
					let matchingkpi = user.all_kpis.find(kpi => kpi.kpi_id === id);
					return {
						...user,
						all_kpis: matchingkpi ? [matchingkpi] : [{ roles: [] }]
					};
				});

			this.viewModel.users(kpi_users);
		}
			
		else
			throw new Error(users.message || users.code)
	}
}

export default {
	route: '[/]kpi/kpi-users',
	page_class: KPIUsersPage,
	template: template
}
