/**
 * Cache for BusinessUnitFieldLookup
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'BusinessUnitFieldLookup',
	options: {
		table: 'v_lookup_value',
		schema: 'grape',
		sortfield: 'lookup_name',
		filter: [{field: 'lookup_name', operator: '=', value: 'BusinessUnitField'}]
	}
};

