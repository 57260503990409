import template from './sdg-overview-sidebar.html';
import SDGViewModel from '../../../../lib/SDGViewModel';

export class SDGAllVM
{
	constructor (page)
	{
		document.title = 'SDG';
		this.page = page;
		//this.sdgvm = new SDGViewModel(page.bindings.sdg_id || null);
		this.sdg_id = ko.observable();
		this.current_page_title = ko.observable('');
		this.current_route = ko.observable();
		this.change = ko.observable(false);
		this.selected_page = ko.computed(() => {
			return window.AppState.selected_page();
		});
		this.test = ko.observable('ferfergergergerfegergwefvgberfgrefgfrfgr');

		this.tabs = ko.observableArray([{
			route: '[/]sdg/overview',
			title: 'Overview',
			heading_text: 'Overview',
			icon: 'fa-thin fa-list-ul'
		}, {
			route: '[/]sdg/about',
			title: 'About',
			heading_text: 'About',
			icon: 'fa-thin fa-circle-info'
		}, {
			route: '[/]sdg/search',
			title: 'Quick Search',
			heading_text: 'Search',
			icon: 'fa-thin fa-magnifying-glass'
		}]);

		this.current_route.subscribe((newPageId) => {
			let matchingTab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === newPageId;
			});
			this.current_page_title(matchingTab ? matchingTab.heading_text : '');
		}, this);

		this.change.subscribe((val) => {
			this.load_tab('[/]sdg/search');
		});
	}

	async load()
	{
		//return await this.sdgvm.load();
	}

	async load_tab (route)
	{
		window.localStorage.setItem('last_sdg_page', route);
		this.current_route(route);
		const element = this.page.element.querySelector('#page_content');
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id '${route}' not found`);

		await Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class SDGAll
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new SDGAllVM(this);
	}

	async init()
	{
		document.title = 'SDG';

		if (this.bindings.sdg_id) {
			//this.viewModel.sdgvm.sdg_id(this.bindings.sdg_id); // Set the SDG ID
			//await this.viewModel.sdgvm.load(); // Load the SDG details
		}
		if (this.bindings.from === 'overview')
		{
			this.viewModel.load_tab('[/]sdg/detail');
			this.bindings.from = null;
		} 
		else if (this.bindings.from === undefined || this.bindings.from === null || this.bindings.from === '')
			this.viewModel.load_tab('[/]sdg/overview');

		if (this.bindings.sdg_id)
		{
			window.localStorage.setItem('last_visited_sdg_id', this.bindings.sdg_id);
			//this.viewModel.sdgvm.sdg_id(this.bindings.sdg_id);
			//this.viewModel.load();
		}
		else if (window.localStorage.getItem('last_visited_sdg_id'))
		{
			let last_sdg_id = window.localStorage.getItem('last_visited_sdg_id');
			//this.viewModel.sdgvm.sdg_id(last_sdg_id);
			this.viewModel.load()
		}

		this.viewModel.load();

		if (this.bindings.page_id)
			this.viewModel.load_tab(this.bindings.page_id);
		else if (window.localStorage.getItem('last_sdg_page'))
			this.viewModel.load_tab(window.localStorage.getItem('last_sdg_page'));
		else
			this.viewModel.load_tab('[/]sdg/overview');

	}

	async updateData()
	{
		
	}
}

export class SDGTest {
	constructor(page, element)
	{
		this.viewModel = new SDGAllVM();
		this.page = page;
		this.element = element;
		
		this.test = ko.observable(element);
	}

	change_tab()
	{
		this.viewModel.change(true);
	}
}

export default {
	route: '[/]frameworks/sdg/sdg-overview',
	page_class: SDGAll,
	template: template
}