/**
 * Cache for SDG linked Projects
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'SDGProject',
	options: {
		tablename: 'v_sdg_projects',
		schema: 'sdg',
		filter: []
	}
};