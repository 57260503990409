import template from './kpi-capture-data.html';

class KPICaptureData
{
	constructor (page)
	{
		this.page = page;
		this.is_loading = ko.observable(false);

		// Inputs for KPI
		this.reading_date = ko.observable(moment().format('YYYY-MM-DD'));
		this.captured_date = ko.observable(moment().format('YYYY-MM-DD'));
		this.start_date = ko.observable('');
		this.end_date = ko.observable(null);
		this.kpi_uom = ko.observable('___');
		this.default_uom = ko.observable();
		this.value = ko.observable('');
		this.selected_status = ko.observable('Draft');
		this.notes = ko.observable('');
		this.selected_project = ko.observable();
		this.project_options = ko.observableArray();
		this.selected_uom = ko.observable(this.default_uom());
		this.uom_options = ko.observableArray();
		this.supported_docs = ko.observable(null);
		this.time_period_map = ko.observable();
		this.kpi_value_id = ko.observable();
		this.bu_id = ko.observable();
		this.bu_name = ko.observable('___');
		this.bu_type = ko.observable('___');
		this.bu_nr = ko.observable('___');
		this.kpi_details = ko.observableArray();
		this.cumulative = ko.observable(true);
		this.snapshot = ko.observable(false);
		this.log_file = ko.observableArray();

		// permissions
		this.can_capture = ko.observable(false);

		this.kpis = ko.observableArray([]);
		this.kpi_id = ko.observable();
		this.kpi_data = ko.observable(null);
		this.kpi_name = ko.observable();
		this.kpi_nr = ko.observable('___');
		this.value_status_options = ko.observableArray([]);
		this.projects = ko.observableArray();
		this.search_string = ko.observable('');
		this.selected_kpi = ko.observable();
		this.selected_time_period = ko.observable('');
		this.time_period_options = ko.observableArray([]);
		this.save_btn = ko.observable("Save");
		this.is_draft = ko.computed(() => this.selected_status() === "Draft");

		this.is_date_valid = ko.computed(() => {
			let start_date = this.start_date();
			let end_date = this.end_date();
			if (start_date && end_date)
				return moment(end_date).isSameOrAfter(moment(start_date));

			return true;
		});

		this.is_value_valid = ko.computed(() => {
			let val = this.value();
			return val < 100 || val > 999999;
		});

		this.search_string.subscribe((value) => {
			this.kpi_uom(value.uom);
			this.page.updateData();
		});

		this.cumulative.subscribe((value) => {
			if (value === true)
				this.snapshot(false);
		});

		this.snapshot.subscribe((value) => {
			if (value === true)
			{
				this.cumulative(false);
				this.selected_time_period('');
			}
			this.end_date('');
		});

		this.selected_time_period.subscribe((value) => {
			this.update_end_date();
		});

		this.start_date.subscribe((value) => {
			this.update_end_date();
		});

		this.selected_status.subscribe((value) => {
			if (value == 'Draft')
				this.save_btn('Save as draft');
			else
				this. save_btn('Save');
		});
	}

	//automatically update the end date when the user choses a time period
	update_end_date ()
	{
		let start_date = this.start_date();
		let time_period = this.selected_time_period();

		if (start_date && time_period) 
		{
			let interval_data = this.time_period_map[time_period];
			if (interval_data) 
			{
				let [amount, unit] = interval_data.split(' ');
				this.end_date(moment(start_date).add(Number(amount), unit).format('YYYY-MM-DD'));
			} 
		} 
	}

	btn_select_kpi_click (kpi)
	{
		this.search_string('');
		this.selected_kpi(kpi);
		this.kpi_name(kpi.kpi_name);
		this.kpi_id(kpi.kpi_id);
		this.kpi_nr(kpi.kpi_nr);
		this.kpi_uom(kpi.uom);
		this.bu_id(kpi.business_unit_id);
		this.bu_name(kpi.business_unit_name);
		this.bu_nr(kpi.bu_nr);
		this.bu_type(kpi.business_unit_type);
		this.reading_date(moment().format('YYYY-MM-DD'));
		window.localStorage.removeItem('kpi_data');
	}	

	async btn_save_kpi_click ()
	{
		if (this.selected_kpi() || this.kpi_data())
		{
			if (!this.start_date())
			{
				Grape.alerts.alert({type: 'warning', title: 'Could not save', message: 'Please populate the start date.'});
				window.document.getElementById('start-date').classList.add('ps-invalid-input');
			}
			else if (!this.value())
			{
				Grape.alerts.alert({type: 'warning', title: 'Could not save', message: 'Please insert a value.'});
				window.document.getElementById('value').classList.add('ps-invalid-input');
			}
			else 
			{
				let data = {
					kpi_value_id: this.kpi_value_id(),
					kpi: this.kpi_name(),
					business_unit_id: this.bu_id(),
					project_name: this.selected_project(),
					reading_value: parseInt(this.value()),
					kpi_value_status: this.selected_status(),
					reading_date: this.reading_date(),
					reading_uom: this.selected_uom(),
					confirmed: 'false',
					reading_note: JSON.stringify(this.notes()),
					cumulative: this.cumulative(),
					time_period_id: this.selected_time_period()
				}

				if(this.end_date() && !this.end_date() == '')
					data.reporting_period = '[' + this.start_date() + ', ' + this.end_date() + ')';
				else
				{
					let start = new Date(this.start_date());
					start.setMonth(start.getMonth() + 1);
					
					data.reporting_period = '[' + this.start_date() + ', ' + start.toISOString().split('T')[0] + ')';
				}
			
				let result = await Grape.fetches.postJSON('/api/kpi/kpi-value', data);
				if (result.status != 'ERROR') 
				{
					Grape.alerts.alert({type: 'success', title: 'Success', message: `New value for ${this.kpi_name()} captured on ${this.captured_date()}.`});
					this.clear_inputs();
					window.localStorage.removeItem('kpi_data');
				} 
				else 
				{
					Grape.alerts.alert({type: 'warning', title:'Could not save', message: result.code});
					throw new Error(result.message || result.code);
				}
			}
		}
		else
			Grape.alerts.alert({type: 'warning', title:'Could not save', message: 'Please select a Indicator.'})
	}

	async btn_cancel_kpi_save_click ()
	{
		let result = await Grape.alerts.confirm(
		{
			type: 'warning',
			message: 'Are you sure you want to cancel this Indicator Capture?',
			title: 'Cancel Capture',
		});

		if (result) 
		{
			window.localStorage.removeItem('kpi_data');
			window.location.reload();
		}
	}

	clear_kpi_value_filter_click ()
	{
		this.clear_inputs();
		localStorage.removeItem('kpi_data');
	}

	clear_inputs ()
	{
		this.selected_kpi('');
		this.search_string('');
		this.start_date('');
		this.end_date('');
		this.value('');
		this.notes('');
		this.selected_status('');
		this.selected_project('');
		this.supported_docs('');
		this.kpi_nr('___');
		this.kpi_name('');
		this.kpi_uom('___');
		this.bu_name('___');
		this.bu_nr('___');
		this.bu_type('___');
	}
}

class KPICaptureDataPage
{

	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPICaptureData(this);
		this.timer = null;

		this.viewModel.kpi_data(JSON.parse(window.localStorage.getItem('kpi_data') || null));

		// Removes kpi_data when navigating away from the page (not tabs)
		document.addEventListener("click", (event) => {
			if(event.target.tagName === 'A')
				localStorage.removeItem('kpi_data');
		});

		let end_date = window.document.getElementById('end-date');
		end_date.addEventListener('input', function()
		{
			if (this.value.length > 0)
				this.classList.remove('ps-invalid-input');
		});

		let start_date = window.document.getElementById('start-date');
		start_date.addEventListener('input', function()
		{
			if (this.value.length > 0)
				this.classList.remove('ps-invalid-input');
		});

		let value = window.document.getElementById('value');
		value.addEventListener('input', function()
		{
			if (this.value.length > 0)
				this.classList.remove('ps-invalid-input');
		});
	}

	async init ()
	{
		document.title = 'Capture Indicator';

		let permission = await window.user_permission(['admin', 'Capture ESG Indicator Page']);
		if (permission)
			this.viewModel.can_capture(permission);

		await this.updateData();
		this.viewModel.is_loading(true);
		if (this.viewModel.kpi_data() != null)
		{
			if (this.viewModel.project_options().some(project => project.name === this.viewModel.kpi_data().project))
				this.viewModel.selected_project(this.viewModel.kpi_data().project);

			if (this.viewModel.uom_options().some(uom => uom.name === this.viewModel.kpi_data().reading_uom))
				this.viewModel.selected_uom(this.viewModel.kpi_data().reading_uom);
			else if (this.viewModel.uom_options().some(uom => uom.symbol_html === this.viewModel.kpi_data().reading_uom))
			{
				// Find the uom with the matching symbol_html
				let matching_uom = this.viewModel.uom_options().find(uom => uom.symbol_html === this.viewModel.kpi_data().reading_uom);

				if (matching_uom)
					this.viewModel.selected_uom(matching_uom.name);
			}

			if (this.viewModel.time_period_options().some(x => x.lookup_value_id === this.viewModel.kpi_data().time_period_type_id))
				this.viewModel.selected_time_period(this.viewModel.kpi_data().time_period_type_id);
			
			this.viewModel.selected_kpi(this.viewModel.kpi_data());
			this.viewModel.kpi_name(this.viewModel.kpi_data().kpi);
			this.viewModel.kpi_id(this.viewModel.kpi_data().kpi_id);
			this.viewModel.kpi_nr(this.viewModel.kpi_data().kpi_nr);
			this.viewModel.bu_nr(this.viewModel.kpi_data().business_unit_nr);
			this.viewModel.bu_type(this.viewModel.kpi_data().business_unit_type);
			this.viewModel.bu_id(this.viewModel.kpi_data().kpi_business_unit_id);
			this.viewModel.kpi_uom(this.viewModel.kpi_data().reading_uom || this.viewModel.default_uom());
			this.viewModel.default_uom(this.viewModel.kpi_data().uom || this.viewModel.default_uom());
			this.viewModel.bu_name(this.viewModel.kpi_data().business_unit);
			this.viewModel.kpi_value_id(this.viewModel.kpi_data().kpi_value_id);

			this.viewModel.value(this.viewModel.kpi_data().reading_value || null);
			this.viewModel.notes(JSON.parse(this.viewModel.kpi_data().reading_note) || null);
			this.viewModel.start_date(this.viewModel.kpi_data().reporting_period_start || null);
			this.viewModel.end_date(this.viewModel.kpi_data().reporting_period_end || null);
			this.viewModel.reading_date(this.viewModel.kpi_data().reading_date || null);
			this.viewModel.captured_date(this.viewModel.kpi_data().captured_date || null);
			this.viewModel.cumulative(this.viewModel.kpi_data().cumulative);

			if (this.viewModel.cumulative() === false)
				this.viewModel.snapshot(true);
		}
		this.viewModel.is_loading(false);
	}

	async updateData ()
	{
		//Get list of KPI BUs
		let options_kpi_bu = {
			table: 'v_kpi_business_units',
			schema: 'kpi',
			filter: []
		}

		if (this.viewModel.search_string() && this.viewModel.search_string().trim() != '')
		{
			options_kpi_bu.filter.push({
				field: 'kpi_name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_string()}%`
			});

			let result = await Grape.fetches.getJSON('/api/record', options_kpi_bu);
			if (result.status != "ERROR")
				this.viewModel.kpis(result.records);
			else 
				throw new Error(result.message || result.code);
		}
		else
			this.viewModel.kpis([]);
		
		//Get list of projects
		let options_projects = {
			table: 'v_projects',
			schema: 'kpi',
			fields: ['project_id', 'name'],
			filter: []
		}

		let result = await Grape.fetches.getJSON('/api/record', options_projects);
		if (result.status != 'ERROR')
			this.viewModel.project_options(result.records);
			
		else 
			throw new Error (result.message || result.code)

		//GET field lookup values 
		// Time period:
		try {
			let TimePeriodTypeLookup = await Grape.cache.fetch('TimePeriodTypeLookup');
			this.viewModel.time_period_options(TimePeriodTypeLookup);
			this.viewModel.time_period_map = {};
			for (let i = 0; i < TimePeriodTypeLookup.length; i++) {
				let record = TimePeriodTypeLookup[i];
				this.viewModel.time_period_map[record.lookup_value_id] = record.data.interval;
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while loading flag names:', error);
		}

		try {
			let result = await Grape.cache.fetch('KPIValueStatusLookup');
				this.viewModel.value_status_options(result);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		let uom_data = await Grape.cache.get('UoM');
		this.viewModel.uom_options(uom_data);
		let default_uom = this.viewModel.kpi_uom();
		let uom_exists = uom_data.some(uom => uom.name === default_uom);

		if (uom_exists)
			this.viewModel.selected_uom(default_uom);
	

		if (this.viewModel.kpi_data() != null)
		{
			// Get Log File
			let options_log = {
				table: 'v_kpi_value_audit_log',
				schema: 'kpi',
				offset: 0,
				filter_join: 'AND',
				join: 'OR',
				filter: [{
					field: 'kpi_value_id',
					operand: '=',
					value: this.viewModel.kpi_data().kpi_value_id
				}]}

			try {
				let result = await Grape.fetches.getJSON('/api/record', options_log);
				if (result.status != 'ERROR')
					this.viewModel.log_file(result.records);
			} catch (error) {
				console.error(error);
			}
		}
	}
}

export default {
	route: '[/]kpi/capture-data',
	page_class: KPICaptureDataPage,
	template: template
}
