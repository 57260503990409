import template from './EditBU.html';

class EditBUDialogVM
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.bu_data = ko.observableArray([]);
		this.business_unit_id = ko.observable(null);
		this.business_units = ko.observableArray([]);
		this.view = ko.observable(false);
		this.edit = ko.observable(false);
		this.buname = ko.observable();
		this.bu_nr = ko.observable();
		this.bu_description = ko.observable();
		this.groups = ko.observableArray();
		this.bu_grouping = ko.observable();
		this.types = ko.observableArray([]);
		this.bu_type = ko.observable();
		this.bu_notes = ko.observable();
		this.is_active = ko.observable(true);
		this.parent_bu_id = ko.observable();
		this.remove_parent = ko.observable(false);
		this.bu_idx = ko.observable();
		this.bu_type_value = ko.observable();
		this.bu_type_id = ko.observable();
		this.flag_options = ko.observableArray();
		this.selected_flag = ko.observableArray();
		this.custom_fields = ko.observableArray();
		this.values = ko.observable();

		this.selected_flag.subscribe((value) => {
			if (this.selected_flag() === undefined)
				this.selected_flag(null);
		});

		this.parent_bu_selected = ko.computed(() => {
			return this.parent_bu_id();
		});

		this.bu_type.subscribe((val) => {
			this.dialog.get_custom_fields();
		});
	}

	async btn_back_click ()
	{
		let response = await Grape.alerts.confirm({ message: 'Are you sure you want to close this dialog?', type: 'warning' });
		if (response)
			this.dialog.close();
	}

	async btn_create_click ()
	{
		if (!this.buname())
		{
			window.document.getElementById('bu-name').classList.add('ps-invalid-input');
			Grape.alerts.alert({ type: 'warning', title: 'Could not save', message: "Business unit's name missing" });
			return;
		}

		if (!this.bu_type())
		{
			window.document.getElementById('bu-type').classList.add('ps-invalid-input');
			Grape.alerts.alert({ type: 'warning', title: 'Could not save', message: "Business unit's type missing" });
			return;
		}

		if (!this.bu_nr())
		{
			window.document.getElementById('bu-nr').classList.add('ps-invalid-input');
			Grape.alerts.alert({ type: 'warning', title: 'Could not save', message: "Business unit's number missing" });
			return;
		}

		let result = {
			parent_business_unit_id: this.parent_bu_id(),
			business_unit_type: this.bu_type(),
			idx: this.bu_idx(),
			name: this.buname(),
			description: this.bu_description(),
			note: this.bu_notes(),
			flag_names: this.selected_flag(),
			bu_nr: this.bu_nr(),
			values: {}
		}

		let values = {};
		this.custom_fields().forEach(x => {
			let table = x.category;
			x.fields.forEach(field => {
				if (!values[table])
					values[table] = {};
				
				values[table][field.field_name] = field.value();
			});
		});

		result.values = values;

		this.bu_data.push(result);

		let response = await fetch('/api/kpi/business-unit', {
			method: 'POST',
			headers: {
				'Content-Type' : 'application/json'
			},
			body: JSON.stringify(result)
		});

		if (response.ok)
			Grape.alerts.alert({ type: 'success', title: 'Saved', message: `${this.buname()} created successfully!`} );
		else 
			throw new Error(response.message || response.code || 'Unknown Error');

		this.dialog.close();
	}

	async btn_save_edit_click ()
	{
		if (!this.buname())
		{
			Grape.alerts.alert({ type: 'warning', title: 'Could not save', message: "Business unit's name missing"} );
			return;
		}

		if (!this.bu_type())
		{
			Grape.alerts.alert({ type: 'warning', title: 'Could not save', message: "Business unit's type missing"} );
			return;
		}

		let result = {
			name: this.buname(),
			business_unit_id: this.business_unit_id(),
			parent_business_unit_id: this.parent_bu_id(),
			business_unit_type: this.bu_type(),
			idx: this.bu_idx(),
			bu_nr: this.bu_nr(),
			description: this.bu_description(),
			note: this.bu_notes(),
			flag_names: this.selected_flag(),
			values: {}
		}

		let values = {};
		this.custom_fields().forEach(x => {
			let table = x.category;
			x.fields.forEach(field => {
				if (!values[table])
					values[table] = {};
				
				values[table][field.field_name] = field.value();
			});
		});

		result.values = values;

		if (this.remove_parent)
			console.log('isnull');
		else
			result.parent_business_unit_id = this.parent_bu_id();

		let response = await Grape.fetches.postJSON('/api/kpi/business-unit', result);

		if (response.status == 'OK')
			Grape.alerts.alert({ type: 'success', title: 'Saved', message: `${this.buname()} saved successfully!`} );
		else 
			throw new Error(response.message || response.code || 'Unknown Error');

		this.dialog.close();
	}

	async btn_unlink_parent()
	{
		let confirm = await Grape.alerts.confirm({ type: 'warning', message: 'Unlink parent business unit?', title: 'Confirm' });

		if (confirm)
		{
			this.remove_parent(true);
			this.parent_bu_id('');
		}
	}
}

class EditBUDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditBUDialogVM(this);
		this.viewModel.bu_data(bindings.bu_data || []);
		this.parentViewModel = bindings.page;
		this.name = 'EditBU';
		this.viewModel.business_units(bindings.business_units);
		this.viewModel.view(bindings.view);
		this.viewModel.edit(bindings.edit);
		this.viewModel.flag_options(bindings.flag_options);
		this.viewModel.types(bindings.types);

		if (bindings.bu_data) 
		{
			this.viewModel.business_unit_id(bindings.bu_data.business_unit_id);
			this.viewModel.bu_nr(bindings.bu_data.bu_nr);
			this.viewModel.buname(bindings.bu_data.name);
			this.viewModel.bu_type(bindings.bu_data.business_unit_type_value);
			this.viewModel.parent_bu_id(bindings.bu_data.parent_business_unit_id);
			this.viewModel.bu_idx(bindings.bu_data.idx);
			this.viewModel.bu_description(bindings.bu_data.description);
			this.viewModel.bu_notes(bindings.bu_data.note);
			this.viewModel.bu_type_value(bindings.bu_data.business_unit_type_value);
			this.viewModel.types(bindings.types);

			if (bindings.bu_data && bindings.bu_data.flag_names)
			{
				let flags = bindings.bu_data.flag_names;

				let selected_flags = this.viewModel.flag_options().filter(option =>
					flags.includes(option)
				);

				this.viewModel.selected_flag(selected_flags);
			}

			let matching_type = ko.utils.arrayFirst(this.viewModel.types(), (type) => {
				return type === bindings.bu_data.business_unit_type_value;
			});

			if (matching_type)
				this.viewModel.bu_type(matching_type);
		}

		let bu_name_input = window.document.getElementById('bu-name');
		bu_name_input.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let bu_type_input = window.document.getElementById('bu-type');
		bu_type_input.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		this.init();
	}

	async init ()
	{
		document.title = 'BU Edit/Create Dialog';
	}

	async get_custom_fields()
	{
		// get fields info
		let options = {
			table: 'v_bu_types_fields_info',
			schema: 'kpi',
			offset: 0,
			sortorder: 'ASC',
			filter: [{
				value: this.viewModel.bu_type(),
				operand: '=',
				field: 'category'
			}]
		};

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				result.records.forEach(group => {
					group.fields.forEach(field => {
						field.value = ko.observable(field.field_data.default_value || '');
					});
				});
				this.viewModel.custom_fields(result.records);
			}
			else 
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Something went wrong while fetching custom fields for this business unit type...' });
			console.error(error);
		}

		if (this.viewModel.edit())
		{
			try {
				let result = await Grape.fetches.getJSON('/api/record', {
					table: 'field_value',
					schema: 'fields',
					offset: 0,
					sortorder: 'ASC',
					filter: [{
						value: this.viewModel.business_unit_id(),
						operand: '=',
						field: 'record_id'
					}]
				});
				if (result.status != 'ERROR')
				{
					result.records.forEach(x => {
						this.viewModel.custom_fields().forEach(group => {
							group.fields.forEach(field => {
								if (field.field_id === x.field_id)
									field.value(x.value);
							});
						});
					});
				}
				else 
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Something went wrong while fetching custom fields values for this business unit...' });
				console.error(error);
			}
		}
	}
}

export default {
	name: 'EditBUDialog',
	dialog_class: EditBUDialog,
	template: template,
	provider: 'ps'
}
