import template from './Add-SDG-Project.html';

class AddSDGProjectDialogvm
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.selected_sdg_project = ko.observableArray();
		this.all_projects = ko.observableArray([]);
		this.project_id = ko.observable();
		this.dialog_title = ko.observable();
		this.all_sdgs = ko.observableArray();
		this.sdg_project_notes = ko.observable();
		this.sdg_id = ko.observable();
		this.sdg_project_to_add = ko.observableArray([]);
		this.sdg_project_to_remove = ko.observableArray([]);
		this.adding_sdg = ko.observable(true);
		this.sdg_projects = ko.observableArray();
		this.add_disabled = ko.observable(true);
		this.remove_disabled = ko.observable(true);

		this.sdg_project_to_add.subscribe((value) => {
			const valid = value.length > 0 && !value.some(item => this.selected_sdg_project().includes(item));
			this.add_disabled(!valid);
			this.remove_disabled(valid);
		});

		this.sdg_project_to_remove.subscribe((value) => {
			const valid = value.length > 0;
			this.remove_disabled(!valid);
			this.add_disabled(valid);
		});
	}

	async load ()
	{
		this.all_projects.removeAll(this.selected_sdg_project());
		this.dialog.updateData();
	}

	async btn_add_click ()
	{
		let success = true;

		for (let item of this.sdg_project_to_add())
		{
			let options;
			if (this.adding_sdg())
			{
				options = {
					sdg_id: item.sdg_id,
					project_id: this.project_id()
				};
			} 
			else if (!this.adding_sdg())
			{
				options = {
					sdg_id: this.sdg_id(),
					project_id: item.project_id
				};
			}

			try {
				let result = await fetch('/api/sdg/sdg-project-link', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(options)
				});

				if (result.ok)
				{
					success = true;
					if (!this.adding_sdg())
					{
						this.all_projects.remove(item);
						this.selected_sdg_project.push(item);
					}
					else
					{
						this.all_sdgs.remove(item);
						this.selected_sdg_project.push(item);
					}
				}
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				break;
			}
		}

		if (success)
		{
			Grape.alerts.alert({title: 'Success!', message: 'Related project(s)/SDG(s) added successfully!', type: 'success'});
			this.sdg_project_to_add([]);
			this.add_disabled(true);
			this.remove_disabled(true);
		}
	}

	async btn_remove_click()
	{
		let response = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Are you sure you want to remove the selected items?',
			title: 'Warning'
		});

		if (response)
		{
			let success = true;
			for (let item of this.sdg_project_to_remove())
			{
				let options;
				if (this.adding_sdg())
				{
					options = {
						sdg_id: item.sdg_id,
						project_id: this.project_id()
					};
				} 
				else if (!this.adding_sdg())
				{
					options = {
						sdg_id: this.sdg_id(),
						project_id: item.project_id
					};
				};

				try {
					let result = await fetch('/api/sdg/sdg-project-unlink', {
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(options)
					});

					if (result.ok)
					{
						success = true;
						this.selected_sdg_project.remove(item);
						if (!this.adding_sdg())
							this.all_projects.push(item);
						else
							this.all_sdgs.push(item);
					}
				} catch (error) {
					success = false;
					Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
					break;
				}
			}

			if (success) {
				Grape.alerts.alert({title: 'Success!', message: 'Items removed successfully!', type: 'success'});
				this.selected_sdg_project.removeAll(this.sdg_project_to_remove());
				this.sdg_project_to_remove([]);
				this.add_disabled(true);
				this.remove_disabled(true);
			}
		}
	}

	async close_click()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}

class AddSDGProjectDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new AddSDGProjectDialogvm(this);
		this.name = 'AddSDGProject';
		this.viewModel.sdg_id(bindings.sdg_id);
		this.viewModel.project_id(bindings.project_id);

		//LOGIC to check whether your adding a SDG to the Project or a Project to a SDG
		if (bindings.mode === 'adding_sdg')
		{
			this.viewModel.adding_sdg(true);
			this.viewModel.dialog_title('ADD ASSOCIATED SDG(S)');
			
		}
		else
		{
			this.viewModel.adding_sdg(false);
			this.viewModel.dialog_title('ADD ASSOCIATED PROJECTS(S)');
		}

		this.init();
	}

	async init ()
	{
		document.title = 'Add SDG Project Dialog';
		this.viewModel.load();
	}

	async updateData ()
	{		
		// GET SDG's

		if (this.viewModel.adding_sdg()) 
		{
			let options = {
				table: 'v_sdgs',
				schema: 'sdg',
				offset: 0,
				limit: 100
			}
	
			try {
				let result = await Grape.fetches.getJSON('/api/record', options);
	
				if (result.status != 'ERROR')
				{
					let img_paths = result.records.map(sdg => ({
						...sdg,
						img_src: `./SDG_icons/Goal-${String(sdg.sdg_nr).padStart(2, '0')}.png`
					}));
					this.viewModel.all_sdgs(img_paths);
				}
				else 
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message});
				console.error(error);
			}
		}

		// Get SDG Projects
		let options_sp = {
			table: 'v_sdg_projects',
			schema: 'sdg',
			offset: 0,
			limit: 1000,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'sdg_id',
				operand: '=',
				value: this.viewModel.sdg_id()
			}]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options_sp);
			if (result.status != 'ERROR')
			{
				this.viewModel.sdg_projects(result.records);
				this.viewModel.selected_sdg_project(result.records);
			}
		} catch (error)
		{
			console.error(error);
		}

		//GET Projects
		if (!this.viewModel.adding_sdg())
		{
			let options = {
				table: 'dv_projects',
				schema: 'kpi',
				offset: 0,
				limit: 1000,
			}

			try {
				let result = await Grape.fetches.getJSON('/api/record', options);
				if (result.status === 'OK')
				{
					let associated_projects = this.viewModel.sdg_projects().map(p => p.project_id);
					let linked = [];
					let remaining = [];

					result.records.forEach(project => {
						if (associated_projects.includes(project.project_id))
							linked.push(project);
						else
							remaining.push(project);
					});

					this.viewModel.selected_sdg_project(linked);
					this.viewModel.all_projects(remaining);
					
				}
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error('Error fetching project data:', error);
			}
		}
	}
}

export default {
	name: 'AddSDGProject',
	dialog_class: AddSDGProjectDialog,
	template: template,
	provider: 'ps'
}
