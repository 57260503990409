import template from './bu-overview.html';
//import KPIViewModel from '../../../lib/KPIViewModel.js';

class BUOverviewVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		document.title = 'Business Unit Overview'
		this.selected_bu = ko.observable({});

		this.flag_options = ko.observableArray();
		this.business_units = ko.observableArray([]);

		// permissions
		this.can_add = ko.observable(false);
		this.can_edit = ko.observable(false);
		this.can_delete = ko.observable(false);

		// pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(15);
		this.page_count = ko.observable(1);
		
		//search
		this.search_string = ko.observable('');
		
		//TODO: FIX SORT TO NOT BREAK HIERARCHY OF BU TREE
		//this.sort_field = ko.observable('bu_nr');
		//this.sort_order = ko.observable('ASC');
		
		//filtering
		this.display_inactive = ko.observable(false);
		this.bu_types = ko.observableArray([]);
		this.bu_flags = ko.observableArray([]);
		this.selected_bu_type = ko.observableArray([]);
		this.selected_bu_flag = ko.observableArray([]);

		this.display_inactive.subscribe((value) => {
			this.current_page_number(1);
			this.page.updateData();
		});

		this.selected_bu_flag.subscribe((value) => {
			this.current_page_number(1);
			this.page.updateData();
		});

		this.selected_bu_type.subscribe((value) => {
			this.current_page_number(1);
			this.page.updateData();
		});

		this.search_string.subscribe((value) => {
			this.current_page_number(1);
			this.page.updateData();
		});
	}

	//TODO: FIX SORT TO NOT BREAK HIERARCHY OF BU TREE
	/*sort_column (column)
	{
		if (this.sort_field() === column)
		{
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		} else {
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.page.updateData();
	}*/

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	//Viewing a BU when clicking on a row
	async btn_view_bu_click (data)
	{
		this.selected_bu(ko.toJS(data));
		await Grape.dialog.open('EditBUDialog', {bu_data: data, business_units: this.business_units(), view: true, types: this.bu_types(), flag_options: this.flag_options()});
		this.page.updateData();
	}

	async btn_edit_bu_click (bu_data)
	{
		await Grape.dialog.open('EditBUDialog', {bu_data: bu_data, business_units: this.business_units(), edit: true, types: this.bu_types(), flag_options: this.flag_options()});
		this.page.updateData();
	}

	async btn_add_new_bu_click ()
	{
		await Grape.dialog.open('EditBUDialog', {business_units: this.business_units(), flag_options: this.flag_options(), types: this.bu_types()});
		this.page.updateData();
	}

	async btn_delete_bu_click (data)
	{
		let confirmed = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Please note that deleting a Business unit will remove all relevant data of the Business unit including captured values, baseline targets, parent business units, etc.',
			title: 'Warning'
		});

		if (confirmed)
		{
			let result = await Grape.alerts.confirm({
				type: 'danger',
				message: 'Are you sure you want to delete this Business unit? (this cannot be undone)',
				title: 'Warning'
			});

			if (result)
			{
				let response = await fetch('/api/kpi/business-unit', {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ business_unit_id: data.business_unit_id })
				});
				
				if (response.ok)
					console.log('Deleted successfully')
				else
					throw new Error(response.message || response.code || 'Unknown Error');
	
				this.page.updateData();	
			}
		}
	}
}

class BUOverviewPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new BUOverviewVM(this);
		this.timer = null;
		this.onload = true;
		let search_input = document.getElementById('search-input');
		search_input.addEventListener('keyup', (event) => {
			if (event.key == 'Enter')
				this.updateData();
		});
	}

	async init ()
	{
		document.title = 'Business Unit Overview';

		let permission_add = await window.user_permission(['Setup Business Unit', 'admin']);
		if (permission_add)
			this.viewModel.can_add(true);

		let permission_edit = await window.user_permission(['admin', 'Setup Business Unit', 'Edit Business Unit']);
		if (permission_edit)
			this.viewModel.can_edit(true);

		try {
			let result = await Grape.cache.fetch('BusinessUnitTypeLookup');
			{
				let bu_types = [];
				for (let i = 0; i < result.length; i++)
					bu_types.push(result[i].name);
				this.viewModel.bu_types(bu_types);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		try {
			let result = await Grape.cache.fetch('BusinessUnitFlagLookup');
			{
				let flag_names = [];
				for (let i = 0; i < result.length; i++)
					flag_names.push(result[i].name);
				this.viewModel.flag_options(flag_names);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
	
	async updateData ()
	{
		//Get list of business units
		let options = {
			table: 'v_business_units',
			schema: 'kpi',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',

			//TODO: FIX SORT TO NOT BREAK HIERARCHY OF BU TREE
			//sortorder: this.viewModel.sort_order(),
			//sortfield: this.viewModel.sort_field(),
			filter: []
		}

		//LOGIC: filter
		if (this.viewModel.selected_bu_type().length > 0) 
		{
			options.filter.push({
				field: 'business_unit_type_value',
				operand: 'IN',
				value: this.viewModel.selected_bu_type()
			});
		}

		//Filter on flags
		if (this.viewModel.selected_bu_flag().length > 0)
		{
			options.filter.push({
				field: 'flag_names',
				operand: '@>',
				value: this.viewModel.selected_bu_flag()
			});
		}

		//LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '')
		{
			options.filter.push({
				field: 'name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_string()}%`
			})
		}

		//LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				let split = result.records.map(bu => ({
					...bu,
					flag_names: bu.flag_names ? bu.flag_names.join(', ') : null
				}));
		
				this.viewModel.business_units(split);
				this.viewModel.page_count(Math.floor(result.total/result.limit)+1);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]bu/overview',
	page_class: BUOverviewPage,
	template: template
}
