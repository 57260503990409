import template from './bu-details.html';
import BUViewModel from '../../../lib/BUViewModel.js';

class BUDetailsVM
{
	constructor (page)
	{
		this.page = page;
		this.budetailsvm = new BUViewModel(page.bindings.bu_id || null);
		this.bu_id = ko.observable();
		this.bu_details = ko.observableArray();
		this.bu_data = ko.observableArray();
		this.business_units = ko.observableArray();
		this.types = ko.observableArray();
		this.flag_options = ko.observableArray();

		// permissions
		this.can_edit = ko.observable(false);
	}

	async btn_edit_bu_click ()
	{
		this.bu_data(this.bu_details()[0]);
		await Grape.dialog.open('EditBUDialog', {bu_data: this.bu_data(), business_units: this.business_units(), types: this.types(), flag_options: this.flag_options()});
		this.page.updateData();
	}

	async load ()
	{
		return await this.budetailsvm.load();
	}
}

class BUDetailsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new BUDetailsVM(this);
		this.timer = null;
		this.viewModel.bu_id(bindings.bu_id);
	}

	async init ()
	{
		document.title = 'Business Unit Details';

		let permission = await window.user_permission(['admin', 'Setup Business Unit', 'Edit Business Unit']);
		if (permission)
			this.viewModel.can_edit(true);

		this.viewModel.load();
	}

	async updateData ()
	{
		try {
			let result = await Grape.cache.fetch('BusinessUnitTypeLookup');
			{
				let types = [];
				for (let i = 0; i < result.length; i++)
					types.push(result[i].name);
				this.viewModel.types(types);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		}

		// get business units
		let bu_options = {
			table: 'v_business_units',
			schema: 'kpi',
			offset: 0,
			sortorder: 'ASC',
			sortfield: 'bu_nr'
		};
	
		try {
			let result = await Grape.fetches.getJSON('/api/record', bu_options);
			if (result.status != 'ERROR')
				this.viewModel.business_units(result.records);
			else 
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		let options = {
			table: 'v_business_units',
			schema: 'kpi',
			offset: 0,
			sortorder: 'ASC',
			sortfield: 'bu_nr',
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'business_unit_id',
				operand: '=',
				value: this.viewModel.bu_id()
			}]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				let split = result.records.map(bu => ({
					...bu,
					flag_names: bu.flag_names ? bu.flag_names.join(', ') : null
				}));
		
				this.viewModel.bu_details(split);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
		try {
			// get BusinessUnitFlag
			let BusinessUnitFlag = await Grape.cache.fetch('BusinessUnitFlagLookup');
			let flag_names = [];
					for (let i = 0; i < BusinessUnitFlag.length; i++)
						flag_names.push(BusinessUnitFlag[i].name);
			this.viewModel.flag_options(flag_names);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while loading flag names:', error);
		}
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]bu/details',
	page_class: BUDetailsPage,
	template: template
}
