import template from './sdg.html';
import SDGViewModel from '../../../lib/SDGViewModel';

class SDGVM
{
	constructor (page)
	{
		document.title = 'SDG';
		this.page = page;
		this.sdgvm = new SDGViewModel(page.bindings.sdg_id || null);
		this.sdg_id = ko.observable();
		this.current_page_title = ko.observable('');
		this.current_route = ko.observable();
		this.search_string = ko.observable();
		this.all_sdgs = ko.observableArray();
		this.sdgs = ko.observableArray();
		this.selected_sdg = ko.observable();
		this.selected_page = ko.computed(() => {
			return window.AppState.selected_page();
		});

		this.search_string.subscribe((value) => {
			this.sdg_id(value.sdg_id);
			this.page.updateData();
		});

		if (page.bindings.from === 'overview')
		{
			window.AppState.selected_page('UN SDGs');
		}

		this.tabs = ko.observableArray([{
			route: '[/]frameworks/sdg/sdg-overview',
			title: 'Back',
			heading_text: 'Overview',
			icon: 'fa-thin fa-chevron-left'
		}, {
			route: '[/]sdg/detail',
			title: 'SDG Detail & Setup',
			heading_text: 'Detail & Setup',
			icon: 'fa-thin fa-location-pen'
		}, {
			route: '[/]sdg/measures',
			title: 'SDG Measures',
			heading_text: 'Measures',
			icon: 'fa-thin fa-chart-mixed-up-circle-currency'
		}]);

		this.current_route.subscribe((newPageId) => {
			let matchingTab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === newPageId;
			});
			this.current_page_title(matchingTab ? matchingTab.heading_text : '');
		}, this);
	}

	async load()
	{
		return await this.sdgvm.load();
	}

	async load_tab (route)
	{
		if (route === '[/]frameworks/sdg/sdg-overview')
			window.Grape.navigate(route);
		else
		{
			window.localStorage.setItem('last_sdg_page', route);
			this.current_route(route);
			const element = this.page.element.querySelector('#page_content');
			const page = Grape.pages.find_page_by_id(route);
			if (!page)
				throw new Error(`Page with id '${route}' not found`);

			await Grape.pages.load_page(page, element, {sdg_id: this.sdgvm.sdg_id()}, {from: 'other'}, () => {});
		}
	}
}

class SDGPage
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new SDGVM(this);
	}

	async init()
	{
		document.title = 'SDG';

		if (this.bindings.sdg_id) {
			this.viewModel.sdgvm.sdg_id(this.bindings.sdg_id); // Set the SDG ID
			await this.viewModel.sdgvm.load(); // Load the SDG details
		}
		if (this.bindings.from === 'overview')
		{
			this.viewModel.load_tab('[/]sdg/detail');
			this.bindings.from = null;
		} 
		else if (this.bindings.from === undefined || this.bindings.from === null || this.bindings.from === '')
			this.viewModel.load_tab('[/]sdg/overview');

		if (this.bindings.sdg_id)
		{
			window.localStorage.setItem('last_visited_sdg_id', this.bindings.sdg_id);
			this.viewModel.sdgvm.sdg_id(this.bindings.sdg_id);
			this.viewModel.load();
		}
		else if (window.localStorage.getItem('last_visited_sdg_id'))
		{
			let last_sdg_id = window.localStorage.getItem('last_visited_sdg_id');
			this.viewModel.sdgvm.sdg_id(last_sdg_id);
			this.viewModel.load()
		}

		this.viewModel.load();

		if (this.bindings.page_id)
			this.viewModel.load_tab(this.bindings.page_id);
		else if (window.localStorage.getItem('last_sdg_page'))
			this.viewModel.load_tab(window.localStorage.getItem('last_sdg_page'));
		else
			this.viewModel.load_tab('[/]sdg/overview');

		// Get SDG info for bindings
		try {
			let sdgs = await Grape.cache.fetch('SDGs');
			this.viewModel.all_sdgs(sdgs);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while getting SDG data:', error);
		}
	}

	async updateData()
	{
		if (this.bindings.sdg_id && this.bindings.sdg_id != this.viewModel.sdgvm.sdg_id())
			this.viewModel.sdgvm.sdg_id(this.bindings.sdg_id);
		
		// Get SDG info for searching
		let options = {
			table: 'v_sdgs',
			schema: 'sdg',
			filter_join: 'OR',
			filter: []
		}

		if (this.viewModel.search_string() && this.viewModel.search_string().trim() != '')
		{
			options.filter.push({
				field: 'sdg_name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_string()}%`
			});

			options.filter.push({
				field: 'sdg_nr',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_string()}%`
			});

			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				let sdgs_with_img_path = result.records.map(sdg => ({
					...sdg,
					img_path: `./SDG_icons/Goal-${String(sdg.sdg_nr).padStart(2, '0')}.png`
				}));
				this.viewModel.sdgs(sdgs_with_img_path);
			}
				
			else 
				throw new Error(result.message || result.code);
		}
		else
			this.viewModel.sdgs([]);
	}
}

export default {
	route: '[/]frameworks/sdg/:sdg_id',
	page_class: SDGPage,
	template: template
}
