import template from './Add-KPI-BU.html';

class AddKPIBUDialogvm
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.selected_kpi_bu = ko.observableArray();
		this.business_units = ko.observableArray([]);
		this.business_unit_id = ko.observable();
		this.dialog_title = ko.observable();
		this.kpis = ko.observableArray();
		this.kpi_bu_notes = ko.observable();
		this.kpi_id = ko.observable();
		this.kpi_bu_to_add = ko.observableArray([]);
		this.kpi_bu_to_remove = ko.observableArray([]);
		this.adding_bu = ko.observable(true);
		this.kpi_business_units = ko.observableArray();
		this.data_to_add = ko.observableArray();
		this.already_added_kpi_bu = ko.observableArray();
		this.add_disabled = ko.observable(true);
		this.remove_disabled = ko.observable(true);

		this.kpi_bu_to_add.subscribe((value) => {
			const valid = value.length > 0 && !value.some(item => this.selected_kpi_bu().includes(item));
			this.add_disabled(!valid);
			this.remove_disabled(valid);
		});

		this.kpi_bu_to_remove.subscribe((value) => {
			const valid = value.length > 0;
			this.remove_disabled(!valid);
			this.add_disabled(valid);
		});
	}

	async load ()
	{
		this.business_units.removeAll(this.selected_kpi_bu());
		//this.already_added_kpi_bu(this.selected_kpi_bu().slice());
		this.dialog.updateData();
	}

	async btn_add_click ()
	{
		this.selected_kpi_bu.push(...this.kpi_bu_to_add());
		this.data_to_add.push(...this.kpi_bu_to_add());
		this.kpis.removeAll(this.kpi_bu_to_add())
		this.business_units.removeAll(this.kpi_bu_to_add());
		this.kpi_bu_to_add([]);

		let success = true;
		if (this.adding_bu())
		{
			for (let bu of this.data_to_add())
			{
				let result = {
					kpi_id: this.kpi_id(),
					business_unit_id: bu.business_unit_id,
					note: this.kpi_bu_notes()
				}
			
				let response = await fetch('/api/kpi/kpi-business-unit', {
					method: 'POST',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(result)
				});

				if (!response.ok)
					success = false;
			}  
		}
		else if (!this.adding_bu())
		{
			for (let kpi of this.data_to_add())
			{
				let result = {
					kpi_id: kpi.kpi_id,
					business_unit_id: this.business_unit_id(),
					note: this.kpi_bu_notes()
				}
		
				let response = await fetch('/api/kpi/kpi-business-unit', {
					method: 'POST',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(result)
				});

				if (!response.ok)
					success = false;
			}
		}
		if (success)
		{
			Grape.alerts.alert({title: 'Success!', message: 'Related business unit(s)/indicator(s) added successfully!', type: 'success'});
			this.data_to_add.removeAll();
			this.add_disabled(true);
			this.remove_disabled(true);
		}
	}

	async btn_remove_click()
	{
		let response = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Are you sure you want to remove the selected items? This will delete ALL the previously captured values relating to these Indicator & Business Unit combinations!',
			title: 'Warning'
		});

		if (response)
		{
			let success = true;

			for (let item of this.kpi_bu_to_remove())
			{
				let options;
				if (this.adding_bu())
				{
					options = {
						business_unit_id: item.business_unit_id,
						kpi_id: this.kpi_id()
					};
				} 
				else if (!this.adding_bu())
				{
					options = {
						business_unit_id: this.business_unit_id(),
						kpi_id: item.kpi_id
					};
				}

				try {
					let result = await fetch('/api/kpi/kpi-business-unit', {
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(options)
					});

					if (result.ok)
					{
						success = true;
						this.selected_kpi_bu.remove(item);
						if (this.adding_bu())
							this.business_units.push(item);
						else
							this.kpis.push(item);
					}
				} catch (error) {
					Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
					break;
				}
			}

			if (success) {
				Grape.alerts.alert({title: 'Success!', message: 'Items removed successfully!', type: 'success'});
				this.selected_kpi_bu.removeAll(this.kpi_bu_to_remove());
				this.kpi_bu_to_remove([]);
				this.add_disabled(true);
				this.remove_disabled(true);
			}
		}
	}

	async close_click()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}

class AddKPIBUDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new AddKPIBUDialogvm(this);
		this.name = 'AddKPIBU';
		this.viewModel.kpi_id(bindings.kpi_id);
		this.viewModel.business_unit_id(bindings.business_unit_id);
		this.viewModel.kpi_business_units(bindings.kpi_business_units);
		this.viewModel.selected_kpi_bu(bindings.kpi_business_units);

		//LOGIC to check whether your adding a BU to the KPI or a KPI to a BU
		if (bindings.kpi_id)
		{
			this.viewModel.adding_bu(true);
			this.viewModel.dialog_title('ADD/REMOVE BUSINESS UNIT(S)');
			
		}
		else if (bindings.business_unit_id) 
		{
			this.viewModel.adding_bu(false);
			this.viewModel.dialog_title('ADD/REMOVE INDICATOR(S)');
		}

		this.init();
	}

	async init ()
	{
		document.title = 'Add KPI BU Dialog';
		this.viewModel.load();
	}

	async updateData ()
	{
		//GET Associated business units for this KPI
		let options = {
			table: 'v_kpi_business_units',
			schema: 'kpi',
			offset: 0,
			limit: 2000,
			filter_join: 'AND',
			join: 'OR',
			filter: []
		}
	
		if (this.viewModel.adding_bu()) 
		{
			options.filter.push({
				field: 'kpi_id',
				operand: '=',
				value: this.viewModel.kpi_id()
			});

			try {
				let result = await Grape.fetches.getJSON('/api/record', options);
				if (result.status != 'ERROR')
				{
					this.viewModel.selected_kpi_bu(result.records);
					this.viewModel.kpi_business_units(result.records);
				}
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error)
			}

			//GET business units
			let options_bu = {
				table: 'v_business_units',
				schema: 'kpi',
				offset: 0,
				sortorder: 'ASC',
				limit: 2000,
				sortfield: 'bu_nr',
				filter_join: 'AND',
				join: 'OR',
				filter: []
			}

			try {
				let result = await Grape.fetches.getJSON('/api/record', options_bu);
				if (result.status != 'ERROR')
				{
					let associatedBU_nrs = this.viewModel.kpi_business_units().map(record => record.bu_nr);
					this.viewModel.selected_kpi_bu(result.records.filter(bu => associatedBU_nrs.includes(bu.bu_nr)));
					let filtered_units = result.records.filter(bu => !associatedBU_nrs.includes(bu.bu_nr)); 
					this.viewModel.business_units(filtered_units);
				}
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				//Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error)
			}
		}

		if (!this.viewModel.adding_bu())
		{
			options.filter.push({
				field: 'business_unit_id',
				operand: '=',
				value: this.viewModel.business_unit_id()
			});
			
			try {
				let result = await Grape.fetches.getJSON('/api/record', options);
				if (result.status != 'ERROR')
				{
					this.viewModel.selected_kpi_bu(result.records);
					this.viewModel.kpi_business_units(result.records);
				}
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error)
			}

			//GET KPIs
			let options_kpi = {
				table: 'dv_kpis',
				schema: 'kpi',
				limit: 2000,
				fields: ['name', 'kpi_id', 'uom', 'kpi_nr'],
				filter: []
			}

			let result = await Grape.fetches.getJSON('/api/record', options_kpi);
			if (result.status != 'ERROR')
			{ 
				let associated_kpis = this.viewModel.kpi_business_units().map(record => record.kpi_nr);
				this.viewModel.selected_kpi_bu(result.records.filter(bu => associated_kpis.includes(bu.kpi_nr)));
				let filtered_kpis = result.records.filter(bu => !associated_kpis.includes(bu.kpi_nr));
				this.viewModel.kpis(filtered_kpis); 
			}
			else 
				throw new Error(result.message || result.code);
		}
	}
}

export default {
	name: 'AddKPIBU',
	dialog_class: AddKPIBUDialog,
	template: template,
	provider: 'ps'
}
