import template from './ViewLogFile.html';

class LogFileDialogvm
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.dialog_title = ko.observable();
		this.kpi_value_id = ko.observable();
		this.log_file = ko.observableArray();
	}

	async load ()
	{
		this.dialog.updateData();
	}

	async close_click()
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: '', message: 'Are you sure you want to close this dialog?'});
		if (response)
			this.dialog.close(false);
	}
}

class LogFileDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new LogFileDialogvm(this);
		this.name = 'LogFile';
		this.viewModel.dialog_title(bindings.kpi_info.kpi);
		this.viewModel.kpi_value_id(bindings.kpi_info.kpi_value_id);

		this.init();
	}

	async init ()
	{
		this.viewModel.load();
	}

	async updateData ()
	{
		// Get Log File
		let options_log = {
			table: 'v_kpi_value_audit_log',
			schema: 'kpi',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'kpi_value_id',
				operand: '=',
				value: this.viewModel.kpi_value_id()
			}]}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options_log);
			if (result.status != 'ERROR')
				this.viewModel.log_file(result.records);
		} catch (error) {
			console.log(error);
		}
	}
}

export default {
	name: 'LogFile',
	dialog_class: LogFileDialog,
	template: template,
	provider: 'ps'
}

