import template from './edit-role-dialog.html';

class EditRoleViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.mode = ko.observable();
		this.features_edit = ko.observable(true);
		this.role_edit = ko.observable(false);
		this.role_data = ko.observableArray([]);
		this.name = ko.observable();
		this.kpi_role_id = ko.observable();
		this.description = ko.observable();
		this.feature_names = ko.observableArray([{selected_feature: ko.observable()}]);
		this.all_features = ko.observable();
		this.selected_feature = ko.observableArray([]);
		this.initial_name = ko.observable();
		this.initial_description = ko.observable();
		this.available_features = ko.observableArray([]);
		this.linked_features = ko.observableArray([]);
		this.selected_linked_feature = ko.observable();
	}

	toggleEdit() {
		this.role_edit(!this.role_edit());
		this.features_edit(!this.features_edit());
	}

	async btn_add_feature_click (data)
	{
		let features_add = this.selected_feature();
		if (features_add && features_add.length > 0) 
		{
			for (let feature_to_add of features_add) 
			{
				this.linked_features.push(feature_to_add);
				this.available_features.remove(feature_to_add);

				let options;
				if (this.mode() === 'edit') 
				{
					options = {
						name: this.name(),
						feature_id: feature_to_add.feature_id,
						kpi_role_id: this.kpi_role_id(),
						description: this.description()
					}
				} 
				else if (this.mode() === 'new')
				{
					options = {
						name: this.name(),
						feature_id: feature_to_add.feature_id,
						description: this.description()
					}
				}

				try {
					let response = await Grape.fetches.postJSON('api/kpi/kpi-role', options);
					if (response.status != 'ERROR')
						feature_to_add.kpi_role_feature_id = response.kpi_role_feature_id;
					else
						throw new Error(response.message || response.code);
				} catch (error) {
					Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
					break;
				}
			}
			await Grape.alerts.alert({type: 'success', title: 'Added', message: 'Features added successfully'});
			this.selected_feature([]);
		}
	}

	async btn_remove_feature_click (data)
	{
		let features_remove = this.selected_feature();
		if (features_remove && features_remove.length > 0)
		{
			for (let feature_to_remove of features_remove)
			{
				this.available_features.push(feature_to_remove);
				this.linked_features.remove(feature_to_remove);

				let options = {
					feature_id: feature_to_remove.feature_id,
					kpi_role_id: this.kpi_role_id()
				};

				try {
					let response = await fetch('api/kpi/kpi-role', {
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(options)
					});

					if (response.ok)
					{
						let result = await response.json();
						if (result.status !== 'ERROR')
							console.log('removed features successfully');
						else
							throw new Error(result.message || result.code);
					} 
					else 
					{
						console.error(error);
						let error = await response.json();
						throw new Error(error.message || "Unknown error occurred");
					}
				} catch (error) {
					console.error(error);
					Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
					break;
				}
			}
			await Grape.alerts.alert({ type: 'success', title: 'Removed', message: 'Features removed successfully' });
			this.selected_feature([]);
		}
	}

	async btn_save_kpi_role ()
	{
		let options;
		let has_changes = this.name() !== this.initial_name() || this.description() !== this.initial_description();

		if (this.mode() === 'edit')
		{
			options = {
				name: this.name(),
				kpi_role_id: this.kpi_role_id(),
				description: this.description()
			};
		}
		else if (this.mode() === 'new')
		{
			options = {
				name: this.name(),
				description: this.description()
			};
		}
	
		try {
			let response = await Grape.fetches.postJSON('api/kpi/kpi-role', options);
			if (response.status != 'ERROR')
			{
				if (has_changes)
					await Grape.alerts.alert({type: 'success', title: 'Saved', message: 'Name and description saved successfully'});
				this.toggleEdit();
				this.initial_name(this.name());
				this.initial_description(this.description());
			}
			else
				throw new Error(response.message || response.code);
		} catch (error) {
		  Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
		}
	}

	async btn_cancel_click ()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}

class EditRolePage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditRoleViewModel(this);
		this.viewModel.mode(bindings.mode);
		this.viewModel.all_features(bindings.features);
		this.viewModel.available_features(bindings.features.slice());

		if (this.viewModel.mode() === 'new')
		{
			this.viewModel.role_edit(true);
			this.viewModel.features_edit(false);
		} 
		else if (this.viewModel.mode() === 'edit')
		{
			this.viewModel.role_edit(false);
			this.viewModel.features_edit(true);
		}

		if (bindings.role_data)
		{
			this.viewModel.initial_name(bindings.role_data.role_name);
			this.viewModel.initial_description(bindings.role_data.description);
			this.viewModel.name(bindings.role_data.role_name);
			this.viewModel.description(bindings.role_data.description);
			this.viewModel.kpi_role_id(bindings.role_data.kpi_role_id);
			
			bindings.role_data.features.forEach(feature => {
				let match = this.viewModel.available_features().find(f => f.feature_id === feature.feature_id);
				if(match)
				{
					this.viewModel.linked_features.push(match);
					this.viewModel.available_features.remove(match);
				}
			});
		}

		this.init();
	}

	init ()
	{
		document.title = 'Add/Edit Role';
		this.updateData();
	}

	async updateData ()
	{
	}
}

export default {
	name: 'EditRoleDialog',
	dialog_class: EditRolePage,
	template: template,
	provider: 'ps'
}
