import template from './dashboard-projects.html';

class DashboardProjectsPageViewModel
{
	constructor (page)
	{
		this.page = page;

		this.fraction_green = ko.observable('0/0');
		this.fraction_amber = ko.observable('0/0');
		this.fraction_red = ko.observable('0/0');

		this.percentage_green = ko.observable('0');
		this.percentage_amber = ko.observable('0');
		this.percentage_red = ko.observable('0');
		this.view_table = ko.observable('grid');

		this.project_targets = ko.observableArray([]);
		this.total_records = ko.observableArray([]);

		this.grid_data = ko.observableArray([]);
		this.comments_data = ko.observableArray([]);
		this.project_data = ko.observableArray([]);

		this.current_page_number1 = ko.observable(1);
		this.current_page_size1 = ko.observable(5);
		this.page_count1 = ko.observable(1);

		//search
		this.search_project_string = ko.observable('');

		this.search_project_string.subscribe((value) => {
			this.page.load_project_targets();
		});
	}

	async btn_refresh_dashboard_click ()
	{
		Grape.alerts.alert({ type: 'info', message: 'Refreshing the dashboard. The page will automatically refresh when successful.' });
		let response = await Grape.fetches.getJSON('/api/project/mv/refresh', {});
		if (response.status == 'OK')
			location.reload();
		else
			Grape.alerts.alert({ type: 'error', title: 'Error', message: response.message});
	}

	page_click_project (page_number)
	{
		this.current_page_number1(page_number);
		this.page.load_project_targets();
	}

	btn_view_grid_data_click (data)
	{
		this.view_table('grid');
	}

	btn_view_comments_data_click (data)
	{
		this.view_table('comments');
	}

	btn_view_monthly_target (data)
	{
		this.page.get_project_details(data);
	}
}

class DashboardProjectsPage
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new DashboardProjectsPageViewModel(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Projects Dashboard';
		this.load_project_targets();
	}

	async updateData ()
	{
		let total_options = {
			table: 'mv_project_targets_status_totals',
			schema: 'kpi',
			limit: 1
		};
		
		try {
			let result = await Grape.fetches.getJSON('/api/record', total_options);
			if (result.status !== 'ERROR' && result.records.length)
			{
				let totals = result.records[0];

				this.viewModel.total_records(totals.total_records);
				this.viewModel.fraction_green(totals.total_green + '/' + totals.total_records);
				this.viewModel.fraction_red(totals.total_red + '/' + totals.total_records);
				this.viewModel.fraction_amber(totals.total_amber + '/' + totals.total_records);

				this.viewModel.percentage_green(totals.percentage_green);
				this.viewModel.percentage_red(totals.percentage_red);
				this.viewModel.percentage_amber(totals.percentage_amber);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async load_project_targets ()
	{
		let options = {
			table: 'mv_project_targets_status',
			schema: 'kpi',
			offset: (this.viewModel.current_page_number1() - 1) * this.viewModel.current_page_size1(),
			limit: this.viewModel.current_page_size1(),
			filter: []
		}

		//LOGIC: Pagination
		if(this.viewModel.current_page_number1() && this.viewModel.current_page_size1())
		{
			options.limit = this.viewModel.current_page_size1();
			options.offset = (this.viewModel.current_page_number1()-1) * this.viewModel.current_page_size1();
		}

		//LOGIC: Search
		if (this.viewModel.search_project_string() && this.viewModel.search_project_string().trim() != '')
		{
			options.filter.push({
				field: 'project_name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_project_string()}%`
			});
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status !== 'ERROR')
			{
				this.viewModel.project_targets(result.records);
				this.viewModel.project_targets(result.records.map(record => ({
				...record,
				measure_notes: record.measure_notes != null ? record.measure_notes.slice().reverse().slice(0, 10) : '',
				progress_notes: record.progress_notes != null ? record.progress_notes.slice().reverse().slice(0, 10) : ''
				})));
				this.viewModel.page_count1(Math.floor(result.total/result.limit)+1);
			}
		}
		catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async get_project_details (row_data)
	{
		let options = {
			table: 'project_status',
			schema: 'kpi',
			fields: ['project_stage', 'completion', 'overall_status', 'updated_date', 'progress_notes', 'reading_note'],
			filter: [{
				field: 'project_id',
				operand: '=',
				value: row_data.project_id
			}, {
				field: 'overall_status',
				operand: '!=',
				value: null
			}],
			sortfield: 'project_status_id',
			sortorder: 'DESC'
		}

		try {
			let data = await Grape.fetches.getJSON('/api/record', options);

			if (data.status != 'ERROR')
			{
				this.viewModel.project_data(data.records);

				await Grape.dialog.open('ProjectTargetsMonthly', {data: row_data, project_data: this.viewModel.project_data()});
			}
			else
			{
				Grape.alerts.alert({ type: 'error', message: 'Could not load project data', title: 'Error' });
				console.error(data);
			}
		}
		catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]dashboard/dashboard-projects',
	page_id: 'dashboard-projects',
	page_class: DashboardProjectsPage,
	template: template
}
