/**
 * Cache for SDG Target Indicators
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'SDGTargetIndicators',
	options: {
		tablename: 'v_sdg_target_indicators',
		schema: 'sdg',
		filter: []
	}
};
