
// COMPONENTS
import navbar from './navbar/navbar.js';
import back_to_top from './back-to-top/back-to-top.js';
import dataQualityCheck from './data-checks/data-quality-check.js';
import missingDataCheck from './data-checks/missing-data-check.js';

export default [
	navbar,
	back_to_top,
	dataQualityCheck,
	missingDataCheck
];
