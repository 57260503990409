import template from './projects-details-sidebar.html';
import ProjectViewModel from '../../../lib/ProjectViewModel';

class ProjectDetailsSidebarVM
{
	constructor (page)
	{
		this.page = page;
		this.projectvm = new ProjectViewModel(page.bindings.project_id || null );
		this.current_page_title = ko.observable('');

		// permissions
		this.add_project = ko.observable(false);
		this.view_users_tab = ko.observable(false);
		this.view_progress_tab = ko.observable(false);
		
		this.selected_page = ko.computed(() => {
			return window.AppState.selected_page();
		});

		if (page.bindings.from === 'overview')
		{
			window.AppState.selected_page('Detail');
			localStorage.setItem('selected_navbar_page', 'Detail');
		}

		this.tabs = ko.observableArray([{
			route: '[/]projects/projectdetail',
			title: 'Details',
			heading_text: 'Details',
			icon: 'fa-thin fa-memo-circle-info',
			visible: true
		}, {
			route: '[/]projects/related-BU',
			title: 'Business Unit(s) & Indicator(s)',
			heading_text: 'Associated Business Unit(s) & Indicator(s)',
			icon: 'fa-thin fa-info-circle',
			visible: true
		}, {
			route: '[/]projects/project-target',
			title: 'Target Value(s)',
			heading_text: 'Target Value(s)',
			icon: 'fa-thin fa-bullseye',
			visible: true
		},{
			route: '[/]projects/project-progress',
			title: 'Progress',
			heading_text: 'Progress',
			icon: 'fa-thin fa-bars-progress',
			visible: this.view_progress_tab()
		},{
			route: '[/]projects/project-users',
			title: 'Users',
			heading_text: 'Users',
			icon: 'fa-thin fa-users',
			visible: this.view_users_tab()
		}]);

		this.current_route = ko.observable();
		this.current_route.subscribe((new_route) => {
			let matching_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === new_route;
			});
			this.current_page_title(matching_tab ? matching_tab.heading_text : '');
		}, this);

		// Re-evaluate the users tab visibility
		this.view_users_tab.subscribe(val => {
			let users_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === '[/]projects/project-users';
			});

			if (users_tab)
			{
				users_tab.visible = val;
				this.tabs.valueHasMutated();
			}
		});

		// Re-evaluate the progress tab visibility
		this.view_progress_tab.subscribe(val => {
			let progress_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === '[/]projects/project-progress';
			});

			if (progress_tab)
			{
				progress_tab.visible = val;
				this.tabs.valueHasMutated();
			}
		});

		// Handle the visible tabs (used in html)
		this.visible_tabs = ko.computed(() => {
			return ko.utils.arrayFilter(this.tabs(), (tab) => {	
				return tab.visible === true;
			});
		});
	}
	async btn_add_project_click ()
	{
		Grape.dialog.open('EditProject', {projectData: {}, title: 'New Project'}).then(() => {
			this.page.updateData();
		})
			.catch((error) => {
				console.error('Error adding Project:', error);
			});
	}
	async load()
	{
		return await this.projectvm.load();
	}

	load_tab (route)
	{
		window.localStorage.setItem('last_details_projectsidebar_page', route);
		let element = this.page.element.querySelector('#page_content');
		this.current_route(route);
		let page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id '${route}' not found`);
		Grape.pages.load_page(page, element, {project_id: this.projectvm.project_id()}, {}, () => {});
	}
}

class ProjectDetailsSidebarPage
{
	constructor (bindings, element, page)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ProjectDetailsSidebarVM(this);
	}
	async init ()
	{
		document.title = 'project details';

		// permissions
		let permission_add = await window.user_permission(['admin', 'Setup Project']);
		if (permission_add)
			this.viewModel.add_project(permission_add);

		let view_progress_permission = await window.user_permission(['admin', 'Project progress status and history', 'Update Project progress', 'Approve Update on Project progress']);
		if (view_progress_permission)
			this.viewModel.view_progress_tab(true);

		let view_users_permission = await window.user_permission(['admin', 'View Project Users Page']);
		if (view_users_permission)
				this.viewModel.view_users_tab(view_users_permission);

		if (this.bindings.project_id) {
			window.localStorage.setItem('last_visited_project_id', this.bindings.project_id);
			this.viewModel.projectvm.project_id(this.bindings.project_id);
			this.viewModel.load();
		} else if (window.localStorage.getItem('last_visited_project_id')) {
			let last_project_id = window.localStorage.getItem('last_visited_project_id');
			this.viewModel.projectvm.project_id(last_project_id);
			this.viewModel.load()
		} else
			throw new Error('Page not found (no linked Project)');

		this.viewModel.load_tab('[/]projects/projectdetail');
	}

	async updateData()
	{
		if (this.bindings.project_id && this.bindings.project_id != this.viewModel.projectvm.project_id())
		{
			this.viewModel.projectvm.project_id(this.bindings.project_id);
			this.viewModel.projectvm.name(this.bindings.name);
			this.viewModel.load();
		}
	}
}

export default {
	route: '[/]projects/projects-details',
	page_class: ProjectDetailsSidebarPage,
	template: template
}
