import template from './kpi-bu.html';
import KPIViewModel from '../../../lib/KPIViewModel.js';

ko.bindingHandlers.formattedDate = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());
		var formattedDate = new Date(value).toLocaleDateString();
		element.innerText = formattedDate;
	},
};

class KPIBUVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.kpivm = new KPIViewModel(page.bindings.kpi_id || null);
		this.kpi_business_units = ko.observableArray([]);
		this.kpi_id = ko.observable(page.bindings.kpi_id);
		this.kpis = ko.observableArray();
		this.kpi_nr = ko.observable(null);
		this.kpi_name = ko.observable();
		this.uom_options = ko.observableArray();
		this.uom = ko.observable();
		this.baseline_per_bu = ko.observableArray();
		this.mode = ko.observable('from_kpi');
		this.time_period_options = ko.observableArray();

		// permissions
		this.edit_related = ko.observable(false);

		//Pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.page_count = ko.observable(1);

		this.current_page_number_target = ko.observable(1);
		this.current_page_size_target = ko.observable(10);
		this.page_count_target = ko.observable(1);
	}

	page_click(page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	page_click_target(page_number)
	{
		this.current_page_number_target(page_number);
		this.page.updateData();
	}

	async btn_edit_baseline_target_click (data)
	{
		this.mode('edit_from_kpi');
		await Grape.dialog.open('BaselinePerBU', {baseline_data: data, mode: this.mode(), kpi_business_units: this.kpi_business_units(), uom_options: this.uom_options(), period: this.time_period_options(), kpi_id: this.kpi_id()});
		this.page.updateData();
	}

	async btn_remove_target_click (data)
	{
		let alert = await Grape.alerts.confirm({type: 'warning', title: 'Confirm', message: 'Are you sure you want to delete this baseline & target?'})
		if (alert)
		{
			let options = {target_id: data.target_id}

			let response = await fetch('/api/kpi/kpi-target', {
				method: 'DELETE',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify(options)
			});

			if (response.ok)
				Grape.alerts.alert({type: 'success', title: 'Success', message: `Target for ${data.kpi_name} deleted successfully!`})
			else
				throw new Error(response.message || response.code || 'Unkown Error');

			this.page.updateData();
		}
	}

	async btn_view_monthly_target (data)
	{
		await Grape.dialog.open('KPIBUTargetsmonthly', {data: data, kpi_business_units: this.kpi_business_units()});
	}

	async btn_add_bu_click ()
	{
		await Grape.dialog.open('AddKPIBU', {kpi_id: this.kpi_id(), kpi_business_units: this.kpi_business_units()})
		this.page.updateData();
	}

	async btn_add_baseline_click ()
	{
		this.mode('from_kpi');
		if (this.kpi_business_units().length === 0)
		{
			await Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Please note, to save a Baseline & Target, you need to have related business units.'});
			await Grape.dialog.open('BaselinePerBU', { kpi_name: this.kpi_name(), kpi_id: this.kpi_id(), kpi_nr: this.kpi_nr(), uom: this.uom(), kpi_business_units: this.kpi_business_units(), mode: this.mode(), uom_options: this.uom_options(), period: this.time_period_options()});
		}
		else
			await Grape.dialog.open('BaselinePerBU', { kpi_name: this.kpi_name(), kpi_id: this.kpi_id(), kpi_nr: this.kpi_nr(), uom: this.uom(), kpi_business_units: this.kpi_business_units(), mode: this.mode(), uom_options: this.uom_options(), period: this.time_period_options()});
		this.page.updateData();
	}

	async btn_remove_bu_click (data)
	{
		let response = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Are you sure you want to remove this Business Unit? This will delete ALL the previously captured values relating to this Indicator & Business Unit combination!',
			title: 'Warning',
		}); 

		if (response) 
		{
			let result = await Grape.alerts.confirm({
				type: 'warning',
				message: 'Are you sure? This cannot be undone!',
				title: 'Warning',
			});
		
			if (result)
			{
				let options = {
					business_unit_id: data.business_unit_id,
					kpi_id: data.kpi_id
				}
				
				let response = await fetch('/api/kpi/kpi-business-unit', {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(options)
				});

				if (response.ok)
					console.log('Deleted successfully')
				else 
					throw new Error(response.message || response.code || 'Unknown Error');

				this.page.updateData();
			}
		}
	}

	async btn_edit_bu_click (data)
	{
		await Grape.dialog.open('EditKPIBU', {kpi_id: data.kpi_id, business_unit_id: data.business_unit_id, note: data.note, kpi_business_unit_id: data.kpi_business_unit_id});
		this.page.updateData();
	}

	async load()
	{
		return await this.kpivm.load();
	}
}

class KPIBUPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPIBUVM(this);
		this.timer = null;
		this.viewModel.kpi_name(bindings.kpi_name);
	}

	init ()
	{
		document.title = 'Indicator Business Units';
		this.viewModel.load();
	}

	async updateData ()
	{
		let permission_bu = await window.user_permission(['Setup ESG Indicator', 'admin', 'Setup associated BU Indicators']);
		if (permission_bu)
			this.viewModel.edit_related(permission_bu);

		//Get this KPI details
		let option = {
			table: 'dv_kpis',
			schema: 'kpi',
			limit: 1000,
			fields: ['name', 'kpi_id', 'uom', 'kpi_nr'],
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'kpi_id',
				operand: '=',
				value: this.viewModel.kpi_id()
			}]
		}

		let result = await Grape.fetches.getJSON('/api/record', option);
		if (result.status != 'ERROR')
		{
			this.viewModel.kpis(result.records);
			this.viewModel.kpi_nr(result.records[0].kpi_nr);
			this.viewModel.uom(result.records[0].uom);
			this.viewModel.kpi_name(result.records[0].name);
		} 
		else 
			throw new Error(result.message || result.code);
		
		//GET Associated business units for this KPI
		let options = {
			table: 'v_kpi_business_units',
			schema: 'kpi',
			offset: (this.viewModel.current_page_number() - 1) * this.viewModel.current_page_size(),
			filter_join: 'AND',
			sortorder: 'ASC',
			sortfield: 'business_unit_name',
			join: 'OR',
			filter: [{
				field: 'kpi_id',
				operand: '=',
				value: this.viewModel.kpi_id()
			}]
		}

		//LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				this.viewModel.kpi_business_units(result.records);
				this.viewModel.page_count(Math.floor(result.total/result.limit)+1);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}

		//GET Baseline Target per Business Unit
		let options_baselines = {
			table: 'v_kpi_bu_targets',
			schema: 'kpi',
			offset: (this.viewModel.current_page_number_target() - 1) * this.viewModel.current_page_size_target(),
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'kpi_id',
				operand: '=',
				value: this.viewModel.kpi_id()
			}]
		}

		//LOGIC: Pagination
		if (this.viewModel.current_page_number_target() && this.viewModel.current_page_size_target())
		{
			options_baselines.limit = this.viewModel.current_page_size_target();
			options_baselines.offset = (this.viewModel.current_page_number_target()-1) * this.viewModel.current_page_size_target();
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options_baselines);
			if (result.status != 'ERROR')
			{
				this.viewModel.baseline_per_bu(result.records);
				this.viewModel.page_count_target(Math.floor(result.total/result.limit)+1);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}

		//GET field lookup values 
		// Time period:
		try {
			let TimePeriodTypeLookup = await Grape.cache.fetch('TimePeriodTypeLookup');
			this.viewModel.time_period_options(TimePeriodTypeLookup);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while loading time period types:', error);
		}

		//Get list of uom values
		let uom = {
			table: 'v_units',
			schema: 'uom',
			filter: []
		}

		let uom_data = await Grape.fetches.getJSON('/api/record', uom);
		if (uom_data.status != 'ERROR')
			this.viewModel.uom_options(uom_data.records);
		else 
			throw new Error (uom_data.message || uom_data.code)
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]kpi/bu-related',
	page_class: KPIBUPage,
	template: template
}
