import template from './project-capture-sidebar.html';
import ProjectViewModel from '../../../lib/ProjectViewModel';

class ProjectCaptureSidebarVM
{
	constructor (page)
	{
		this.page = page;
		this.projectvm = new ProjectViewModel(page.bindings.project_id || null );

		//permissions
		this.view_update_tab = ko.observable(false);
		this.view_approve_tab = ko.observable(false)
		this.view_history_tab = ko.observable(false);

		this.selected_page = ko.computed(() => {
			return window.AppState.selected_page();
		});
		
		this.tabs = ko.observableArray([{
			route: '[/]projects/capture-project',
			title: 'Update',
			icon: 'fa-thin fa-pen-to-square',
			visible: this.view_update_tab()
		}, {
			route: '[/]projects/approve-project',
			title: 'Approve',
			icon: 'fa-thin fa-check-to-slot',
			visible: this.view_approve_tab()
		},{
			route: '[/]projects/project-history',
			title: 'History',
			icon: 'fa-thin fa-file-chart-pie',
			visible: this.view_history_tab()
		}]);

		this.current_route = ko.observable();

		// Re-evaluate the update tab visibility
		this.view_update_tab.subscribe(val => {
			let update_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === '[/]projects/capture-project';
			});

			if (update_tab)
			{
				update_tab.visible = val;
				this.tabs.valueHasMutated();
			}
		});

		// Re-evaluate the approve tab visibility
		this.view_approve_tab.subscribe(val => {
			let approve_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === '[/]projects/approve-project';
			});

			if (approve_tab)
			{
				approve_tab.visible = val;
				this.tabs.valueHasMutated();
			}
		});

		// Re-evaluate the history tab visibility
		this.view_history_tab.subscribe(val => {
			let history_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === '[/]projects/project-history';
			});

			if (history_tab)
			{
				history_tab.visible = val;
				this.tabs.valueHasMutated();
			}
		});

		// Handle the visible tabs (used in html)
		this.visible_tabs = ko.computed(() => {
			return ko.utils.arrayFilter(this.tabs(), (tab) => {	
				return tab.visible === true;
			});
		});
	}

	async load()
	{
		return await this.projectvm.load();
	}

	load_tab (route)
	{
		window.localStorage.setItem('last_capture_projectsidebar_page', route);
		const element = this.page.element.querySelector('#page_content');
		this.current_route(route);

		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id "${route}" not found`);

		Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class ProjectCaptureSidebarPage
{
	constructor (bindings, element, page)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ProjectCaptureSidebarVM(this);
	}

	async init ()
	{
		document.title = 'capture projects';

		let view_update_permission = await window.user_permission(['admin', 'Update Project progress']);
		if (view_update_permission)
			this.viewModel.view_update_tab(true);

		let view_approve_permission = await window.user_permission(['admin', 'Approve Update on Project progress']);
		if (view_approve_permission)
			this.viewModel.view_approve_tab(true);

		let view_history_permission = await window.user_permission(['admin', 'Project progress status and history']);
		if (view_history_permission)
			this.viewModel.view_history_tab(true);

		if (this.bindings.project_id)
		{
			window.localStorage.setItem('last_visited_project_id', this.bindings.project_id);
			this.viewModel.projectvm.project_id(this.bindings.project_id);
			this.viewModel.load();
		}
		else if (window.localStorage.getItem('last_visited_project_id'))
		{
			let last_project_id = window.localStorage.getItem('last_visited_project_id');
			this.viewModel.projectvm.project_id(last_project_id);
			this.viewModel.load();
		}
		else
			throw new Error('Page not found (no linked Project)');
			this.viewModel.load_tab('[/]projects/capture-project');
	}

	async updateData()
	{
	}
}

export default {
	route: '[/]projects/projects-Update',
	page_class: ProjectCaptureSidebarPage,
	template: template
}
