/**
 * Cache for all KPIs
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'KPIS',
	options: {
		tablename: 'v_kpis',
		schema: 'kpi',
		fields: ['name', 'kpi_id', 'kpi_nr']
	}
};