/**
 * Cache for all Business unit users
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'BUUser',
	options: {
		tablename: 'v_business_unit_users',
		schema: 'kpi'
	}
};