import template from './related-bu-kpi.html';
import ProjectViewModel from '../../../../lib/ProjectViewModel.js';

class RelatedBuKPIVM
{
	constructor (page)
	{
		document.title = 'Associated Business Units & Indicators'
		this.loaded = ko.observable(false);
		this.page = page;
		this.projectvm = new ProjectViewModel(page.bindings.project_id || null )
		this.current_page_id = ko.observable();
		this.current_page_title = ko.observable('');
		this.project_id = ko.observable();
		this.kpi_projects = ko.observableArray();
		this.bu_projects = ko.observableArray();

		// permissions
		this.edit_related = ko.observable(false);
	}

	async btn_add_related_bu_click ()
	{
		await Grape.dialog.open('AddBUProject', {project_id: this.project_id(), bu_projects: this.bu_projects()});
		this.page.updateData();
	}

	async btn_edit_bu_click (data)
	{
		await Grape.dialog.open('EditKPIBU', {mode: 'edit_project_bu', business_unit_id: data.business_unit_id, note: data.bu_project_note, project_business_id: data.project_business_id, project_id: data.project_id});
		this.page.updateData();
	}

	async btn_edit_kpi_click (data)
	{
		await Grape.dialog.open('EditKPIBU', {mode: 'edit_project_kpi', note: data.kpi_project_note, project_kpi_id: data.project_kpi_id, kpi_id: data.kpi_id, project_id: data.project_id});
		this.page.updateData();
	}

	async btn_add_related_kpi_click ()
	{
		await Grape.dialog.open('AddKpiProject', {project_id: this.project_id(), kpi_projects: this.kpi_projects()});
		this.page.updateData();
	}

	async btn_remove_bu_click (data)
	{
		let result = await Grape.alerts.confirm({
			type: 'warning',
			message: `Are you sure you want to unlink ${data.business_unit_name}?`,
			title: 'warning'
		});

		if (result)
		{
			let options = {
				project_id: data.project_id,
				business_unit_id: data.business_unit_id
			}

			let response = await fetch('/api/kpi/business-unit-project-unlink', {
				method: 'DELETE',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify(options)
			});

			if (response.ok)
			{
				Grape.alerts.alert({
					type: 'success',
					title: 'Success',
					message: `${data.business_unit_name} unlinked successfully`
				});
			} else
				throw new Error(response.message || response.code || 'Unknown Error');
			this.page.updateData();
		}
	}

	async btn_remove_kpi_click (data)
	{
		let result = await Grape.alerts.confirm({
			type: 'warning',
			message: `Are you sure you want to unlink ${data.kpi_name}?`,
			title: 'warning'
		});

		if (result)
		{
			let options = {
				project_id: data.project_id,
				kpi_id: data.kpi_id
			}

			let response = await fetch('/api/kpi/project-unlink', {
				method: 'DELETE',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify(options)
			});

			if (response.ok)
			{
				Grape.alerts.alert({
					type: 'success',
					title: 'Success',
					message: `${data.kpi_name} unlinked successfully`
				});
			} else
				throw new Error(response.message || response.code || 'Unknown Error');
			this.page.updateData();
		}
	}
}

class RelatedBuKPIPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new RelatedBuKPIVM(this);
		this.timer = null;
		this.viewModel.project_id(bindings.project_id);
	}

	async init ()
	{
		document.title = 'Project Business Unit & Indicator';

		let permission = await window.user_permission(['admin', 'Setup associated BUs and ESG Indicators']);
		if (permission)
			this.viewModel.edit_related(permission);
	}

	async updateData ()
	{
		//Get Related KPI Projects
		let options = {
			table: 'v_kpi_projects',
			schema: 'kpi',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'project_id',
				operand: '=',
				value: this.viewModel.project_id()
			}]
		}

		let result = await Grape.fetches.getJSON('/api/record', options);

		if (result.status != 'ERROR')
		{
			this.viewModel.kpi_projects(result.records);
		}
		else
			throw new Error(result.message || result.code);

		//Get Related Business units projects
		let options_bu = {
			table: 'v_business_unit_projects',
			schema: 'kpi',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'project_id',
				operand: '=',
				value: this.viewModel.project_id()
			}]
		}

		let result_bu = await Grape.fetches.getJSON('/api/record', options_bu);
		if (result_bu.status != 'ERROR')
		{
			this.viewModel.bu_projects(result_bu.records);
		}
		else
			throw new Error(result_bu.message || result_bu.code);
	}

	add_relatedBU ()
	{
		Grape.dialog.open('EditProjectRelatedBU',)
		.then(() => {
		})
		.catch((error) => {
			console.error('Error adding Project:', error);
		});
	}
}

export default {
	route: '[/]projects/related-BU',
	page_class: RelatedBuKPIPage,
	template: template
}
