import template from './kpi-view-approve.html';

ko.bindingHandlers.formattedDate = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());
		var formattedDate = new Date(value).toLocaleDateString();
		element.innerText = formattedDate;
	},
};

ko.bindingHandlers.formattedDateRange = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());

		if (typeof value === 'string') {
			var dateRangeMatch = value.match(/\[(.*?),(.*?)\)/);

			if (dateRangeMatch) {
				var startDate = new Date(dateRangeMatch[1]);
				var endDate = new Date(dateRangeMatch[2]);

				var formattedStartDate = startDate.toLocaleDateString();
				var formattedEndDate = endDate.toLocaleDateString();

				element.innerText = `(${formattedStartDate} - ${formattedEndDate})`;
				return;
			}
		}
		element.innerText = value;
	},
};

ko.bindingHandlers.note_text = {
	update: function(element, valueAccessor) {
		let value = ko.unwrap(valueAccessor());
		if (value === null || typeof value === 'undefined') {
			element.textContent = '';
		} else {
			let cleaned_note = value.replace(/["]+/g, '');
			element.textContent = cleaned_note;
		}
	}
};

class KPIViewDataVM
{
	constructor (page)
	{
		this.page = page;
		this.kpi_values = ko.observableArray();
		this.kpis = ko.observableArray();
		this.kpi_id = ko.observable();
		this.kpi_uom = ko.observable();
		this.kpi_nr = ko.observable();
		this.search_string = ko.observable('');
		this.selected_kpi = ko.observable();
		this.selected_kpi_name = ko.observable();
		this.draft_kpi_values = ko.observableArray([]);
		//this.current_page_id = ko.observable();
		this.kpi_super_users = ko.observable();

		// permissions
		this.permission_to_approve = ko.observable(false);
		this.permission_to_drafts = ko.observable(false);
		this.permission_to_edit_draft = ko.observable(false);

		this.search_string.subscribe((value) => {
			this.page.updateData();
			this.page.updateDraft();
		});

		this.selected_kpi.subscribe((value) => {
			this.page.updateData();
			this.page.updateDraft();
		});
	}

	format_reporting_period(period) {
		let dates = period.slice(1, -1).split(',');
		return dates[0].replace(/-/g, '/') + ' - ' + dates[1].replace(/-/g, '/');
	}

	btn_select_kpi_click (kpi)
	{
		this.selected_kpi_name(kpi.name);
		this.search_string('');
		this.selected_kpi(kpi);
		this.kpi_id(kpi.kpi_id);
		this.kpi_nr(kpi.kpi_nr);
		this.kpi_uom(kpi.uom);
	}

	btn_view_draft_capture_click (data)
	{
		let page_id = '[/]kpi/capture-data';
		window.localStorage.setItem('kpi_data', JSON.stringify(data));
		window.localStorage.setItem('last_kpicapturesidebar_page', page_id);
		window.location.reload();
	}

	async btn_confirm_kpi_value_click (data)
	{
		let confirm = await Grape.alerts.confirm({type: 'success', title: 'Confirm', message: 'Are you sure you want to confirm this Indicator captured value?'});
		if (confirm)
		{
			let options = {
				kpi_value_id: data.kpi_value_id,
				confirmed: true,
				confirmed_date: moment().format('YYYY-MM-DD')
			}
	
			let result = await Grape.fetches.postJSON('/api/kpi/kpi-value-confirm', options);
			if (result.status != 'ERROR')
			{
				this.page.updateData();

				let email_options = {
					username: data.user_captured,
					kpi: data.kpi,
					kpi_nr: data.kpi_nr,
					kpi_value_status: data.kpi_value_status,
					project: data.project,
					business_unit: data.business_unit,
					value: data.reading_value,
					note: data.reading_note
				}
		
				let email_result = await fetch('/api/kpi-value/approve', {
					method: 'POST',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify(email_options)
				});
		
				if (email_result.status != 'ERROR')
					Grape.alerts.alert({type: 'success', title: 'Confirmed', message: 'Captured value successfully confirmed, an email has been sent to the creator.'});
				
				else 
				{
					Grape.alerts.alert({type: 'success', title: 'Confirmed', message: 'Captured value successfully confirmed.'});
					Grape.alerts.alert({type: 'warning', title: 'Could not send email', message: (email_result.message || email_result.code)});
					throw new Error(email_result.message || email_result.code);
				}
			} 
			else 
			{
				Grape.alerts.alert({type: 'warning', title: 'Could not save', message: 'Something went wrong...'});
				throw new Error(result.message || result.code);
			}
		}
	}

	async btn_view_log_file_click (data)
	{
		await Grape.dialog.open('LogFile', {kpi_info: data});
	}

	async btn_reject_kpi_value_click (data)
	{
		let response1 = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Do you want to move this captured value to Drafts for correction?',
			title: 'Move or delete Indicator Value?',
		});

		let options;
		let response;
		let action;

		if (response1)
		{
			response = await Grape.alerts.prompt({
				type: 'warning',
				title: 'Reason',
				message: 'Please provide a reason for moving the captured value to draft.'
			});

			if (response)
			{
				options = {
					kpi_value_id: data.kpi_value_id,
					kpi_value_status: 'Draft',
					is_deleting: false
				};

				action = ' moved to the Draft table to be modified.';
			}

		} else if (!response1)
		{
			response = await Grape.alerts.prompt({
				type: 'warning',
				title: 'Reason',
				message: 'Please provide a reason for rejection (and removing) this captured value.'
			});

			if (response)
			{
				options = {
					kpi_value_id: data.kpi_value_id
				}

				action = ' deleted.';
			}
		}

		if (response)
		{
			let result = await fetch('/api/kpi/kpi-value', {
				method: 'DELETE',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(options)
			});
	
			if (result.status != 'ERROR')
			{
				this.page.updateData();
				this.page.updateDraft();
				
				let email_options = {
					username: data.user_captured,
					kpi: data.kpi,
					kpi_nr: data.kpi_nr,
					kpi_value_status: data.kpi_value_status,
					project: data.project,
					business_unit: data.business_unit,
					value: data.reading_value,
					note: data.reading_note,
					reason: response,
					moved_or_removed: action
				}
		
				let email_result = await fetch('/api/kpi-value/reject', {
					method: 'POST',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify(email_options)
				});
		
				if (email_result.status != 'ERROR')
					Grape.alerts.alert({type: 'success', title: 'Rejected', message: 'Captured value successfully rejected, an email has been sent to the creator.'});
				
				else 
				{
					Grape.alerts.alert({type: 'success', title: 'Rejected', message: 'Captured value successfully rejected.'});
					Grape.alerts.alert({type: 'warning', title: 'Could not send email', message: (email_result.message || email_result.code)});
					throw new Error(email_result.message || email_result.code);
				}
			} 
			else 
			{
				Grape.alerts.alert({type: 'warning', title: 'Could not reject', message: (result.message || result.code)});
				throw new Error(result.message || result.code);
			}
		}
	}

	clear_kpi_value_filter_click ()
	{
		this.selected_kpi('');
		this.search_string('');
	}

	async btn_delete_kpi_value_click (data)
	{
		let result = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Are you sure you want to delete this captured value?',
			title: 'Warning',
		});

		if (result)
		{
			let options = {
				kpi_value_id: data.kpi_value_id
			}

			let response = await fetch('/api/kpi/kpi-value', {
				method: 'DELETE',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(options)
			});

			if (response.ok)
				Grape.alerts.alert({type: 'success', title: 'Success', message: 'Deleted Successfully'});
			else
				throw new Error(response.message || response.code || 'Unknown Error');
			this.page.updateData();
			this.page.updateDraft();
		}
	}
}

class KPIViewData
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPIViewDataVM(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Indicator View & Approve';

		let permission_to_approve = await window.user_permission(['admin', 'Approve Indicator Captured Values']);
		if (permission_to_approve)
			this.viewModel.permission_to_approve(permission_to_approve);

		let permission_to_drafts = await window.user_permission(['admin', 'Capture ESG Indicator Page', 'Approve Indicator Captured Values']);
		if (permission_to_drafts)
			this.viewModel.permission_to_drafts(permission_to_drafts);

		let permission_to_edit_draft = await window.user_permission(['admin', 'Capture ESG Indicator Page']);
		
		if (permission_to_edit_draft)
			this.viewModel.permission_to_edit_draft(permission_to_edit_draft);
		this.updateDraft();
	}

	async updateData ()
	{
		//Get all previous KPI VALUES to approve
		let response = {
			table: 'v_kpi_values_to_approve',
			schema: 'kpi',
			offset: 0.,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'confirmed',
				operand: '=',
				value: 'false'
			}, {
				field: 'kpi_value_status',
				operand: '!=',
				value: 'Draft'
			}]
		}

		if (this.viewModel.selected_kpi())
		{
			response.filter.push({
				field: 'kpi_id',
				operand: '=',
				value: this.viewModel.selected_kpi().kpi_id
			});
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', response);
			if (result.status != 'ERROR')
			{
				let formatted_records = result.records.map(record => {
					if (record.reporting_period)
						record.reporting_period = this.viewModel.format_reporting_period(record.reporting_period);
				
					return record;
				});
				this.viewModel.kpi_values(formatted_records);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}

		//Get KPI info
		let options = {
			table: 'dv_kpis',
			schema: 'kpi',
			fields: ['name', 'kpi_id', 'uom', 'kpi_nr', 'reporting_frequency'],
			filter: []
		}

		if (this.viewModel.search_string() && this.viewModel.search_string().trim() != '')
		{
			options.filter.push({
				field: 'name',
				operand: 'ILIKE',
				value: `%${this.viewModel.search_string()}%`
			});

			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
				this.viewModel.kpis(result.records);
			else 
				throw new Error(result.message || result.code);
		}
		else 
			this.viewModel.kpis([]);
	}

	async updateDraft() 
	{
		let options = {
			table: 'v_kpi_value_drafts',
			schema: 'kpi',
			offset: 0,
			limit: 1000
		};

		if(this.viewModel.selected_kpi()) 
		{
			options.filter.push({
				field: 'kpi_id',
				operand: '=',
				value: this.viewModel.selected_kpi().kpi_id
			});
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				let formatted_records = result.records.map(record => {
					if (record.reporting_period)
						record.reporting_period = this.viewModel.format_reporting_period(record.reporting_period);
				
					return record;
				});
				this.viewModel.draft_kpi_values(formatted_records);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}

		//Get kpi users :
		/*let user_options = {
			table: 'v_kpi_users',
			schema: 'kpi',
			offset: 0,
			limit: 3000
		}
		let super_user;
		let admin_users = []
		let res = await Grape.fetches.getJSON('/api/record', user_options);
		if (res.status != 'ERROR')
			super_user = res.records;

		// Get users with roles
		let g_users = {
			table: 'v_users',
			schema: 'grape',
			offset: 0,
			limit: 5000
		}
		
		let user_res = await Grape.fetches.getJSON('/api/record', g_users);
		if (user_res.status != 'ERROR')
		{
			admin_users = user_res.records.filter(user => 
				user.assigned_role_names && user.assigned_role_names.includes('admin')
			);
		}
		let combined_users = [...super_user, ...admin_users];
		let unique_users = [];

		combined_users.forEach(user => {
			if (!unique_users.some(u => u.user_id === user.user_id)) {
				unique_users.push(user);
			}
		});

		let permission = this.viewModel.kpi_values().some(x => 
			unique_users.some(user => user.user_id === x.current_user_id));
		
		this.viewModel.permission_for_approve(permission);*/
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]kpi/view-approve-data',
	page_class: KPIViewData,
	template: template
}
