import template from './edit-user-dialog.html';

class EditUserViewModel
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.mode = ko.observable();
		this.user_data = ko.observable();
		this.user_id = ko.observable();
		this.username = ko.observable();
		this.name = ko.observable();
		this.email = ko.observable();
		this.phone = ko.observable();
		this.department = ko.observable();
		this.designation = ko.observable();
		this.emp_number = ko.observable();

		this.username_disabled = ko.computed(() => {
			let current_mode = this.mode();
			return current_mode === "view" || current_mode === "edit";
		});	
	}

	async btn_cancel_click ()
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: '', message: 'Are you sure you want to close this dialog?'});
		if (response)
			this.dialog.close();
	}

	async btn_save_click ()
	{
		try
		{
			let user = {
				user_data: {
					"add": [{
						"user_id": this.user_id(),
						"name_and_surname": this.name(),
						"name": this.name(),
						"user_email": this.email(),
						"email": this.email(),
						"user_phone": this.phone(),
						"user_department": this.department(),
						"user_designation": this.designation(),
						"user_employee_number": this.emp_number(),
						"username": this.username()
					}]
				}
			};

			let response = await fetch('/api/catalytix/catalytix', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(user)
			});

			if (response.ok)
			{
				let result = await response.json();
				await Grape.alerts.alert({ type: 'success', title: 'Saved', message: 'Saved successfully!' });
				this.dialog.close();
			}
			else
			{
				let errorData = await response.json();
				console.error('Api Error:', errorData);
			}
		} catch (error) {
			console.error('Save Error:', error);
		}
	}
}

class EditUserPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditUserViewModel(this);
		this.viewModel.mode(bindings.mode);

		if (bindings.user_data)
		{
			this.viewModel.user_id(bindings.user_data.user_id);
			this.viewModel.name(bindings.user_data.name_and_surname);
			this.viewModel.email(bindings.user_data.email);
			this.viewModel.phone(bindings.user_data.user_phone);
			this.viewModel.department(bindings.user_data.user_department);
			this.viewModel.designation(bindings.user_data.user_designation);
			this.viewModel.emp_number(bindings.user_data.user_employee_number);
			this.viewModel.username(bindings.user_data.username);
			this.viewModel.user_data(bindings.user_data);
		}
		else
		{
			this.viewModel.user_id('');
			this.viewModel.name('');
			this.viewModel.email('');
			this.viewModel.phone('');
			this.viewModel.department('');
			this.viewModel.designation('');
			this.viewModel.emp_number('');
			this.viewModel.username('');
			this.viewModel.user_data('');
		}

		this.init();
	}

	init ()
	{
		document.title = 'Edit User';
	}
}

export default {
	name: 'EditUserDialog',
	dialog_class: EditUserPage,
	template: template,
	provider: 'ps'
}
