/**
 * Cache for Unit of Measure
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'UoM',
	options: {
		table: 'v_units',
		schema: 'uom',
		sortfield: 'idx'
	}
};

