import KPIViewModel from '../../../lib/KPIViewModel';
import template from './kpi-kpis.html';

class KPIRelatedKPIsPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.kpivm = new KPIViewModel(page.bindings.kpi_id || null);
		this.frameworks = ko.observableArray();
		this.kpi_id = ko.observable(page.bindings.kpi_id);
		this.sdg_kpis = ko.observableArray();

		// permissions
		this.add_framework = ko.observable(false);
	}

	async btn_add_framework_click()
	{
		await Grape.dialog.open('AddSDGKPI', {kpi_id: this.kpi_id(), adding_sdg: true, kpi_sdgs: this.sdg_kpis()});
		this.page.updateData();
	}

	async btn_remove_sdg_click()
	{
		// confirm unlink 		//
	}

	async load()
	{
		return await this.kpivm.load();
	}
}

class KPIRelatedKPIsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new KPIRelatedKPIsPageVM(this);
		
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Indicator Frameworks';
		this.viewModel.load();
	}

	async updateData ()
	{
		let permission = await window.user_permission(['admin', 'Setup ESG Indicator', 'Setup associated Frameworks']);
		if (permission)
			this.viewModel.add_framework(permission);
			
		let option = {
			table: 'v_sdg_kpi',
			schema: 'sdg',
			offset: 0,
			limit: 1000,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'kpi_id',
				operand: '=',
				value: this.viewModel.kpi_id()
			}]
		}

		let result = await Grape.fetches.getJSON('/api/record', option);
		if (result.status != 'ERROR')
		{
			result.records.forEach(sdg => {
				if (sdg.related_targets !== null)
				{
					sdg.related_targets.forEach(t => {
					t.show = ko.observable(false);
					});
				}
				if (sdg.related_indicators !== null)
				{
					sdg.related_indicators.forEach(i => {
						i.show = ko.observable(false);
					});
				}
			});
			
			let img_path = result.records.map(sdg => ({
				goal: sdg.goal,
				sdg_nr: sdg.sdg_nr,
				name: sdg.sdg_name,
				sdg_id: sdg.sdg_id,
				img_path: `./SDG_icons/Transparent-icons/T-Goal-${String(sdg.sdg_nr).padStart(2, '0')}.png`,
				linked_projects: sdg.linked_projects,
				related_targets: sdg.related_targets,
				related_indicators: sdg.related_indicators,
			}));
			this.viewModel.sdg_kpis(img_path);
		} 
		else
			throw new Error(result.message || result.code);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]kpi/kpi-related',
	page_class: KPIRelatedKPIsPage,
	template: template
}
