import template from './project-capture.html';
import ProjectViewModel from '../../../lib/ProjectViewModel';

class ProjectCaptureVM
{
	constructor (page)
	{
		this.page = page;
		this.projectvm = new ProjectViewModel(page.bindings.project_id || null);
		this.update_date = ko.observable(new Date().toISOString().split('T')[0]);
		this.uom_options = ko.observableArray([]);
		this.selected_uom = ko.observable();
		this.overall_status_color = ko.observable('#87a747');
		this.schedule_status_color = ko.observable('#87a747');
		this.scope_status_color = ko.observable('#87a747');
		this.costs_status_color = ko.observable('#87a747');
		this.completion = ko.observable('');
		this.set_completion = (value) => {
			this.completion(value);
		};
		this.project_stage = ko.observable();
		this.overall_status = ko.observable();
		this.schedule_status = ko.observable();
		this.scope_status = ko.observable();
		this.cost_status = ko.observable();
		this.progress_notes = ko.observable();
		this.actual_value = ko.observable('');
		this.kpi_id = ko.observable();
		this.project_id = ko.observable();
		this.baseline = ko.observable();
		this.baseline_date = ko.observable();
		this.target_date = ko.observable();
		this.nominal_target = ko.observable();
		this.uom = ko.observable();
		this.kpi_projects = ko.observableArray([]);
		this.approved_kpi_projects = ko.observableArray([]);
		this.start_date = ko.observable('');
		this.end_date = ko.observable(null);
		this.selected_time_period = ko.observable('');
		this.time_period_options = ko.observableArray([]);
		this.time_period_map = ko.observable();
		this.updated_date = ko.observable(moment().format('YYYY/MM/DD'));
		this.measurement_notes = ko.observable();
		this.cumulative = ko.observable(true);
		this.snapshot = ko.observable(false);
		this.selected_kpi_id = ko.observable('');
		this.selected_kpi = ko.observable();

		// permissions
		this.add_target = ko.observable(false);

		this.selected_kpi_id.subscribe(value => {
			if (value)
				this.selected_kpi(this.kpi_projects().find(kpi => kpi.kpi_id === value));
		});

		this.cumulative.subscribe((value) => {
			if (value === true)
				this.snapshot(false);
		});

		this.snapshot.subscribe((value) => {
			if (value === true)
			{
				this.cumulative(false);
				this.end_date('infinity');
			}
		});

		this.selected_time_period.subscribe((value) => {
			this.update_end_date();
		});

		this.start_date.subscribe((value) => {
			this.update_end_date();
			window.document.getElementById('start-date').classList.remove('ps-invalid-input');
		});

		this.actual_value.subscribe((value) => {
			if (value) 
				window.document.getElementById('value').classList.remove('ps-invalid-input');
		})
	}

	update_end_date ()
	{
		let start_date = this.start_date();
		let time_period = this.selected_time_period();

		if (start_date && time_period)
		{
			let interval_data = this.time_period_map[time_period];
			if (interval_data)
			{
				let [amount, unit] = interval_data.split(' ');
				this.end_date(moment(start_date).add(Number(amount), unit).format('YYYY-MM-DD'));
			}
		}
	}

	update_dropdown_color (dropdown, data)
	{
		let selected_value = dropdown.value;
		let selected_color = '';
		if (selected_value === 'Amber')
			selected_color = '#eba61d';
		else if (selected_value === 'Red')
			selected_color = '#911602';
		else
			selected_color = '#87a747';

		this[data](selected_color);
	}

	async load ()
	{
		return await this.projectvm.load();
	}

	async btn_save_measurement_click() {
		try {

			if (!this.start_date())
			{
				Grape.alerts.alert({type: 'warning', title: 'Could not save', message: 'Please populate the start date.'});
				window.document.getElementById('start-date').classList.add('ps-invalid-input');
			} else if (!this.actual_value())
			{
				Grape.alerts.alert({type: 'warning', title: 'Could not save', message: 'Please insert a value.'});
				window.document.getElementById('value').classList.add('ps-invalid-input');
			}
			else 
			{
				if(!this.end_date())
					this.end_date('infinity');

				let project_measurement = {
					kpi_id: this.selected_kpi().kpi_id,
					project_id: parseInt(this.projectvm.project_id()),
					actual_value: this.actual_value(),
					completion: parseInt(this.completion()),
					updated_date: this.update_date(),
					confirmed: false,
					reporting_period: '[' + this.start_date() + ', ' + this.end_date() + ')',
					uom: this.selected_kpi().target_uom,
					reading_note: this.measurement_notes(),
					project_stage: this.project_stage()
				}

				let response = await fetch('/api/project/project-value', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(project_measurement),
				});

				if (!response.ok)
					throw new Error(`Error: ${response.statusText}`);

				let result = await response.json();
				if (result.status !== 'OK')
					throw new Error(`Error: ${result.message}`);

				await Grape.alerts.alert({ type: 'success', title: 'Saved', message: 'Project measurement progress updated successfully!' });
				this.page.save_values_for_project(this.projectvm.project_id());
			}
		} catch (error) {
			console.error('Save Error:', error);
			Grape.alerts.alert({ type: 'error', title: 'Error', message: `Error: please ensure that a target is linked to the project!` });
		}
	}

	async btn_save_status_click ()
	{
		try {
			// Loop through each kpi_project
			for (let kpi_project of this.kpi_projects()) {
				let project_status = {
					kpi_id: kpi_project.kpi_id,
					project_id: parseInt(this.projectvm.project_id()),
					completion: parseInt(this.completion()),
					updated_date: this.update_date(),
					confirmed: false,
					overall_status: this.overall_status(),
					schedule_status: this.schedule_status(),
					scope_status: this.scope_status(),
					cost_status: this.cost_status(),
					progress_notes: this.progress_notes(),
					project_stage: this.project_stage(),
				};
	
				let response = await fetch('/api/project/project-value', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(project_status),
				});
	
				if (response.ok)
				{
					let result = await response.json();
					if (result.status !== 'OK')
					{
						Grape.alerts.alert({ type: 'error', title: 'Error', message: `Error: ${result.message}` });
						break;
					}
				}
				else
					throw new Error('Network response was not ok.');
			}
			await Grape.alerts.alert({ type: 'success', title: 'Saved', message: 'Project updated successfully!' });
			this.page.save_values_for_project(this.projectvm.project_id());
		} catch (error) {
			console.error('Save Error:', error);
			Grape.alerts.alert({ type: 'error', title: 'Error', message: `Error: please ensure that a target is linked to the project!` });
		}
	}	

	btn_cancel_status_click ()
	{
		this.completion(0);
		this.project_stage('Early consideration');
		this.overall_status('Green');
		this.schedule_status('Green');
		this.scope_status('Green');
		this.cost_status('Green');
		this.progress_notes('');
		this.overall_status_color('#87a747');
		this.schedule_status_color('#87a747');
		this.scope_status_color('#87a747');
		this.costs_status_color('#87a747');
	}

	btn_cancel_measurement_click ()
	{
		this.selected_time_period(null);
		this.start_date(null);
		this.end_date(null);

		// Clear all number inputs
		document.querySelectorAll('input[type="number"]').forEach((input) => {
			input.value = '';
		});

		// Clear all textareas
		document.querySelectorAll('textarea').forEach((textarea) => {
			textarea.value = '';
		});
	}

	btn_add_target_click ()
	{
		let project_target = {
			name: this.projectvm.name(),
			project_id: this.projectvm.project_id(),
			project_nr: this.projectvm.project_nr(),
		};
		Grape.dialog.open('EditProjectTarget', project_target).then(() => {
			this.page.updateData();
		}).catch((error) => {
			console.error('Error adding Project:', error);
		});
	}
}

class ProjectCapturePage
{
	constructor(bindings, element, page)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ProjectCaptureVM(this);

		let start_date = window.document.getElementById('start-date');
		start_date.addEventListener('input', function()
		{
			this.classList.remove('ps-invalid-input');
		});

		let value = window.document.getElementById('value');
		value.addEventListener('input', function()
		{
			if (this.value.length > 0)
				this.classList.remove('ps-invalid-input');
		});

		this.init();
	}

	async init ()
	{
		document.title = 'Update Project';

		let permission = await window.user_permission(['admin', 'Add Project associated Targets']);
		if (permission)
			this.viewModel.add_target(permission);

		let projectId = this.bindings.project_id || window.localStorage.getItem('last_visited_project_id');
		if (!projectId)
			throw new Error('Page not found (no linked Project)');

		window.localStorage.setItem('last_visited_project_id', projectId);
		this.viewModel.projectvm.project_id(projectId);
		this.load_saved_values_for_project(projectId);
		this.viewModel.load();
	}

	async updateData ()
	{
		try {
			let options = {
				table: 'v_project_targets',
				schema: 'kpi',
				offset: 0,
				filter_join: 'AND',
				join: 'OR',
				filter: [
					{
						field: 'project_id',
						operand: '=',
						value: this.viewModel.projectvm.project_id(),
					},
				],
			};

			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				result.records.forEach((project) => {
					project.actual_value = '';
					project.progress_notes = '';
				});
				this.viewModel.kpi_projects(result.records);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
		try {
			let time_period_type_lookup = await Grape.cache.fetch('TimePeriodTypeLookup');
			this.viewModel.time_period_options(time_period_type_lookup);
			this.viewModel.time_period_map = {};
			for (let i = 0; i < time_period_type_lookup.length; i++)
			{
				let record = time_period_type_lookup[i];
				this.viewModel.time_period_map[record.lookup_value_id] = record.data.interval;
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('An error occurred while loading flag names:', error);
		}
	}

	save_values_for_project (projectID)
	{
		let saved_values = {
			completion: this.viewModel.completion(),
			overall_status: this.viewModel.overall_status(),
			schedule_status: this.viewModel.schedule_status(),
			cost_status: this.viewModel.cost_status(),
			scope_status: this.viewModel.scope_status(),
			project_stage: this.viewModel.project_stage(),
			overall_status_color: this.viewModel.overall_status_color(),
			schedule_status_color: this.viewModel.schedule_status_color(),
			scope_status_color: this.viewModel.scope_status_color(),
			costs_status_color: this.viewModel.costs_status_color(),
			};
		window.localStorage.setItem(`project_values_${projectID}`, JSON.stringify(saved_values));
	}

	load_saved_values_for_project (projectID)
	{
		let saved_values_JSON = window.localStorage.getItem(`project_values_${projectID}`);
		if (saved_values_JSON)
		{
			let saved_values = JSON.parse(saved_values_JSON);
			this.viewModel.completion(saved_values.completion);
			this.viewModel.overall_status(saved_values.overall_status);
			this.viewModel.schedule_status(saved_values.schedule_status);
			this.viewModel.scope_status(saved_values.scope_status);
			this.viewModel.cost_status(saved_values.cost_status);
			this.viewModel.project_stage(saved_values.project_stage);
			this.viewModel.overall_status_color(saved_values.overall_status_color);
			this.viewModel.schedule_status_color(saved_values.schedule_status_color);
			this.viewModel.scope_status_color(saved_values.scope_status_color);
			this.viewModel.costs_status_color(saved_values.costs_status_color);
		}
	}
}

export default {
	route: '[/]projects/capture-project',
	page_class: ProjectCapturePage,
	template: template
}
