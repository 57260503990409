import template from './project-history.html';

ko.bindingHandlers.formattedDate = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());
		var formattedDate = new Date(value).toLocaleDateString();
		element.innerText = formattedDate;
	},
};

ko.bindingHandlers.formattedDateRange = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());

		if (typeof value === 'string') {
			var dateRangeMatch = value.match(/\[(.*?),(.*?)\)/);

			if (dateRangeMatch) {
				var startDate = new Date(dateRangeMatch[1]);
				var endDate = new Date(dateRangeMatch[2]);

				var formattedStartDate = startDate.toLocaleDateString();
				var formattedEndDate = endDate.toLocaleDateString();

				element.innerText = `(${formattedStartDate} - ${formattedEndDate})`;
				return;
			}
		}
		element.innerText = value;
	},
};

class ProjectHistoryVM
{
	constructor (page)
	{
		this.page = page;
		this.kpi_values = ko.observableArray();
		this.projects = ko.observableArray();
		this.kpi_id = ko.observable();
		this.kpi_uom = ko.observable();
		this.kpi_nr = ko.observable();
		this.search_string = ko.observable('');
		this.kpi_projects_history = ko.observableArray([]);
		this.updated_date = ko.observable();
		this.project_status_id = ko.observable();
		this.available_projects = ko.observableArray([]);
		let current_date = new Date();
		let first_day_of_month = new Date(current_date.getFullYear(), current_date.getMonth(), 1);
		this.selected_from_date = ko.observable(first_day_of_month.toISOString().split('T')[0]);
		this.selected_from_date2 = ko.observable(first_day_of_month.toISOString().split('T')[0]);
		let next_month = new Date(current_date.getFullYear(), current_date.getMonth() + 1, 1);
		let start_of_next_month = new Date(next_month.getFullYear(), next_month.getMonth(), 1);
		this.selected_to_date = ko.observable(start_of_next_month.toISOString().split('T')[0]);
		this.selected_to_date2 = ko.observable(start_of_next_month.toISOString().split('T')[0]);
		this.selected_projects = ko.observableArray([]);
		this.selected_projects2 = ko.observableArray([]);
		this.table_search_string = ko.observable('');
		this.table_search_string2 = ko.observable('');
		this.kpi_projects_measurement_history = ko.observableArray([]);

		this.sort_field = ko.observable('name');
		this.sort_order = ko.observable('DESC');

		this.table_search_string.subscribe((value) => {
			this.page.updateData();
		});

		this.table_search_string2.subscribe((value) => {
			this.page.updateData2();
		});

		this.selected_from_date.subscribe((value) => {
			this.page.updateData();
		});

		this.selected_from_date2.subscribe((value) => {
			this.page.updateData2();
		});

		this.selected_to_date.subscribe((value) => {
			this.page.updateData();
		});

		this.selected_to_date2.subscribe((value) => {
			this.page.updateData2();
		});

		this.selected_projects.subscribe((value) => {
			this.page.updateData();
		});

		this.selected_projects2.subscribe((value) => {
			this.page.updateData2();
		});

		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.page_count = ko.observable(1);

		this.current_page_number2 = ko.observable(1);
		this.current_page_size2 = ko.observable(10);
		this.page_count2 = ko.observable(1);
	}

	format_reporting_period(period) {
		let dates = period.slice(1, -1).split(',');
		return dates[0].replace(/-/g, '/') + ' - ' + dates[1].replace(/-/g, '/');
	}

	get_background_color (status)
	{
		let background_color_class = '';
		switch (status)
		{
			case 'Green':
				background_color_class = '#87a747';
				break;
			case 'Amber':
				background_color_class = '#eba61d';
				break;
			case 'Red':
				background_color_class = '#911602"';
				break;
			default:
				background_color_class = '';
				break;
		}
		return background_color_class;
	}


	btn_search_click (data)
	{
		this.table_search_string('');
		this.page.updateData();
	}

	btn_search_click2 (data)
	{
		this.table_search_string2('');
		this.page.updateData2();
	}


	btn_clear_kpi_value_filter_click2 ()
	{
		this.table_search_string2('');
		this.page.updateData2();
	}

	btn_clear_kpi_value_filter_click ()
	{
		this.table_search_string('');
		this.page.updateData();
	}

	btn_page_click (page_number)
	{
		if (page_number < 1)
			page_number = 1;
		else if (page_number > this.page_count())
			page_number = this.page_count();

		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_page_click2 (page_number)
	{
		if (page_number < 1)
			page_number = 1;
		else if (page_number > this.page_count2())
			page_number = this.page_count2();

		this.current_page_number2(page_number);
		this.page.updateData2();
	}

	async btn_delete_project_value_click (data)
	{
		let result = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Are you sure you want to delete this Project Value ?',
			title: 'Warning',
		});

		if (result)
		{
			let options = {
				project_status_id: data.project_status_id
			}

			let response = await fetch('/api/project/project-value', {
				method: 'DELETE',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(options)
			});

			if (response.ok)
				console.log('Deleted successfully')
			else
				throw new Error(response.message || response.code || 'Unknown Error');
			this.page.updateData();
			this.page.updateDraft();
		}
	}
}

class ProjectHistory
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProjectHistoryVM(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Projects History';
		let options_projects = {
			table: 'v_projects',
			schema: 'kpi',
			fields: ['project_id', 'name'],
			filter: []
		}

		let projects = await Grape.fetches.getJSON('/api/record', options_projects);

		if (projects.status != 'ERROR')
		{
			let project_names = projects.records.map(project => project.name);
			this.viewModel.available_projects(project_names);
		}
		else
			throw new Error (projects.message || projects.code)
		
		this.updateData2();
	}

	async updateData ()
	{
		let options= {
			table: 'v_project_values_status_history',
			schema: 'kpi',
			offset: (this.viewModel.current_page_number() - 1) * this.viewModel.current_page_size(),
			limit: this.viewModel.current_page_size(),
			sortorder: 'DESC',
			sortfield: 'project_status_id',
			filter: []
		}

		if (this.viewModel.selected_projects().length > 0)
		{
			options.filter.push({
				field: 'project',
				operand: 'IN',
				value: this.viewModel.selected_projects()
			});
		}

		if (this.viewModel.table_search_string() && this.viewModel.table_search_string() != '')
		{
			options.filter.push({
				field: 'kpi',
				operand: 'ILIKE',
				value: `%${this.viewModel.table_search_string()}%`
			});
		}
		try {
			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				this.viewModel.kpi_projects_history(result.records);

				this.viewModel.page_count(Math.ceil(result.total / this.viewModel.current_page_size()));
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}

	async updateData2 ()
	{
		let options = {
			table: 'v_project_values_measurement_history',
			schema: 'kpi',
			offset: (this.viewModel.current_page_number2() - 1) * this.viewModel.current_page_size2(),
			limit: this.viewModel.current_page_size2(),
			sortorder: 'DESC',
			sortfield: 'project_status_id',
			filter: []
		};

		if (this.viewModel.selected_projects2().length > 0) {
			options.filter.push({
				field: 'project',
				operand: 'IN',
				value: this.viewModel.selected_projects2()
			});
		}

		if (this.viewModel.table_search_string2() && this.viewModel.table_search_string2() != '')
		{
			options.filter.push({
				field: 'kpi',
				operand: 'ILIKE',
				value: `%${this.viewModel.table_search_string2()}%`
			});
		}

		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status != 'ERROR')
			{
				let formatted_records = result.records.map(record => {
					if (record.reporting_period)
						record.reporting_period = this.viewModel.format_reporting_period(record.reporting_period);
				
					return record;
				});

				this.viewModel.kpi_projects_measurement_history(formatted_records);
				this.viewModel.page_count2(Math.ceil(result.total / this.viewModel.current_page_size2()));
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	route: '[/]projects/project-history',
	page_class: ProjectHistory,
	template: template
}
