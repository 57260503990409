import template from './project-progress.html';
import ProjectViewModel from '../../../../lib/ProjectViewModel';

ko.bindingHandlers.formattedDate = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());
		var formattedDate = new Date(value).toLocaleDateString();
		element.innerText = formattedDate;
	},
};

class ProjectProgressVM
{
	constructor (page)
	{
		document.title = 'Project Progress'
		this.loaded = ko.observable(false);
		this.page = page;
		this.projectvm = new ProjectViewModel(page.bindings.project_id || null );
		this.current_page_id = ko.observable();
		this.current_page_title = ko.observable('');
		this.approved_kpi_projects = ko.observable();
		this.completion = ko.observable();
		this.approval_history = ko.observableArray([]);
		this.updated_date = ko.observable();
		this.kpi_nr = ko.observable();
		this.kpi = ko.observable();
		this.approval_kpi_history = ko.observableArray([]);
		this.start_date = ko.observable();
		this.end_date = ko.observable();

		// permissions
		this.can_update = ko.observable(false);

		// pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(5);
		this.page_count = ko.observable(1);

		// Pagination for the second table
		this.current_page_number2 = ko.observable(1);
		this.current_page_size2 = ko.observable(5);
		this.page_count2 = ko.observable(1);

		//search
		this.search_string = ko.observable('');

		//filtering
		this.project_flags = ko.observableArray([]);
		this.selected_project_flags = ko.observableArray([]);
		this.start_date = ko.observable();
		this.end_date = ko.observable();

		this.selected_project_flags.subscribe((value) => {
			this.page.updateData();
		});

		this.search_string.subscribe((value) => {
			this.page.updateData();
		});

		this.start_date.subscribe((value) => {
			this.page.updateData();
		});

		this.end_date.subscribe((value) => {
			this.page.updateData();
		});
	}

	get_background_color (status)
	{
		let background_color_class = '';
		switch (status)
		{
			case 'Green':
				background_color_class = '#87a747';
				break;
			case 'Amber':
				background_color_class = '#eba61d';
				break;
			case 'Red':
				background_color_class = '#911602"';
				break;
			default:
				background_color_class = '';
				break;
		}
		return background_color_class;
	}

	update_project ()
	{
		localStorage.setItem('selected_navbar_page', 'Capture');
		window.AppState.selected_page('Capture');
		Grape.navigate('/projects/projects-Update');
	}

	page_click (page_number)
	{
		if (page_number < 1)
			page_number = 1;
		else if (page_number > this.page_count())
			page_number = this.page_count();
		this.current_page_number(page_number);
		this.page.updateData();
	}

	page_click_kpi (page_number)
	{
		if (page_number < 1)
			page_number = 1;
		else if (page_number > this.page_count2())
			page_number = this.page_count2();
		this.current_page_number2(page_number);
		this.page.updateData();
	}
}

class ProjectProgressPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProjectProgressVM(this);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Project Progress';

		let permission = await window.user_permission(['admin', 'Update Project progress']);
		if (permission)
			this.viewModel.can_update(permission);
	}

	async updateData (v,e)
	{
		let current_project_id = this.viewModel.projectvm.project_id();
		let options= {
			table: 'v_project_values',
			schema: 'kpi',
			offset: 0,
			filter: [{
					field: 'project_id',
					operand: '=',
					value: current_project_id,
			}, {
				field: 'overall_status',
				operand: '!=',
				value: null,
			}]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status !== 'ERROR')
			{
				let confirmed_projects = result.records.filter((project) => project.confirmed === true && project.overall_status !== null);
					confirmed_projects.forEach((project) => {
						if (project.completion)
							project.completion = ko.observable(project.completion);
						else
							project.completion = ko.observable(0);

						if (!project.project_stage)
							project.project_stage = '';
					});
				confirmed_projects.sort((a, b) => b.project_status_id - a.project_status_id);
				let most_recent_project = confirmed_projects[0];

				if (most_recent_project)
					this.viewModel.approved_kpi_projects(most_recent_project);
				else
					this.viewModel.approved_kpi_projects();
			}
			else
			{
				this.viewModel.approved_kpi_projects();
				throw new Error(result.message || result.code);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		let hist = {
			table: 'v_project_values_status_history',
			schema: 'kpi',
			limit: this.viewModel.current_page_size(),
			offset: (this.viewModel.current_page_number() - 1) * this.viewModel.current_page_size(),
			sortorder: 'DESC',
			sortfield: 'project_status_id',
			filter_join: 'AND',
			join: 'OR',
			filter: [
				{
					field: 'project_id',
					operand: '=',
					value: current_project_id,
				},
			]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', hist);
			if (result.status != 'ERROR')
			{
				this.viewModel.approval_history(result.records);
				this.viewModel.page_count(Math.ceil(result.total / this.viewModel.current_page_size()));
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		let kpi_history = {
			table: 'v_project_values_measurement_history',
			schema: 'kpi',
			limit: this.viewModel.current_page_size2(),
			offset: (this.viewModel.current_page_number2() - 1) * this.viewModel.current_page_size2(),
			sortorder: 'DESC',
			sortfield: 'project_status_id',
			filter_join: 'AND',
			join: 'OR',
			filter: [
				{
					field: 'project_id',
					operand: '=',
					value: current_project_id,
				},
			]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', kpi_history);
			if (result.status != 'ERROR')
			{
				this.viewModel.approval_kpi_history(result.records.sort((a, b) => new Date(b.updated_date) - new Date(a.updated_date)));
				this.viewModel.page_count2(Math.ceil(result.total / this.viewModel.current_page_size()));
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	route: '[/]projects/project-progress',
	page_class: ProjectProgressPage,
	template: template
}
