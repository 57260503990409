import template from './EditSDGProject.html';

class EditSDGProjectVM
{
	constructor (dialog)
	{
		this.dialog = dialog;

		let last_sdg_project_tab = localStorage.getItem('last_sdg_project_tab') || 'target';
		this.selected_tab = ko.observable(last_sdg_project_tab);

		this.project_name = ko.observable();
		this.project_id = ko.observable();
		this.sdg_id = ko.observable();
		this.targets = ko.observableArray();
		this.selected_item = ko.observable();
		this.selected_ind = ko.observable();
		this.mode = ko.observable();
		this.linked_targets = ko.observableArray();
		this.available_targets = ko.observableArray();

		this.indicators = ko.observableArray();
		this.linked_indicators = ko.observableArray();

		this.target_add_disabled = ko.observable(true);
		this.target_remove_disabled = ko.observable(true);
		this.ind_add_disabled = ko.observable(true);
		this.ind_remove_disabled = ko.observable(true);

		this.selected_item.subscribe(item => {
			this.target_add_disabled(!item || this.linked_targets().some(t => t.sdg_target_id === item.sdg_target_id));
			this.target_remove_disabled(!item || this.targets().some(r => r.sdg_target_id === item.sdg_target_id));
		});

		let selected_target = this.selected_item();
		this.target_add_disabled(!selected_target || this.linked_targets().includes(item => item.sdg_target_id === selected_target.sdg_target_id));
		this.target_remove_disabled(!selected_target || this.targets().includes(item => item.sdg_target_id === selected_target.sdg_target_id));

		this.selected_ind.subscribe(item => {
			this.ind_add_disabled(!item || this.linked_indicators().some(t => t.indicator_id === item.indicator_id));
			this.ind_remove_disabled(!item || this.indicators().some(r => r.indicator_id === item.indicator_id));
		});

		let selected_indicator = this.selected_ind();
		this.ind_add_disabled(!selected_indicator || this.linked_indicators().includes(item => item.indicator_id === selected_indicator.indicator_id));
		this.ind_remove_disabled(!selected_indicator || this.indicators().includes(item => item.indicator_id === selected_indicator.indicator_id));
	}

	switch_tabs (data, event)
	{
		let tabname = event.currentTarget.getAttribute('data-tabname');
		this.selected_tab(tabname);

		localStorage.setItem('last_sdg_project_tab', tabname);
		
		document.querySelectorAll('.ps-tabs li').forEach(tab => tab.classList.remove('active'));
		event.currentTarget.classList.add('active');
	}

	async btn_select_target_click(data)
	{
		this.selected_item(data);
	}

	async btn_select_ind_click(data)
	{
		this.selected_ind(data);
	}

	// ==================================================
	// Add targets to ESG Project
	async btn_add_target_ind_project_click()
	{
		let targets_to_add = this.selected_item();
		if (targets_to_add)
		{
			//for (let target of targets_to_add)
			//{
				let options = {
					sdg_target_id: targets_to_add.sdg_target_id,
					project_id: this.project_id()
				};

				try {
					let response = await Grape.fetches.postJSON('api/sdg/sdg-target-project-link', options);
					if (response.result != 'ERROR')
					{
						this.linked_targets.push(targets_to_add);
						this.targets.remove(targets_to_add);
					}
					else
						throw new Error(response.message || response.code);
				} catch (error) {
					console.error(error)
					Grape.alerts.alert({type: 'error', title: 'Error', message: 'Unable to add target'});
					//break;
				}
			//}
			this.selected_item('');
		}
	}

	// ==================================================
	// Remove targets from ESG Project
	async btn_remove_target_ind_project_click()
	{
		let to_remove = this.selected_item();
		let options = {
			sdg_target_id: to_remove.sdg_target_id,
			project_id: this.project_id()
		}

		try {
			let response = await fetch('api/sdg/sdg-target-project-unlink', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(options)
			});

			if (response.ok)
			{
				let result = await response.json();
				if (result.status != 'ERROR')
				{
					this.targets.push(to_remove);
					this.linked_targets.remove(to_remove);					
					this.selected_item('');
				}
				else
					throw new Error(result.message || result.code);
			}
			else
			{
				let error = await response.json();
				throw new Error(error.message || 'Unknown error occurred');
			}
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Unable to remove target' });
			//break;
		}
	}

	// ==================================================
	// Add Indicators to ESG Project
	async btn_add_ind_project_click()
	{
		let to_add = this.selected_ind();
		if (to_add)
		{
			//for (let target of targets_to_add)
			//{
				let options = {
					indicator_id: to_add.indicator_id,
					project_id: this.project_id()
				};

				try {
					let response = await Grape.fetches.postJSON('api/sdg/sdg-indicator-project-link', options);
					if (response.result != 'ERROR')
					{
						this.linked_indicators.push(to_add);
						this.indicators.remove(to_add);
					}
					else
						throw new Error(response.message || response.code);
				} catch (error) {
					console.error(error);
					Grape.alerts.alert({type: 'error', title: 'Error', message: 'Unable to add indicator'});
					//break;
				}
			//}
			this.selected_ind('');
		}
	}

	// ==================================================
	// Remove Indicators from ESG Project
	async btn_remove_ind_project_click()
	{
		let to_remove = this.selected_ind();
		let options = {
			indicator_id: to_remove.indicator_id,
			project_id: this.project_id()
		}

		try {
			let response = await fetch('api/sdg/sdg-indicator-project-unlink', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(options)
			});

			if (response.ok)
			{
				let result = await response.json();
				if (result.status != 'ERROR')
				{
					this.indicators.push(to_remove);
					this.linked_indicators.remove(to_remove);
					this.selected_ind('');
				}
					
				else
					throw new Error(result.message || result.code);
			}
			else
			{
				let error = await response.json();
				throw new Error(error.message || 'Unknown error occurred');
			}
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			//break;
		}
	}

	async btn_close_click ()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}

class EditSDGProject
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditSDGProjectVM(this);
		this.name = 'EditSDGProject';
		this.viewModel.project_id(bindings.sdg_project_info.project_id);
		this.viewModel.project_name(bindings.sdg_project_info.project_name || '');
		this.viewModel.sdg_id(bindings.sdg_project_info.sdg_id);

		this.init();
	}

	async init () 
	{
		document.title = 'Edit SDG related Projects';
		let last_sdg_project_tab = localStorage.getItem('last_sdg_project_tab') || 'target';
		document.querySelector(`.ps-tabs li[data-tabname='${last_sdg_project_tab}']`).classList.add('active');
	}

	async updateData ()
	{
		// Get targets
		let option_targets = {
			table: 'v_sdg_targets',
			schema: 'sdg',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter : [{
				field: 'sdg_id',
				operand: '=',
				value: this.viewModel.sdg_id()
			}]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', option_targets);
			if (result.status != 'ERROR')
			{
				let targets = result.records.map(target => ({
					...target,
					show_description: ko.observable(false)
				}));

				this.viewModel.targets(targets);
			}
		} catch (error) {
			console.error(error);
		}

		//Get Project Targets
		let options_projectt = {
			table: 'v_sdg_target_project',
			schema: 'sdg',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'project_id',
				operand: '=',
				value: this.viewModel.project_id()
			}]
		}
		try {
			let result = await Grape.fetches.getJSON('/api/record', options_projectt);
			if (result.status != 'ERROR')
			{
				let linked = result.records.map(target => target.sdg_target_id);
				let avail = this.viewModel.targets().filter(target => !linked.includes(target.sdg_target_id));
				let link = this.viewModel.targets().filter(target => linked.includes(target.sdg_target_id));
				link.forEach(x => x.show_description(false));
				this.viewModel.targets(avail);
				this.viewModel.linked_targets(link);
			}
		} catch (error) {
			console.error(error);
		}

		// Get Indicators
		let options_indicators = {
			table: 'v_sdg_target_indicators',
			schema: 'sdg',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter : [{
				field: 'sdg_id',
				operand: '=',
				value: this.viewModel.sdg_id()
			}]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options_indicators);
			if (result.status != 'ERROR')
			{
				let indicators = result.records.map(ind => ({
					...ind,
					show_description: ko.observable(false)
				}));

				this.viewModel.indicators(indicators);
			}
		} catch (error) {
			console.error(error);
		}

		// Get Project indicators
		let options_projecti = {
			table: 'v_sdg_target_indicator_project',
			schema: 'sdg',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'project_id',
				operand: '=',
				value: this.viewModel.project_id()
			}]
		}
		try {
			let result = await Grape.fetches.getJSON('/api/record', options_projecti);
			if (result.status != 'ERROR')
			{
				let linked = result.records.map(ind => ind.indicator_id);
				let avail = this.viewModel.indicators().filter(ind => !linked.includes(ind.indicator_id));
				let link = this.viewModel.indicators().filter(ind => linked.includes(ind.indicator_id));
				link.forEach(x => x.show_description(false));
				this.viewModel.indicators(avail);
				this.viewModel.linked_indicators(link);
			}
		} catch (error) {
			console.error(error);
		}
	}
}

export default {
	name: 'EditSDGProject',
	dialog_class: EditSDGProject,
	template: template,
	provider: 'ps'
}
