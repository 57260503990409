import template from './edit-project-details.html';

class EditProjectDetailsViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.project_id = ko.observable();
		this.name = ko.observable();
		this.project_nr = ko.observable();
		this.description = ko.observable();
		this.start_date = ko.observable();
		this.end_date = ko.observable();
		this.users = ko.observableArray();
		this.project_type = ko.observable();
		this.sponsors = ko.observableArray([]);
		this.champions = ko.observableArray([]);
		this.owners = ko.observableArray([]);
		this.selected_sponsor = ko.observable();
		this.selected_champion = ko.observable();
		this.selected_owner = ko.observable();
		this.user_data = ko.observableArray([]);
		this.project_owner = ko.observable();
		this.project_sponsor = ko.observable();
		this.project_champion = ko.observable();
		this.project_users - ko.observableArray([]);
		this.project_types = ko.observableArray(['Internal', 'External']);

		this.project_id.subscribe((newProjectId) => {
			this.set_dropdown_options(newProjectId);
		});

		this.is_date_valid = ko.computed(() => {
			let start_date = this.start_date();
			let end_date = this.end_date();
			if (start_date && end_date)
				return moment(end_date).isSameOrAfter(moment(start_date));

			return true;
		});
	}

	async btn_save_click ()
	{
		if (!this.project_nr())
		{
			window.document.getElementById('project-nr').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Project number missing!'});
			return;
		}
		else if (!this.name())
		{
			window.document.getElementById('name').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Project name missing!'});
			return;
		}
		else if (!this.description())
		{
			window.document.getElementById('description').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Project description missing!'});
			return;
		}
		else if (!this.project_type())
		{
			window.document.getElementById('type').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Project type missing!'});
			return;
		}
		else if (!this.start_date())
		{
			window.document.getElementById('start-date').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Project start date missing!'});
			return;
		}
		else if (!this.end_date())
		{
			window.document.getElementById('end-date').classList.add('ps-invalid-input');
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Project end date missing!'});
			return;
		}

		let project_details = {
			name: this.name(),
			project_nr: this.project_nr(),
			project_id: this.project_id(),
			fields: {
				'add': [
					{'name':'description', 'value': this.description()},
					{'name': 'project_type', 'value': this.project_type()},
					{'name': 'start_date', 'value': this.start_date()},
					{'name': 'end_date', 'value': this.end_date()},
					{'name': 'project_champion', 'value': this.selected_champion() ? this.selected_champion().name() : null},
					{'name': 'project_sponsor', 'value': this.selected_sponsor() ? this.selected_sponsor().name() : null},
					{'name': 'project_owner', 'value': this.selected_owner() ? this.selected_owner().name() : null}
				]
			}
		};

		try {
			let response;

			if (project_details.project_id)
			{
				response = await fetch('/api/kpi/project', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(project_details),
				});
			}

			if (response.ok)
			{
				let result = await response.json();

				if (result.status === 'OK')
				{
					await Grape.alerts.alert({type: 'success', title: 'Saved', message: 'Project  details saved successfully!'});
					this.dialog.close(true);
				}
				else
					console.error('Error:', result.message);
			}
			else
				console.error('Network error:', response.statusText);
		} catch (error) {
			console.error('Error:', error);
		}
	}

	set_dropdown_options(current_project_id) {
		let filtered_users = this.user_data().filter(user =>
			user.all_projects.some(project => project.project_id == current_project_id)
		).map(user => ({
			name: ko.observable(user.username),
			id: user.user_id,
			roles: user.roles
		}));
	
		this.users(filtered_users);
	}

	async btn_close_click ()
	{
		let response = await Grape.alerts.confirm({type: 'warning', message: 'Are you sure you want to cancel?', title: ''})
		if (response)
			this.dialog.close(false);
	}
}

class EditProjectDetailsClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditProjectDetailsViewModel(this);
		this.name = 'EditProjectDetails';
		this.parentViewModel = bindings.page;

		this.viewModel.name(bindings.name);
		this.viewModel.project_id(bindings.project_id);
		this.viewModel.project_nr(bindings.project_nr);
		this.viewModel.description(bindings.description);
		this.viewModel.start_date(bindings.start_date);
		this.viewModel.end_date(bindings.end_date);
		this.viewModel.project_type(bindings.project_type);
		this.viewModel.selected_sponsor(bindings.project_sponsor);
		this.viewModel.selected_owner(bindings.project_owner);
		this.viewModel.selected_champion(bindings.project_champion);

		this.init();
	}

	async init ()
	{
		let userdata = {
			table: 'v_project_users',
			schema: 'kpi',
			filter: []
		};

		try {
			let result = await Grape.fetches.getJSON('/api/record', userdata);

			if (result.status === 'OK')
			{
				let users_with_projects = result.records.map(user => ({
					...user,
					all_projects: user.all_projects || [],
					roles: user.roles
				}));

				this.viewModel.user_data(users_with_projects);
				this.viewModel.set_dropdown_options(this.viewModel.project_id());

				let project_owner_users = this.viewModel.users().filter(user => user.roles.includes('Project owner'));
				let project_sponsor_users = this.viewModel.users().filter(user => user.roles.includes('Project sponsor'));
				let project_champion_users = this.viewModel.users().filter(user => user.roles.includes('Project champion'));

				this.viewModel.owners(project_owner_users.map(user => ({ name: user.name, project_id: user.project_id })));
				this.viewModel.champions(project_champion_users.map(user => ({ name: user.name, project_id: user.project_id })));
				this.viewModel.sponsors(project_sponsor_users.map(user => ({ name: user.name, project_id: user.project_id })));

				let matching_sponsor = ko.utils.arrayFirst(this.viewModel.sponsors(), (sponsor) => {
					return sponsor.name() === this.bindings.project_sponsor;
				});

				if (matching_sponsor)
					this.viewModel.selected_sponsor(matching_sponsor);

				let matching_owner = ko.utils.arrayFirst(this.viewModel.owners(), (owner) => {
					return owner.name() === this.bindings.project_owner;
				});

				if (matching_owner)
					this.viewModel.selected_owner(matching_owner);

				let matching_champion = ko.utils.arrayFirst(this.viewModel.champions(), (champion) => {
					return champion.name() === this.bindings.project_champion;
				});

				if (matching_champion)
					this.viewModel.selected_champion(matching_champion);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		let name = window.document.getElementById('name');
		name.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let number = window.document.getElementById('project-nr');
		number.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let description = window.document.getElementById('description');
		description.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let start_date = window.document.getElementById('start-date');
		start_date.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let end_date = window.document.getElementById('end-date');
		end_date.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});

		let type = window.document.getElementById('type');
		type.addEventListener('input', function() 
		{
			if (this.value.length > 0) 
				this.classList.remove('ps-invalid-input');
		});
	}
}

export default {
	name: 'EditProjectDetails',
	dialog_class: EditProjectDetailsClass,
	template: template,
	provider: 'ps'
}
