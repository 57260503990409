
class BUViewModel
{
	constructor(bu_id, name)
	{
		this.bu_nr = ko.observable('');
		this.bu_id = ko.observable(bu_id);
		this.name = ko.observable(name);
		this.business_unit_type_value = ko.observable('');
	}

	async load()
	{
		//get BU details of business unit
		let result = await Grape.fetches.getJSON('/api/record', {
			schema: 'kpi',
			table: 'v_business_units',
			filter: [{field: 'business_unit_id', 
				oper: '=',
				value: this.bu_id()}],
			fields: ['name', 'business_unit_id', 'business_unit_type_value', 'bu_nr']
		});

		if (!result.records) 
			console.log('No data');
		
		this.name(result.records[0].name);
		this.bu_id(result.records[0].business_unit_id);
		this.business_unit_type_value(result.records[0].business_unit_type_value);
		this.bu_nr(result.records[0].bu_nr);
	}

	async save ()
	{
	}
}

export default BUViewModel;
