import template from './users.html';

class UsersSubPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.users = ko.observableArray([]);
		this.kpi_users = ko.observableArray([]);
		this.bu_users = ko.observableArray([]);
		this.project_users = ko.observableArray([]);

		// Pagination for first table (Indicators)
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(5);
		this.page_count = ko.observable(1);
		
		// Pagination for the second table (Business Units)
		this.current_page_number2 = ko.observable(1); 
		this.current_page_size2 = ko.observable(5);
		this.page_count2 = ko.observable(1);

		// Pagination for the third table (Projects)
		this.current_page_number3 = ko.observable(1); 
		this.current_page_size3 = ko.observable(5);
		this.page_count3 = ko.observable(1);

		this.no_bu_users_with_roles = ko.computed(() => {
			return !this.bu_users().some(user => user.roles && user.roles.trim().length > 0);
		});

		this.no_kpi_users_with_roles = ko.computed(() => {
			return !this.kpi_users().some(user => user.roles && user.roles.trim().length > 0);
		});

		this.no_project_users_with_roles = ko.computed(() => {
			return !this.project_users().some(user => user.roles && user.roles.trim().length > 0);
		});
	}

	async btn_new_user_click ()
	{
		await Grape.dialog.open('EditUserDialog', {mode: 'new'});
		this.page.updateData();
	}

	async btn_view_user_click (data)
	{
		await Grape.dialog.open('EditUserDialog', {user_data: data, mode: 'view'});
	}

	//--------------------------------------------------------------------------
	// DELETING EXISTING USERS FROM ALL:
	
	//INDICATORS:
	async btn_delete_kpi_user_click (data)
	{
		let kpi_ids = data.all_kpis;

		let answer = await Grape.alerts.confirm({type: 'warning', title: 'Warning', message: 'Are you sure you want to remove this user from all Indicators?'});

		if (answer)
		{
			let success;
			for (let item of kpi_ids)
			{
				let options = {
					kpi_id: item.kpi_id,
					user_id: data.user_id
				};

				try {
					let response = await fetch('/api/catalytix/catalytix', {
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(options),
					});

					if (response.ok)
						success = true;

				} catch (error)
				{
					await Grape.alerts.alert({type: 'error', title: 'Error', message: 'Error deleting user from Indicators' + error.message});
					break;
				}
			}
			if (success)
			{
				Grape.alerts.alert({title: 'Success!', message: 'User removed successfully!', type: 'success'});
				this.page.updateData();
			}
		}
	}

	//BUSINESS UNITS:
	async btn_delete_bu_user_click (data)
	{
		let business_unit_ids = data.all_business_units;

		let answer = await Grape.alerts.confirm({type: 'warning', title: 'Warning', message: 'Are you sure you want to remove this user from all Business Units?'});

		if (answer)
		{
			let success;
			for (let item of business_unit_ids)
			{
				let options = {
					business_unit_id: item.business_unit_id,
					user_id: data.user_id
				};

				try {
					let response = await fetch('/api/catalytix/catalytix', {
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(options),
					});

					if (response.ok)
						success = true;

				} catch (error)
				{
					await Grape.alerts.alert({type: 'error', title: 'Error', message: 'Error deleting user from Business Units' + error.message});
					break;
				}
			}
			if (success)
			{
				Grape.alerts.alert({title: 'Success!', message: 'User removed successfully!', type: 'success'});
				this.page.updateData();
			}
		}
	}

	//PROJECTS:
	async btn_delete_project_user_click (data)
	{
		let project_ids = data.all_projects;

		let answer = await Grape.alerts.confirm({type: 'warning', title: 'Warning', message: 'Are you sure you want to remove this user from all Projects?'});
		
		if (answer)
		{
			let success;
			for (let item of project_ids)
			{
				let options = {
					project_id: item.project_id,
					user_id: data.user_id
				};

				try {
					let response = await fetch('/api/catalytix/catalytix', {
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(options),
					});

					if (response.ok)
						success = true;

				} catch (error)
				{
					await Grape.alerts.alert({type: 'error', title: 'Error', message: 'Error deleting user from Projects' + error.message});
					break;
				}
			}
			if (success)
			{
				Grape.alerts.alert({title: 'Success!', message: 'User removed successfully!', type: 'success'});
				this.page.updateData();
			}
		}
	}

	//------------------------------------------------------------------------------
	// EDIT THE EXISTING USERS FOR: 
	// General:
	async btn_edit_user_click (data)
	{
		await Grape.dialog.open('EditUserDialog', { user_data: data, mode: 'edit'});
		this.page.updateData();
	}

	// KPI
	async btn_edit_kpi_user_click(data)
	{
		await Grape.dialog.open('EditKPIUserDialog', {mode: 'edit', user_data: data});
		location.reload();
	}

	// BUSINESS UNIT
	async btn_edit_bu_user_click(data)
	{
		await Grape.dialog.open('EditBUUserDialog', {mode: 'edit', user_data: data});
		location.reload();
	}

	// PROJECT
	async btn_edit_project_user_click(data)
	{
		await Grape.dialog.open('EditProjectUserDialog', {mode: 'edit', user_data: data});
		location.reload();
	}

	page_click_kpi (page_number)
	{
		if (page_number < 1)
			page_number = 1;
		else if (page_number > this.page_count())
			page_number = this.page_count();
		this.current_page_number(page_number);
		this.page.updateData();
	}

	page_click_BUs (page_number)
	{
		if (page_number < 1)
			page_number = 1;
		else if (page_number > this.page_count2())
			page_number = this.page_count2();
		this.current_page_number2(page_number);
		this.page.updateData();
	}

	page_click_projects (page_number)
	{
		if (page_number < 1)
			page_number = 1;
		else if (page_number > this.page_count3())
			page_number = this.page_count3();
		this.current_page_number3(page_number);
		this.page.updateData();
	}

	async btn_delete_user_click (data)
	{
		let user_id = data.user_id;
		let answer = await Grape.alerts.confirm({type: 'danger', title: 'Warning', message: 'Are you sure you want to delete this user system-wide?'});

		if (answer)
		{
			let response = await fetch('/api/catalytix/catalytix', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({user_id }),
			});
	
			if (response.ok)
			{
				await Grape.alerts.alert({type: 'success', title: 'Saved', message: 'Catalytix user removed successfully!'});
				this.page.updateData();
			}
			else
				console.error('Error deleting user:', error);
		}
	}

	//----------------------------------------------------------------------
	//ADD A USER TO:
	//INDICATOR:
	async btn_add_kpi_user_click()
	{
		await Grape.dialog.open('EditKPIUserDialog', {mode: 'new', added_users: this.kpi_users()});
		location.reload();
	}

	//PROJECT:
	async btn_add_project_user_click()
	{
		await Grape.dialog.open('EditProjectUserDialog', {mode: 'new', added_users: this.project_users()});
		location.reload();
	}

	//BUSINESS UNITS:
	async btn_add_bu_user_click()
	{
		await Grape.dialog.open('EditBUUserDialog', {mode: 'new', added_users: this.bu_users()});
		location.reload();
	}
}

class UsersSubPagePage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new UsersSubPageVM(this);
		this.bindings = bindings;
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Admin - Users';
	}

	async updateData (v,e)
	{
		// Get list of distinct users
		let user_data = {
			table: 'v_all_users',
			schema: 'kpi',
			sortfield: 'user_id',
			sortorder: 'ASC'
		}

		let users = await Grape.fetches.getJSON('/api/record', user_data);

		if (users.status != 'ERROR')
		{
			users.records.forEach(user => {
				let data = this.parse_settings(user.settings);
				user.name_and_surname = data.name_and_surname || '';
				user.user_department = data.user_department || '';
				user.user_designation = data.user_designation || '';
				user.user_employee_number = data.user_employee_number || '';
				user.user_phone = data.user_phone || '';
			});

			this.viewModel.users(users.records);
		}
		else
			throw new Error(users.message || users.code)
		
		// Get list of kpi users
		let kpi_user_data = {
			table: 'v_kpi_users',
			schema: 'kpi',
			sortfield: 'user_id',
			sortorder: 'ASC',
			limit: this.viewModel.current_page_size(),
			offset: (this.viewModel.current_page_number() - 1) * this.viewModel.current_page_size(),
		}

		let kpi_users = await Grape.fetches.getJSON('/api/record', kpi_user_data);

		if (kpi_users.status != 'ERROR')
		{
			
			let users = kpi_users.records.map(user => ({
				...user,
				kpi_view: ko.observable(false)
			}));
			this.viewModel.kpi_users(users);
			this.viewModel.page_count(Math.floor(kpi_users.total / this.viewModel.current_page_size())+1);
		} else
			throw new Error(kpi_users.message || kpi_users.code)

		// Get list of bu users
		let bu_user_data = {
			table: 'v_business_unit_users',
			schema: 'kpi',
			sortfield: 'user_id',
			sortorder: 'ASC',
			limit: this.viewModel.current_page_size2(),
			offset: (this.viewModel.current_page_number2() - 1) * this.viewModel.current_page_size2(),
		}

		let bu_users = await Grape.fetches.getJSON('/api/record', bu_user_data);

		if (bu_users.status != 'ERROR')
		{
			let users = bu_users.records.map(user => ({
				...user,
				bu_view: ko.observable(false)
			}))
			this.viewModel.bu_users(users);
			this.viewModel.page_count2(Math.floor(bu_users.total / this.viewModel.current_page_size()+1));
		} else
			throw new Error(bu_users.message || bu_users.code)

		// Get list of project users
		let project_user_data = {
			table: 'v_project_users',
			schema: 'kpi',
			sortfield: 'user_id',
			sortorder: 'ASC',
			limit: this.viewModel.current_page_size3(),
			offset: (this.viewModel.current_page_number3() - 1) * this.viewModel.current_page_size3()
		}

		let project_users = await Grape.fetches.getJSON('/api/record', project_user_data);

		if (project_users.status != 'ERROR') {
			let users = project_users.records.map(user => ({
				...user,
				projects_view: ko.observable(false)
			}));

			this.viewModel.project_users(users);
			this.viewModel.page_count3(Math.floor(project_users.total / this.viewModel.current_page_size()+1));
		} else
			throw new Error(project_users.message || project_users.code)
	}

	parse_settings (settings_string)
	{
		let parsed = {};
		// Split the string into individual key-value pairs
		let data = settings_string.split(',').map(s => s.trim());
		
		data.forEach(pair => {
			let [key, value] = pair.split(':').map(s => s.trim());
			parsed[key] = value.replace(/"/g, '');
		});
	
		return parsed;
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]users-setup/users',
	page_id: '/users-setup/users',
	page_class: UsersSubPagePage,
	template: template
}
