import template from './missing-data-check.html';

class MissingDataViewModel
{
	constructor (params)
	{
		this.params = params;
		this.kpis = ko.observableArray();
		this.selected_kpi_tab = ko.observable();
		this.onload = true;

		this.sort_field = ko.observable('kpi_nr');
		this.sort_order = ko.observable('ASC');
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(13);
		this.page_count = ko.observable(1);

		this.kpi_grouped = ko.observable({});
		this.kpi_group_names = ko.observable();
		this.kpi_flags_options = ko.observableArray();
		this.reporting_frequency_options = ko.observableArray();
		this.kpi_tabs = ko.observableArray();
		this.btn_process = ko.observable(true);

		this.available_uoms = ko.observable();

		this.loader = ko.observable(false);

		this.current_page_size.subscribe((value) => {
			this.onload = true;
		});

		this.load_kpi_tabs();
		this.btn_select_kpi_type('Environmental');
		this.updateData();
	}

	async btn_run_missing_data_process()
	{
		try {
			this.btn_process(false);
			let res = await Grape.fetches.getJSON('/api/kpi/check-missing-kpi-data');
			if (res.status == 'OK')
			{
				Grape.alerts.alert({ type: 'success', message: 'Indicator missing values check done!' });
				this.refresh_view();
				this.updateData();
				this.btn_process(true);
			}
			else
			{
				console.error(res.message);
				this.btn_process(true);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', message: 'An error occurred while running the missing data check' });
		}
	}

	async set_zero_flag_name(data)
	{
		let confirm = await Grape.alerts.confirm({ title: 'Confirm', message: 'This will remove the value from the missing values list, and consider it with outlier detection checks. Are you sure you want to allow the value to be 0 or nothing? ' });
		if (confirm)
		{
			let res = await Grape.fetches.postJSON('/api/kpi/kpi-value-add-zero-flag', {
				kpi_value_id: data.kpi_value_id
			});

			if (res.status =='OK')
			{
				Grape.alerts.alert({ type: 'success', message: 'This Indicator value will no longer be seen as a missing value.' });
				this.refresh_view();
				this.updateData();
			}
			else
			{
				Grape.alerts.alert({ type: 'error', message: 'An error occurred while seeting the flag name' });
				console.error(error);
			}
		}
	}

	get_flag_data(flag_names)
	{
		if (!flag_names || !Array.isArray(flag_names))
			return { class: '', text: '' };

		if (flag_names.includes('dqc missing error'))
			return { class: 'kpi-outlier-flag', text: 'Missing Value' };

		if (flag_names.includes('dqc missing warning'))
			return { class: 'kpi-warning-flag', text: 'Value Warning' };

		return { class: '', text: flag_names.join(', ') };
	}

	async btn_view_kpi_value_click(data)
	{
		await Grape.dialog.open('KPIValueDetails', {data: data});
		this.updateData();
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.updateData();
	}

	// Sort fields
	sort_column (column)
	{
		if (this.sort_field() === column)
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		else
		{
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.updateData();
	}

	async load_kpi_tabs()
	{
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'kpi',
				table: 'v_kpi_groups',
				filter: [],
			});

			if (result.status === 'OK')
			{
				this.kpi_tabs(result.records);
				this.selected_kpi_tab(this.kpi_tabs()[0]);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	btn_select_kpi_type(data)
	{
		let selected = this.kpi_tabs().find((group) => group.name === data);
		this.selected_kpi_tab(selected);
		this.current_page_number(1);
		this.updateData();

		// Ensure the correct radio button is checked
		let buttons = document.querySelectorAll('.missing-kpi-group-btn');
		buttons.forEach((button) => {
			let radio_input = button.querySelector('input[type="radio"]');
			let span = button.querySelector('span');

			if (span && radio_input)
				radio_input.checked = (span.textContent.trim() === data);
		});
	}

	async refresh_view()
	{
		let refresh = await Grape.fetches.getJSON('/api/kpi-missing-values/mv/refresh', {});
		this.updateData();
	}

	async updateData()
	{
		let options =  {
			table: 'mv_kpi_data_missing_values',
			schema: 'kpi',
			filter_join: 'AND',
			join: 'OR',
			sortorder: this.sort_order(),
			sortfield: this.sort_field(),
			filter: [],
			limit: this.current_page_size()
		};

		this.loader(true);

		let response = await Grape.fetches.getJSON('/api/record', options);

		if (response.status != 'ERROR')
			this.kpis(response.records);

		if (this.selected_kpi_tab())
		{
			options.filter.push({
				field: 'kpi_group_id',
				operand: '=',
				value: this.selected_kpi_tab().kpi_group_id,
			});
		}

		// LOGIC: Pagination
		if (this.current_page_number() && this.current_page_size())
		{
			options.limit = this.current_page_size();
			options.offset = (this.current_page_number()-1) * this.current_page_size();
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);
			if (result.status == 'OK')
			{
				this.page_count(Math.floor(result.total/result.limit)+1);

				this.kpi_grouped(result.records);
				this.loader(false);
			}
		} catch (error) {
			this.loader(false);
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		// Load UOMs
		let uom = {
			table: 'v_units',
			schema: 'uom',
			filter: []
		}

		let uom_data = await Grape.fetches.getJSON('/api/record', uom);

		if (uom_data.status != 'ERROR')
			this.available_uoms(uom_data.records);
		else
			throw new Error (uom_data.message || uom_data.code)

		let kpi_flags = {
			table: 'v_lookup_value',
			schema: 'grape',
			sortfield: 'idx',
			filter: [{
				field: 'lookup_name',
				operand: '=',
				value: 'KPIFlag'
			}]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', kpi_flags);
			if (result.status != 'ERROR')
				this.kpi_flags_options(result.records);
			else
				throw new Error(result.message || result.code)
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		}

		try {
			let result = await Grape.cache.fetch('TimePeriodTypeLookup');
			this.reporting_frequency_options(result);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	name: 'ko-missing-data-check',
	viewModel: MissingDataViewModel,
	module_type: 'ko',
	template: template
}
