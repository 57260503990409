import template from './business-units.html';
import BUViewModel from '../../lib/BUViewModel';

class BUVM
{
	constructor (page) 
	{
		this.page = page;
		this.buvm = new BUViewModel(page.bindings.bu_id || null);
		this.bu_id = ko.observable();
		this.current_route = ko.observable();
		this.current_page_title = ko.observable('');

		// permissions
		this.view_users_tab = ko.observable(false);

		this.selected_page = ko.computed(() => {
			return window.AppState.selected_page();
		});

		if (page.bindings.from === 'overview')
		{
			window.AppState.selected_page('Setup');
			localStorage.setItem('selected_navbar_page', 'Setup');
		}

		this.tabs = ko.observableArray([{
			route: '[/]bu/details',
			title: 'Details',
			heading_text: 'Details',
			icon: 'fa-thin fa-memo-circle-info',
			visible: true
		}, {
			route: '[/]bu/bu-kpi',
			title: 'Associated Indicator(s)',
			heading_text: 'Associated Indicator(s)',
			icon: 'fa-thin fa-key-skeleton-left-right',
			visible: true
		}, {
			route: '[/]bu/related-projects',
			title: 'Associated Project(s)',
			heading_text: 'Associated Project(s)',
			icon: 'fa-thin fa-diagram-project',
			visible: true
		}, {
			route: '[/]bu/project-users',
			title: 'Users',
			heading_text: 'Users',
			icon: 'fa-thin fa-users',
			visible: this.view_users_tab()
		}, {
			route: '[/]ui/setup/custom-tables',
			title: 'Custom Fields',
			heading_text: 'Custom Fields',
			icon: 'fa-thin fa-pen-field',
			visible: true
		}]); 

		this.current_route.subscribe((newPageId) => {
			let matchingTab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === newPageId;
			});
			this.current_page_title(matchingTab ? matchingTab.heading_text : '');
		}, this);

		// Re-evaluate the users tab visibility
		this.view_users_tab.subscribe(val => {
			let users_tab = ko.utils.arrayFirst(this.tabs(), (tab) => {
				return tab.route === '[/]bu/project-users';
			});

			if (users_tab)
			{
				users_tab.visible = val;
				this.tabs.valueHasMutated();
			}
		});

		// Handle the visible tabs (used in html)
		this.visible_tabs = ko.computed(() => {
			return ko.utils.arrayFilter(this.tabs(), (tab) => {	
				return tab.visible === true;
			});
		});
	}

	async load()
	{
		return await this.buvm.load();
	}

	load_tab (route)
	{
		window.localStorage.setItem('last_busidebar_page', route);
		const element = this.page.element.querySelector('#page_content');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id '${route}' not found`);
		
		Grape.pages.load_page(page, element, {bu_id: this.buvm.bu_id()}, {}, () => {});
	}
}

class BUPage 
{
	constructor (bindings, element, page) 
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new BUVM(this);
	}

	async init ()
	{
		document.title = 'Business Units';

		// permissions
		let view_users_permission = await window.user_permission(['admin', 'View Business Unit Users Page']);
		if (view_users_permission)
				this.viewModel.view_users_tab(view_users_permission);

		if (this.bindings.bu_id)
		{
			window.localStorage.setItem('last_visited_bu_id', this.bindings.bu_id);
			this.viewModel.buvm.bu_id(this.bindings.bu_id);
			this.viewModel.load();
		}
		else if (window.localStorage.getItem('last_visited_bu_id'))
		{
			let last_bu_id = window.localStorage.getItem('last_visited_bu_id');
			this.viewModel.buvm.bu_id(last_bu_id);
			this.viewModel.load();
		}
		else
			throw new Error('Page not found (no linked BU)');
		
		this.viewModel.load();
		if (window.localStorage.getItem('last_busidebar_page'))
			this.viewModel.load_tab(window.localStorage.getItem('last_busidebar_page'));
		else
			this.viewModel.load_tab('[/]bu/details');
	}
}

export default {
	route: '[/]bu/business-units/:bu_id',
	page_class: BUPage,
	template: template
}
