import template from './roles.html';

class RolesSubPageVM
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.kpi_features = ko.observableArray();
		this.bu_features = ko.observableArray();
		this.project_features = ko.observableArray();
		this.features = ko.observableArray();
		this.role_features = ko.observableArray();
		this.sdg_features = ko.observableArray();
		this.roles_with_users = ko.observableArray();

		this.grouped_roles = ko.computed(() => {
			let roles_group = {};
			this.role_features().forEach(feature => {
				if (!roles_group[feature.role_name])
				{
					roles_group[feature.role_name] = {
						role_name: feature.role_name,
						kpi_role_id: feature.kpi_role_id,
						features: [],
						description: feature.role_description
					};
				}
				if (feature.feature_name)
				{
					roles_group[feature.role_name].features.push({
						feature_name: feature.feature_name,
						feature_id: feature.feature_id
					});
				}
			});

			// Include user names or set a default value if not present
			this.roles_with_users().forEach(role_with_users => {
				if (roles_group[role_with_users.role_name])
					roles_group[role_with_users.role_name].user_names = role_with_users.user_names;
			});

			// Ensure every role has a user_names property
			Object.keys(roles_group).forEach(roleName => {
				if (typeof roles_group[roleName].user_names === 'undefined')
					roles_group[roleName].user_names = 'none';
			});

			return Object.values(roles_group);
		});
	}

	async btn_new_role_click ()
	{
		await Grape.dialog.open('EditRoleDialog', {mode: 'new', features: this.features()});
		this.page.updateData();
	}

	async btn_edit_role_click (data)
	{
		await Grape.dialog.open('EditRoleDialog', {role_data: data, mode: 'edit', features: this.features()});
		this.page.updateData();
	}

	async btn_delete_role_click (data)
	{
		let response = await Grape.alerts.confirm({type: 'danger', title: 'Delete', message: 'Are you sure you want to delete this role?'});

		if (response)
		{
			let options = {
				kpi_role_id: data.kpi_role_id
			};
			
			try {
				let response = await fetch ('api/kpi/kpi-role', {
					method: 'DELETE',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(options)
				});

				if (response.status != 'ERROR') {
					await Grape.alerts.alert({type: 'success', title: 'Removed', message: 'Role removed successfully'});
				} else {
					throw new Error(response.message || response.code);
				}
			} catch (error) {
				Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
			}
			this.page.updateData();
		}
	}
}

class RolesSubPagePage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new RolesSubPageVM(this);
		this.bindings = bindings;
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Admin - Roles';
	}

	async updateData ()
	{
		let combined_roles = [];
		//get features
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'kpi',
				table: 'v_feature',
				filter: [],
				limit: 1000
			});

			if (result.status === 'OK')
			{
				this.viewModel.features(result.records);
				result.records.forEach((record) => {
					switch (record.category) {
						case 'K':
							this.viewModel.kpi_features.push(record);
							break;
						case 'B':
							this.viewModel.bu_features.push(record);
							break;
						case 'P':
							this.viewModel.project_features.push(record);
							break;
					}
				});
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		//get features
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'sdg',
				table: 'v_sdg_feature',
				filter: [],
				limit: 1000
			});

			if (result.status === 'OK')
			{
				this.viewModel.features.push(result.records);
				this.viewModel.sdg_features(result.records);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		// get kpi roles with features
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'kpi',
				table: 'v_kpi_role_feature',
				filter: [],
				limit: 1000
			});

			if (result.status === 'OK')
			{
				
				combined_roles = combined_roles.concat(result.records);
			}
				
				
		} catch (error) {
			console.error(error);
		}

		// get sdg roles with features
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'sdg',
				table: 'v_sdg_role_feature',
				filter: [],
				limit: 1000
			});

			if (result.status === 'OK')
			{
				combined_roles = combined_roles.concat(result.records);
			}
				
		} catch (error) {
			console.error(error);
		}

		//get roles with users
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'kpi',
				table: 'v_users_with_roles',
				filter: [],
				limit: 1000
			});

			if (result.status === 'OK')
				this.viewModel.roles_with_users(result.records);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		this.viewModel.role_features(combined_roles);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/]users-setup/roles',
	page_id: '/users-setup/roles',
	page_class: RolesSubPagePage,
	template: template
}
