import template from './edit-kpi-user.html';

class EditKPIUserViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.mode = ko.observable();
		this.user_id = ko.observable();
		this.role_names = ko.observableArray([]);
		this.available_kpis = ko.observableArray([]);
		this.selected_kpis = ko.observableArray([]);
		this.selected_role = ko.observableArray([]);
		this.available_users = ko.observableArray([]);
		this.selected_user = ko.observable();
		this.username = ko.observable();
		this.kpi_name = ko.observable();
		this.linked_roles = ko.observableArray([]);
		this.selected_linked_role = ko.observable();
		this.linked_users = ko.observableArray();
		this.add_disabled = ko.observable(true);
		this.remove_disabled = ko.observable(true);
		this.all_kpis = ko.observableArray();
		this.kpi_users = ko.observable();
		this.linked_kpis = ko.observableArray();
		this.add_kpis = ko.observableArray();
		this.all_selected = ko.observable(false);
		this.search_string = ko.observable('');
		this.title = ko.observable('');
		this.current_user = ko.observable();
		this.selected_linked_role = ko.observable();

		this.selected_user.subscribe((value) => {
			if (value[0])
				this.username(value[0].username);
			this.linked_kpis([]);
			this.linked_roles([]);
			this.selected_kpis([]);
			this.available_kpis([]);
			this.selected_role([]);
			this.selected_linked_role(undefined);
			this.dialog.updateData();
		});

		this.selected_linked_role.subscribe((value) => {
			this.selected_role([]);
			this.available_kpis(this.all_kpis());
		
			if (value && this.username())
			{
				let user_kpis = this.kpi_users().find(user => user.username === this.username())?.all_kpis || [];

				let filtered_kpis = user_kpis.filter(kpi => kpi.roles.includes(value));
		
				let linked_kpis = filtered_kpis.map(kpi => ({
					...kpi,
					name: kpi.kpi_name
				}));

				let available_kpis = this.all_kpis().filter(kpi => 
					!linked_kpis.some(linked_kpi => linked_kpi.name === kpi.name)
				);

				this.linked_kpis(linked_kpis);
				this.available_kpis(available_kpis);
			} 
			else
			{
				this.linked_kpis([]);
				this.available_kpis(this.all_kpis());
			}
		});

		this.selected_role.subscribe((selected_role) => {
			this.available_kpis(this.all_kpis());
			if (selected_role.length > 0)
				this.selected_linked_role(undefined);
		});

		this.username.subscribe((value) => {
			if (value !== undefined)
			{
				let matching_user = this.available_users().find(user => user.username === value[0]);
				if (matching_user)
					this.user_id(matching_user.user_id);
			}
			else
			{
				let matching_user = this.available_users().find(user => user.username === this.available_users()[0].username);
				if (matching_user)
					this.user_id(matching_user.user_id);

				this.username(this.available_users()[0].username);
			}
		});

		this.all_selected.subscribe((value) => {
			if (value === true)
			{
				this.selected_kpis(ko.utils.arrayMap(this.available_kpis(), kpi => {
					return { name: kpi.name, kpi_id: kpi.kpi_id };
				}));
			}
		});

		this.search_string.subscribe(async (value) => {
			await this.dialog.load_kpis();

			if (value != '')
			{
				this.available_kpis(this.all_kpis().filter(kpi =>
					this.available_kpis().some(available_kpi => available_kpi.name === kpi.name)
				));
			}
			else
				this.available_kpis(this.all_kpis());
		});
	}

	async btn_add_role_click()
	{
		let role = this.selected_role();

		if ((!role.length > 0 && this.selected_linked_role() == undefined) || this.username() == undefined)
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Please ensure that a role and a user is selected!'});
			return;
		}

		let kpis_to_add = this.selected_kpis();
		this.add_kpis(this.selected_kpis());
	
		if (kpis_to_add && kpis_to_add.length > 0)
		{
			this.linked_kpis.push(...kpis_to_add);
	
			kpis_to_add.forEach(KPI => {
				this.available_kpis.remove(kpi => kpi.kpi_id === KPI.kpi_id);
			});
	
			if (this.all_selected())
			{
				this.selected_kpis([]);
				this.all_selected(false);
			}
		}
	
		this.add_disabled(true);
		this.remove_disabled(true);

		if (!role.length > 0)
		{
			let kpis_to_add = this.add_kpis().map(kpi => kpi.kpi_id);

			let user = {
				kpi_ids: kpis_to_add,
				user_data: {
					'add': [{
						'username': this.username(),
						'role': this.selected_linked_role()
					}]
				}
			};

			let response = await fetch('/api/catalytix/catalytix', {
				method: 'POST',
				headers: {
					'Content-Type' : 'application/json'
				},
				body: JSON.stringify(user)
			});

			if (!response.ok)
			{
				Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Error occurred' });
				console.error('Unknown Error:', error_data);
			}
			else
				Grape.alerts.alert({ type: 'success', title: 'Saved', message: 'Saved successfully!' });
		}
	}

	async btn_remove_role_click ()
	{
		let role = this.selected_role();

		if ((!role.length > 0 && this.selected_linked_role() == undefined) || this.username() == undefined)
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Please ensure that a role and a user is selected!'});
			return;
		}

		let kpis_to_remove = this.selected_kpis();

		if (kpis_to_remove && kpis_to_remove.length > 0)
		{
			this.available_kpis.push(...kpis_to_remove);
			ko.utils.arrayForEach(kpis_to_remove, kpi => {
				this.linked_kpis.remove(kpi);
			});
			this.selected_kpis([]);
		}

		this.remove_disabled(true);
		this.add_disabled(true);

		if (!role.length > 0 && this.selected_linked_role() != undefined)
		{
			let to_remove = kpis_to_remove.map(kpi => kpi.kpi_id);
			let success;

			for (let item of to_remove)
			{
				try {
					let options ={
						kpi_id: item,
						username: this.username()
					}

					let response = await fetch('/api/catalytix/catalytix', {
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(options),
					});

					if (response.ok)
						success = true;
						
				} catch (error)
				{
					await Grape.alerts.alert({type: 'error', title: 'Error', message: 'Error removing Indicator(s)' + error.message});
					break;
				}
			}

			if (success)
				Grape.alerts.alert({title: 'Success!', message: 'Indicator(s) removed successfully!', type: 'success'});
		}
	}

	async btn_cancel_click ()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close();
	}

	async btn_save_click ()
	{
		try
		{
			let success = true;
			let error_data;
			let kpis_to_add = this.add_kpis().map(kpi => kpi.kpi_id);
			let role = this.selected_role();

			if (!role.length > 0 || this.username() == undefined)
			{
				Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Please ensure that a role and a user is selected!'});
				return;
			}

			for (let x of role)
			{
				let user = {
					kpi_ids: kpis_to_add,
					user_data: {
						'add': [{
							'username': this.username(),
							'user_id': this.user_id(),
							'role': x
						}]
					}
				};
	
				let response = await fetch('/api/catalytix/catalytix', {
					method: 'POST',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(user)
				});

				if (!response.ok)
				{
					error_data = response.json();
					success = false;
				}
			}

			if (success)
			{
				await Grape.alerts.alert({ type: 'success', title: 'Saved', message: 'Saved successfully!' });
				this.dialog.close(true);
			}
			else
			{
				Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Error occurred' });
				console.error('Unknown Error:', error_data);
			}
		} catch (error) {
			console.error('Save Error:', error);
		}
	}

	get_current_user_role_info(filtered_roles)
	{
		this.linked_roles([]);
		filtered_roles.forEach(role => {
			let match = this.role_names().find(koRole => koRole.name.toLowerCase() === role);
			if (match !== undefined && !this.linked_roles().some(x => x.name === match.name))
			{
				this.linked_roles.push(match);
				this.role_names.remove(match);
			} 
			else
				console.log(`No match found for role: ${role}`);
		});
	}
}

class EditKPIUserPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new EditKPIUserViewModel(this);
		this.viewModel.mode(bindings.mode);
	}

	async init ()
	{
		document.title = 'Edit User';

		await this.updateData();

		this.viewModel.selected_user([this.bindings.name]);
		this.viewModel.username(this.bindings.name);

		if (this.viewModel.mode() === 'new')
		{
			this.viewModel.title('ADD INDICATOR USER');
			this.viewModel.linked_users(this.bindings.added_users);
			this.viewModel.linked_roles([]);
		}

		if (this.viewModel.mode() === 'edit') {
			let name = this.bindings.user_data.username;
			this.viewModel.username(name);
			this.viewModel.title(`EDIT INDICATOR USER: ${name}`);
			
			let roles = this.bindings.user_data.roles.split(', ').map(role => role.trim().toLowerCase());
			let filtered_roles = roles.filter(role => role !== null);
			this.viewModel.get_current_user_role_info(filtered_roles);
		}
	}

	async updateData ()
	{
		// Load roles
		try {
			let result = {
				table: 'kpi_role',
				schema: 'kpi',
				offset: 0,
				limit: 1000,
				filter: []
			}

			let response = await Grape.fetches.getJSON('/api/record', result);
			if (response.status != 'ERROR')
				this.viewModel.role_names(response.records);

		} catch (error)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		/// Load users
		try {
			let result = await Grape.cache.fetch('Users');
			this.viewModel.available_users(result);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		await this.load_kpis();

		// Load KPI Users
		try {
			let result = await Grape.cache.fetch('KPIUsers');
			this.viewModel.kpi_users(result.map(user => ({
				...user,
				kpis: user.all_kpis.map(kpi => ({
					kpi_id: kpi.kpi_id,
					roles: kpi.roles,
					kpi_name: kpi.name
				}))
			})));

			try {
				if (this.viewModel.username())
				{
					let user = result.filter(user => user.username == this.viewModel.username());
					this.viewModel.current_user(user);
					if (user)
					{
						let current_roles = this.viewModel.current_user()[0].roles;
						if (current_roles)
						{
							let roles = current_roles.split(', ').map(role => role.trim().toLowerCase());
							let filtered_roles = roles.filter(role => role !== null);
							this.viewModel.get_current_user_role_info(filtered_roles);
						}
					}
				}
			} catch {}
		} catch (error)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}
	}

	async load_kpis ()
	{
		// Load all KPIs
		try {
			let options = {
				table: 'v_kpis',
				schema: 'kpi',
				fields: ['name', 'kpi_id', 'kpi_nr'],
				filter: [],
				limit: 1000
			}

			//LOGIC: Search
			if (this.viewModel.search_string() && this.viewModel.search_string() != '')
			{
				options.filter.push({
					field: 'name',
					operand: 'ILIKE',
					value: `%${this.viewModel.search_string()}%`
				})
			}

			let result = await Grape.fetches.getJSON('/api/record', options)
			if (result.status != 'ERROR')
				this.viewModel.all_kpis(result.records);
		} catch (error)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}
	}
}

export default {
	name: 'EditKPIUserDialog',
	dialog_class: EditKPIUserPage,
	template: template,
	provider: 'ps'
}
