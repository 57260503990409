/**
 * Cache for User Roles
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'UserRoles',
	options: {
		table: 'kpi_role',
		schema: 'kpi',
		sortfield: 'kpi_role_id',
	}
};

