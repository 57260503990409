/**
 * Cache for Features
 *
 */

/**
 * @kind cache
 */
export default {
	name: 'Features',
	options: {
		table: 'v_feature',
		schema: 'kpi',
		sortfield: 'feature_id'
	}
};

