import template from './Add-SDG-KPI.html';

class AddKPISDGDialogvm
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.selected_kpi_sdg = ko.observableArray([]);
		this.all_sdgs = ko.observableArray([]);
		this.sdg_id = ko.observable();
		this.dialog_title = ko.observable();
		this.kpis = ko.observableArray();
		this.all_kpis = ko.observableArray();
		this.kpi_sdg_notes = ko.observable();
		this.kpi_id = ko.observable();
		this.kpi_sdg_to_add = ko.observableArray([]);
		this.kpi_sdg_to_remove = ko.observableArray([]);
		this.adding_sdg = ko.observable();
		this.kpi_sdgs = ko.observableArray();
		this.add_disabled = ko.observable(true);
		this.remove_disabled = ko.observable(true);

		this.kpi_sdg_to_add.subscribe((value) => {
			const valid = value.length > 0 && !value.some(item => this.selected_kpi_sdg().includes(item));
			this.add_disabled(!valid);
			this.remove_disabled(valid);
		});

		this.kpi_sdg_to_remove.subscribe((value) => {
			const valid = value.length > 0;
			this.remove_disabled(!valid);
			this.add_disabled(valid);
		});
	}

	async btn_add_click ()
	{
		let success = true;

		for (let sdg of this.kpi_sdg_to_add())
		{
			let options;
			if (!this.adding_sdg())
			{
				options = {
					sdg_id: this.sdg_id(),
					kpi_id: sdg.kpi_id
				}
			} else if (this.adding_sdg())
			{
				options = {
					kpi_id: this.kpi_id(),
					sdg_id: sdg.sdg_id
				}
			}	
			
			try {
				let result = await fetch('/api/sdg/sdg-kpi-link', {
					method: 'POST',
					headers: {
						'Content-Type' : 'application/json'
					},
					body: JSON.stringify(options)
				});

				if (result.ok)
				{
					success = true;
					if (!this.adding_sdg())
					{
						this.kpis.remove(sdg);
						this.selected_kpi_sdg.push(sdg);
					}
					if (this.adding_sdg())
					{
						this.all_sdgs.remove(sdg);
						this.selected_kpi_sdg.push(sdg);
					}
				}
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				break;
			}
		}
		
		if (success)
		{
			Grape.alerts.alert({title: 'Success!', message: 'Related SDG(s)/indicator(s) added successfully!', type: 'success'});
			this.kpi_sdg_to_add.removeAll();
			this.add_disabled(true);
			this.remove_disabled(true);
		}
	}

	async btn_remove_click()
	{
		let response = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Are you sure you want to remove the selected items?',
			title: 'Warning'
		});

		if (response)
		{
			let success;
			for (let item of this.kpi_sdg_to_remove())
			{
				let options;
				if (this.adding_sdg())
				{
					options = {
						sdg_id: item.sdg_id,
						kpi_id: this.kpi_id()
					};
				} 
				else if (!this.adding_sdg())
				{
					options = {
						sdg_id: this.sdg_id(),
						kpi_id: item.kpi_id
					};
				};

				try {
					let result = await fetch('/api/sdg/sdg-kpi-unlink', {
						method: 'DELETE',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(options)
					});

					if (result.ok)
					{
						success = true;
						this.selected_kpi_sdg.remove(item);
						if (!this.adding_sdg())
							this.kpis.push(item);
						if (this.adding_sdg())
							this.all_sdgs.push(item);
					}
				} catch (error) {
					success = false;
					Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
					break;
				}
			}

			if (success)
			{
				Grape.alerts.alert({title: 'Success!', message: 'Items removed successfully!', type: 'success'});
				this.selected_kpi_sdg.removeAll(this.kpi_sdg_to_remove());
				this.kpi_sdg_to_remove([]);
				this.add_disabled(true);
				this.remove_disabled(true);
			} else
			{
				Grape.alerts.alert({ type: 'error', title: 'Error', message: 'An error occured' });
			}
		}
	}

	async close_click()
	{
		let response = await Grape.alerts.confirm({title: '', message: 'Are you sure you want to close this dialog?', type: 'warning'});
		if (response)
			this.dialog.close(false);
	}
}

class AddKPISDGDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new AddKPISDGDialogvm(this);
		this.name = 'AddSDGKPI';
		this.viewModel.kpi_id(bindings.kpi_id);
		this.viewModel.sdg_id(bindings.sdg_id);
		this.viewModel.adding_sdg(bindings.adding_sdg);

		//LOGIC to check whether your adding a SDG to the KPI or a KPI to a SDG
		if (bindings.adding_sdg === true)
		{
			this.viewModel.adding_sdg(true);
			this.viewModel.dialog_title('ADD/REMOVE SDG(S)');
			
		}
		else
		{
			this.viewModel.adding_sdg(false);
			this.viewModel.dialog_title('ADD/REMOVE INDICATOR(S)');
		}
	}

	async init ()
	{
		document.title = 'Add Indicator SDG Dialog';

		try {
			let result = await Grape.cache.fetch('SDGs');
				this.viewModel.all_sdgs(result);
		} catch (error) {
			console.error(error);
		}
	}

	async updateData ()
	{
		// GET SDG KPI's
		let option = {
			table: 'v_sdg_kpi',
			schema: 'sdg',
			offset: 0,
			limit: 1000,
			filter_join: 'AND',
			join: 'OR',
			filter: []
		};

		if (this.viewModel.adding_sdg() === false)
		{
			option.filter.push({
				field: 'sdg_id',
				operand: '=',
				value: this.viewModel.sdg_id()
			});
		} 
		else if (this.viewModel.adding_sdg())
		{
			option.filter.push({
				field: 'kpi_id',
				operand: '=',
				value: this.viewModel.kpi_id()
			})
		}

		let result_sk = await Grape.fetches.getJSON('/api/record', option);
		if (result_sk.status != 'ERROR')
		{
			this.viewModel.kpi_sdgs(result_sk.records);
			this.viewModel.selected_kpi_sdg(result_sk.records);
		} 
		else
			throw new Error(result_sk.message || result_sk.code);


		if (!this.viewModel.adding_sdg())
		{
			//GET KPIs
			let options_kpi = {
				table: 'dv_kpis',
				schema: 'kpi',
				limit: 1000,
				fields: ['name', 'kpi_id', 'uom', 'kpi_nr'],
				filter: []
			}

			let result = await Grape.fetches.getJSON('/api/record', options_kpi);
			if (result.status != 'ERROR')
			{
				this.viewModel.all_kpis(result.records);
				let associated_kpis = this.viewModel.kpi_sdgs().map(record => record.kpi_id);
				this.viewModel.selected_kpi_sdg(result.records.filter(sdg => associated_kpis.includes(sdg.kpi_id)));
				let filtered_kpis = result.records.filter(sdg => !associated_kpis.includes(sdg.kpi_id));
				this.viewModel.kpis(filtered_kpis);
			}
			else 
				throw new Error(result.message || result.code);
		}

		if (this.viewModel.adding_sdg())
		{
			let all_sdgs = this.viewModel.all_sdgs();
			let associated_sdgs = this.viewModel.kpi_sdgs().map(record => record.sdg_id);
			this.viewModel.selected_kpi_sdg(all_sdgs.filter(sdg => associated_sdgs.includes(sdg.sdg_id)));
			let filtered_sdgs = all_sdgs.filter(sdg => !associated_sdgs.includes(sdg.sdg_id));
			this.viewModel.all_sdgs(filtered_sdgs);
			let linked_sdgs = this.viewModel.selected_kpi_sdg().map(x => ({
				name: x.sdg_name,
				sdg_id: x.sdg_id,
				sdg_nr: x.sdg_nr
			}));
			this.viewModel.selected_kpi_sdg(linked_sdgs);
			let not_linked = this.viewModel.all_sdgs().map(x => ({
				name: x.sdg_name,
				sdg_id: x.sdg_id,
				sdg_nr: x.sdg_nr
			}));
			this.viewModel.all_sdgs(not_linked);
		}
	}
}

export default {
	name: 'AddSDGKPI',
	dialog_class: AddKPISDGDialog,
	template: template,
	provider: 'ps'
}
