import KPICapture from './kpi-capture/kpi-capture.js';
import KPICaptureData from './kpi-capture/kpi-capture-data/kpi-capture-data.js';
import KPICapturePrevious from './kpi-capture/kpi-previous/kpi-previous.js';
import KPIBulkEdit from './kpi-capture/bulk-kpi-value-edit/bulk-kpi-value-edit.js';
import KPIViewApprove from './kpi-capture/kpi-view-approve/kpi-view-approve.js';
import KPIList from './kpi-list/kpi-list.js';
import KPIDataWarnings from './kpi-list/kpi-data-warnings.js';
import KPISetup from './kpi-setup/kpi-setup.js';
import KPISetupBU from './kpi-setup/kpi-bu/kpi-bu.js';
import KPISetupProjects from './kpi-setup/kpi-projects/kpi-projects.js';
import KPISetupRelated from './kpi-setup/kpi-kpis/kpi-kpis.js';
import KPISetupUsers from './kpi-setup/kpi-user/kpi-user.js';
import BUPage from './bu-page/business-units.js';
import BUOverview from './bu-page/bu-overview/bu-overview.js';
import BUKPI from './bu-page/bu-kpi/bu-kpi.js';
import BUDetails from './bu-page/bu-details/bu-details.js';
import BUProjects from './bu-page/bu-related-projects/bu-related-projects.js';
import BUUsers from './bu-page/bu-project-users/bu-project-users.js';

import ProjectDetailsSidebarPage from './projects/project-details/projects-details-sidebar.js';
import ProjectsOverview from './projects/project-overview/projects-overview.js';
import ProjectCapture from './projects/project-capture/project-capture.js';
import ProjectDetailContent from './projects/project-details/projects-details.js';
import RelatedBUKPI from './projects/project-details/related-bu-kpi/related-bu-kpi.js';
import ProjectTargetContent from './projects/project-details/project-targets/project-target.js';
import ProjectProgress from './projects/project-details/project-progress/project-progress.js';
import ProjectUser from './projects/project-details/project-user/project-user.js';
import ProjectApproval from './projects/project-capture/view-approve-project/view-approve-project.js';
import ProjectCaptureSidebar from './projects/project-capture/project-capture-sidebar.js';
import ProjectHistory from './projects/project-capture/project-history/project-history.js';

import SDG from './frameworks/sdg/sdg.js';
import SDGOverview from './frameworks/sdg/sdg-overview/sdg-overview.js';
import SDGDetail from './frameworks/sdg/sdg-detail/sdg-detail.js';
import SDGMeasures from './frameworks/sdg/sdg-measures/sdg-measures.js';
import SDGAbout from './frameworks/sdg/sdg-overview/sdg-about/sdg-about.js';
import SDGSearch from './frameworks/sdg/sdg-overview/sdg-search/sdg-search.js';
import SDGAllPage from './frameworks/sdg/sdg-overview/sdg-overview-sidebar';

import UserSetup from './admin/users/users-setup.js';
import UsersSubPage from './admin/users/users-subpages/users.js';
import RolesSubPage from './admin/users/users-subpages/roles.js';
import FeaturesSubPage from './admin/users/users-subpages/features.js';

import ExportData from './admin/export/export-data.js';

import UserPagePage from './user-page/user-page.js';

import DashboardPage from './dashboard/dashboard.js';
import DashboardKPIsPage from './dashboard/dashboard-subpages/dashboard-kpis.js';
import DashboardProjectsPage from './dashboard/dashboard-subpages/dashboard-projects.js';
import DashboardPBIPage from './dashboard/dashboard-subpages/dashboard-powerBI-link.js';

import LoginPage from './login/login.js';

export default [
	KPICapture,
	KPICaptureData,
	KPICapturePrevious,
	KPIBulkEdit,
	KPIViewApprove,
	KPIList,
	KPIDataWarnings,
	KPISetup,
	KPISetupBU,
	KPISetupProjects,
	KPISetupRelated,
	KPISetupUsers,

	BUPage, 
	BUOverview,
	BUKPI,
	BUDetails,
	BUProjects,
	BUUsers,

	ProjectDetailsSidebarPage,
	ProjectsOverview,
	ProjectCapture,
	ProjectDetailContent,
	RelatedBUKPI,
	ProjectTargetContent,
	ProjectProgress,
	ProjectUser,
	ProjectApproval,
	ProjectCaptureSidebar,
	ProjectHistory,

	SDG,
	SDGOverview,
	SDGDetail,
	SDGMeasures,
	SDGAbout,
	SDGSearch,
	SDGAllPage,

	UserSetup,
	UsersSubPage,
	RolesSubPage,
	FeaturesSubPage,

	ExportData,

	UserPagePage,

	DashboardPage,
	DashboardKPIsPage,
	DashboardProjectsPage,
	DashboardPBIPage,

	LoginPage
];
