import template from './sdg-detail.html';
import SDGViewModel from '../../../../lib/SDGViewModel';

class SDGDetail
{
	constructor (page)
	{
		this.page = page;
		this.sdgvm = new SDGViewModel(page.bindings.sdg_id || null);
		this.is_loading = ko.observable(false);
		this.sdg_kpis = ko.observableArray([]);
		this.sdg_projects = ko.observableArray();
		this.all_sdg_projects = ko.observableArray();
		this.sdg_id = ko.observable();
		this.sdg_goal = ko.observable();
		this.sdg_name = ko.observable();
		this.img_path = ko.observable();
		this.sdg_nr = ko.observable();
		this.targets = ko.observableArray();
		this.indicators = ko.observableArray();
		this.selected_item = ko.observable();
		this.all_sdgs = ko.observableArray();
		this.linked_business_units = ko.observableArray();

		// permissions
		this.is_visible = ko.observable(false);

		// search
		this.table_search_string = ko.observable('');
		this.selected_sdg = ko.observableArray([]);

		this.table_search_string.subscribe((value) => {
			this.page.updateData();
		});

		// pagination for SDG ESG KPI's
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(5);
		this.page_count = ko.observable(1);

		// pagination for SDG Projects
		this.current_page_number2 = ko.observable(1);
		this.current_page_size2 = ko.observable(5);
		this.page_count2 = ko.observable(1);
	}

	// For SDG ESG KPI::
	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	// For SDG Projects::
	page_click2 (page_number)
	{
		this.current_page_number2(page_number);
		this.page.updateData();
	}

	// Format of numbers for Associated KPI's table
	format(kpi)
	{
		let format_indicators;
		let format_targets;
		if (kpi.related_indicators)
			format_indicators = kpi.related_indicators.map(i => i.indicator_nr).join(', ');
		
		if (kpi.related_targets)
			format_targets = kpi.related_targets.map(t => t.target_nr).join(', ');
		
		return [format_targets, format_indicators].filter(Boolean).join(' - ');
	}

	toggle_indicator(target)
	{
		target.show_indicator(!target.show_indicator());
	}

	// Search
	btn_search_click (data)
	{
		this.table_search_string(''),
		this.selected_sdg(data);
		this.page.updateData();
		this.all_sdgs([]);
	}

	async btn_select_target_click(data)
	{
		this.selected_item(data);
		this.page.updateData({ update_targets: false});
	}

	async btn_add_kpi_click()
	{
		await Grape.dialog.open('AddSDGKPI', {sdg_id: this.sdg_id(), kpi_sdgs: this.sdg_kpis(), adding_sdg: false});
		this.current_page_number(1);
		this.page.updateData();
	}

	async btn_add_project_click()
	{
		await Grape.dialog.open('AddSDGProject', {sdg_id: this.sdg_id()});
		this.current_page_number2(1);
		this.page.updateData();
	}

	async btn_remove_kpi_click(data)
	{
		let respones = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Are you sure you want to unlink this ESG Indicator/SDG?',
			title: 'Warning'
		});

		if (respones)
		{
			let options = {
				sdg_id: data.sdg_id,
				kpi_id: data.kpi_id
			}
			
			let response = await fetch('/api/sdg/sdg-kpi-unlink', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(options)
			});

			if (response.ok)
				console.log('Deleted successfully')
			else 
				throw new Error(response.message || response.code || 'Unknown Error');

			this.page.updateData();
		}
	}

	async btn_remove_sdg_project_click(data)
	{
		let response = await Grape.alerts.confirm({
			type: 'warning',
			message: 'Are you sure you want to remove the selected items? This will delete ALL the previously captured values relating to these SDG & Projects combinations!',
			title: 'Warning'
		});

		if (response)
		{
			let options = {
				sdg_id: data.sdg_id,
				project_id: data.project_id
			}

			try {
				let result = await fetch('/api/sdg/sdg-project-unlink', {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(options)
				});

				if (result.ok)
				{
					this.page.updateData();
				}
			} catch (error) {
				console.error(error);
				Grape.alerts.alert({ type: 'error', title: 'Error', message: 'An error occured, could not remove project.' });
			}
		}
	}

	async btn_edit_sdg_kpi_click(data)
	{
		await Grape.dialog.open('EditSDGKPI', {sdg_kpi_info: data});
		this.page.updateData();
	}

	async btn_edit_sdg_project_click(data)
	{
		await Grape.dialog.open('EditSDGProject', {sdg_project_info: data});
		this.page.updateData();
	}
}

class SDGDetailPage
{

	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new SDGDetail(this);
		this.timer = null;
		this.viewModel.sdg_id(bindings.sdg_id);
		this.viewModel.sdg_goal(bindings.goal);
		this.viewModel.sdg_name(bindings.sdg_name);
	}

	async init ()
	{
		document.title = 'SDG Details & Set up';

		let permission = await window.user_permission(['admin']);
		if (permission)
			this.viewModel.is_visible(true);
	}

	async updateData ({ update_targets = true, update_indicators = true, update_sdg_kpis = true, update_sdg = true, update_sdg_projects = true } = {})
	{
		// Get SDG Details and picture
		let options = {
			table: 'v_sdgs',
			schema: 'sdg',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'sdg_id',
				operand: '=',
				value: this.viewModel.sdg_id()
			}]
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				let img_paths = result.records.map(sdg => ({
					...sdg,
					img_src: `./SDG_icons/Goal-${String(sdg.sdg_nr).padStart(2, '0')}.png`
				}));

				this.viewModel.sdg_goal(result.records[0].goal);
				this.viewModel.sdg_name(result.records[0].sdg_name);
				this.viewModel.sdg_nr(result.records[0].sdg_nr);
				this.viewModel.img_path(img_paths[0].img_src);
			}
			else 
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message});
			console.error(error);
		}

		// get SDG KPI's
		let option = {
			table: 'v_sdg_kpi',
			schema: 'sdg',
			offset: (this.viewModel.current_page_number() - 1) * this.viewModel.current_page_size(),
			limit: this.viewModel.current_page_size,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'sdg_id',
				operand: '=',
				value: this.viewModel.sdg_id()
			}]
		}

		let result = await Grape.fetches.getJSON('/api/record', option);
		if (result.status != 'ERROR')
		{
			this.viewModel.sdg_kpis(result.records);
			this.viewModel.page_count(Math.floor(result.total/result.limit) + 1);
		}

		if (update_targets)
		{
			let indicators_by_target = {};

			// Get targets
			let option_targets = {
				table: 'v_sdg_targets',
				schema: 'sdg',
				offset: 0,
				filter_join: 'AND',
				join: 'OR',
				filter : [{
					field: 'sdg_id',
					operand: '=',
					value: this.viewModel.sdg_id()
				}]
			}

			try {
				let result = await Grape.fetches.getJSON('/api/record', option_targets);
				if (result.status != 'ERROR')
				{
					let targets = result.records.map(target => ({
						...target,
						show_indicator: ko.observable(false),
						img_src: `./SDG_target_icons/Target_${String(target.target_nr)}.png`
					}));

					this.viewModel.targets(targets);
					targets.forEach(t => {
						indicators_by_target[t.sdg_target_id] = [];
					});
				}
			} catch (error) {
				console.error(error);
			}

			// Get indicators
			let options_indicators = {
				table: 'v_sdg_target_indicators',
				schema: 'sdg',
				offset: 0,
				limit: 1000
			}

			try {
				let result = await Grape.fetches.getJSON('/api/record', options_indicators);
				if (result.status != 'ERROR')
				{
					this.viewModel.indicators(result.records);

					result.records.forEach(ind => {
						if (indicators_by_target[ind.sdg_target_id])
							indicators_by_target[ind.sdg_target_id].push(ind);
					});

					let updated_targets = this.viewModel.targets().map(target => ({
						...target,
						indicators: indicators_by_target[target.sdg_target_id] || []
					}));

					this.viewModel.targets(updated_targets);
				}
			} catch (error) {
				console.error(error);
			}
		}

		// Get SDG Projects
		let options_projects = {
			table: 'v_sdg_projects',
			schema: 'sdg',
			offset: 0,
			limit: 1000,
			filter_join: 'AND',
			join: 'OR',
			filter: [{
				field: 'sdg_id',
				operand: '=',
				value: this.viewModel.sdg_id()
			}]
		}

		//LOGIC: Pagination
		if (this.viewModel.current_page_number2() && this.viewModel.current_page_size2())
		{
			options_projects.limit = this.viewModel.current_page_size2();
			options_projects.offset = (this.viewModel.current_page_number2()-1) * this.viewModel.current_page_size2();
		}

		try {
			let result = await Grape.fetches.getJSON('/api/record', options_projects);
			if (result.status != 'ERROR')
			{
				this.viewModel.sdg_projects(result.records);
				this.viewModel.page_count2(Math.floor(result.total/result.limit)+1);
			}
		} catch (error)
		{
			console.error(error);
		}
	}
}

export default {
	route: '[/]sdg/detail',
	page_class: SDGDetailPage,
	template: template
}
